import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, ModalProps, Row } from 'antd';

import { ChevronRight, Horloge, CalendarThree } from '../../icons';
import AppointmentTimeSlots from '../AppointmentTimeSlots';
import { AppointmentSlot, SettingSlug } from '../../../queries/api/types';
import { useSettingList } from '../../../queries/settings';
import '../../../assets/styles/AppointmentModalFormAdd.less';
import { useOrganizationsList } from '../../../queries/organizations';
import CustomModal from '../../CustomModal';
import { useAppointmentCreate } from '../../../queries/appointments';
import { errorMessage, successMessage } from '../../../helpers/message';

interface FormAddAppointment {
    timeSlots: Pick<AppointmentSlot, 'fromDate' | 'toDate'> | null;
    isFilled: boolean;
}

const AppointmentModalFormAdd = NiceModal.create<Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>>(
    ({ ...props }) => {
        const [dateSelected, setDateSelected] = useState<Dayjs>(dayjs());
        const [formData, setFormData] = useState<FormAddAppointment>({
            timeSlots: null,
            isFilled: false,
        });
        const { mutate: createAppointment } = useAppointmentCreate();
        const organizationScapnorId = useOrganizationsList().data?.items.find(
            (organization) => organization.slug === 'scapnor'
        )?.id;
        const { data: settings } = useSettingList();
        const appointmentDurationSetting = settings?.find(
            (setting) => setting.slug === SettingSlug.appointmentDuration
        );
        const modalAdd = useModal();

        const handleCallbackTimeSlotChange = (fromDate: string, toDate: string) => {
            setFormData({ ...formData, timeSlots: { fromDate, toDate }, isFilled: true });
        };

        const handleClickEdit = () => {
            setFormData({ ...formData, isFilled: false });
        };

        const handleClickSave = () => {
            createAppointment(
                {
                    organization: organizationScapnorId,
                    fromDate: formData.timeSlots?.fromDate,
                    toDate: formData.timeSlots?.toDate,
                },
                {
                    onSuccess: () => {
                        successMessage({ content: 'Demande de rendez-vous envoyée avec succès' });
                        modalAdd.hide();
                    },
                    onError: (error) => {
                        errorMessage({
                            content: `La demande de rendez-vous n'a pas été envoyée (${error?.response?.status ?? 0})`,
                        });
                    },
                }
            );
        };

        return (
            <CustomModal
                className="modal-add-appointment"
                title="Demande de rendez-vous"
                footer={false}
                width={formData.isFilled ? 500 : 700}
                {...props}
                {...antdModal(modalAdd)}
            >
                {formData.isFilled ? (
                    <div className="modal-add-appointment-summary">
                        <Row justify="center">
                            <span className="modal-add-appointment-summary-title font-bold">
                                Vous avez choisi le créneau suivant :
                            </span>
                        </Row>
                        <Row
                            className="modal-add-appointment-summary-cards mt-16 mb-24 font-medium"
                            justify="space-between"
                        >
                            <Col className="flex-auto" style={{ flexBasis: '100%' }}>
                                <div className="modal-add-appointment-summary-card modal-add-appointment-summary-card-date flex flex-col items-center justify-center h-full">
                                    <CalendarThree className="ant-icons-calendar modal-add-appointment-schedule-icon" />
                                    <span className="text-center">
                                        {dayjs(formData.timeSlots?.fromDate).format('dddd D MMM')}
                                    </span>
                                </div>
                            </Col>
                            <Col className="flex-auto">
                                <div className="modal-add-appointment-summary-card flex flex-col items-center justify-center h-full">
                                    <Horloge className="ant-icons-calendar modal-add-appointment-schedule-icon" />
                                    <span className="text-center">
                                        {dayjs(formData.timeSlots?.fromDate).format('HH[h]mm')}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="modal-add-appointment-summary-button">
                            <Col className="flex-1">
                                <Button className="w-full" type="primary" ghost onClick={handleClickEdit}>
                                    Choisir un autre créneau
                                </Button>
                            </Col>
                            <Col className="flex-1">
                                <Button className="w-full" type="primary" onClick={handleClickSave}>
                                    Confirmer le rendez-vous
                                </Button>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Row align="middle">
                            <Col className="flex mr-16">
                                <Horloge className="ant-icons-calendar modal-add-appointment-schedule-icon" />
                            </Col>
                            <Col className="grow ">
                                <span className="modal-add-appointment-schedule-title font-bold">
                                    Sélectionnez votre créneau horaire ({appointmentDurationSetting?.value} minutes)
                                </span>
                            </Col>
                        </Row>
                        <Row align="middle" className="modal-add-appointment-timeslot mt-24">
                            <Col>
                                <Button
                                    type="text"
                                    className="ant-icons-calendar modal-add-appointment-chevron"
                                    icon={<ChevronRight />}
                                    onClick={() => setDateSelected(dateSelected.subtract(7, 'day'))}
                                    disabled={dateSelected.isSame(dayjs(), 'week')}
                                />
                            </Col>
                            <Col className="grow">
                                <AppointmentTimeSlots
                                    fromDate={dateSelected.startOf('week').toISOString()}
                                    toDate={dateSelected.endOf('week').toISOString()}
                                    timeSlots={formData.timeSlots}
                                    onTimeSlotChange={handleCallbackTimeSlotChange}
                                />
                            </Col>
                            <Col>
                                <Button
                                    type="text"
                                    className="ant-icons-calendar"
                                    icon={<ChevronRight />}
                                    onClick={() => setDateSelected(dateSelected.add(7, 'day'))}
                                />
                            </Col>
                        </Row>
                    </div>
                )}
            </CustomModal>
        );
    }
);

export default AppointmentModalFormAdd;
