import { Typography } from 'antd';
import { useMemo, VFC } from 'react';

import { formatPrice } from '../../i18n';
import { Penalty } from '../../queries/api/types';

interface PenaltyFeesChangedAmountTotalBarProps {
    penalty?: Penalty;
}

const PenaltyFeesChangedAmountTotalBar: VFC<PenaltyFeesChangedAmountTotalBarProps> = ({ penalty }) => {
    const { hasChangedAmount, kept, canceled } = useMemo(() => {
        return {
            hasChangedAmount: penalty?.fees?.some((fee) => fee.amount !== fee.originalAmount),
            kept: penalty?.amount,
            canceled: Math.abs((penalty?.originalAmount ?? 0) - (penalty?.amount ?? 0)),
        };
    }, [penalty]);

    return hasChangedAmount ? (
        <>
            <Typography.Text className="block my-16" strong>
                Correction des montants proposée
            </Typography.Text>
            <div className="rounded border-solid border-base border-2 flex mb-24">
                <div className="py-16 px-24 flex flex-1 items-center justify-center gap-24">
                    <Typography.Text type="secondary" className="font-medium font-16 leading-7">
                        Montant maintenu :
                    </Typography.Text>
                    <Typography.Text className="font-16 leading-7" strong>
                        {formatPrice(kept)}
                    </Typography.Text>
                </div>
                <div className="py-16 px-24 flex flex-1 items-center justify-center gap-24 border-l-solid border-l-2 border-base">
                    <Typography.Text type="secondary" className="font-medium font-16 leading-7">
                        Montant annulé :
                    </Typography.Text>
                    <Typography.Text className="font-16 leading-7" strong>
                        {formatPrice(canceled)}
                    </Typography.Text>
                </div>
            </div>
        </>
    ) : null;
};

export default PenaltyFeesChangedAmountTotalBar;
