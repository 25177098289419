import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    details,
    list,
    listTypes,
    PenaltyIdPayload,
    PenaltyListPayload,
    PenaltyListResponse,
    PenaltyListTypesResponse,
    PenaltyUpdatePayload,
    remove,
    update,
} from './api/penalties';
import { Penalty } from './api/types';

const extractCQLFee = (penalty: Penalty) => ({
    ...penalty,
    cqlFee: penalty.fees?.find((fee) => fee.cql),
    fees: penalty.fees?.filter((fee) => !fee.cql),
});

export const penaltiesKeys = {
    all: ['penalties'],
    lists: () => [...penaltiesKeys.all, 'list'],
    list: (params?: PenaltyListPayload) => [...penaltiesKeys.lists(), params],
    listTypes: () => [...penaltiesKeys.all, 'types'],
    details: () => [...penaltiesKeys.all, 'details'],
    detail: (id?: PenaltyIdPayload) => [...penaltiesKeys.details(), id],
};

export const usePenaltyList = (
    params?: PenaltyListPayload,
    options?: UseQueryOptions<PenaltyListResponse, AxiosError, PenaltyListResponse>
) => {
    return useQuery<PenaltyListResponse, AxiosError, PenaltyListResponse>(
        penaltiesKeys.list(params),
        async () => await list(params),
        {
            keepPreviousData: true,
            ...options,
            select: (data) =>
                data
                    ? {
                          ...data,
                          items: data?.items.map(extractCQLFee),
                      }
                    : undefined,
        }
    );
};

export const usePenaltyListTypes = <TData = PenaltyListTypesResponse>(
    options?: UseQueryOptions<PenaltyListTypesResponse, AxiosError, TData>
) => {
    return useQuery<PenaltyListTypesResponse, AxiosError, TData>(
        penaltiesKeys.listTypes(),
        async () => await listTypes(),
        { staleTime: 1000 * 60 * 60 * 8, ...options }
    );
};

export const usePenaltyDetails = (id?: PenaltyIdPayload, options?: UseQueryOptions<Penalty, AxiosError, Penalty>) => {
    const queryClient = useQueryClient();

    return useQuery<Penalty, AxiosError, Penalty>(penaltiesKeys.detail(id), async () => await details(id), {
        ...options,
        onSettled: (data, error) => {
            options?.onSettled?.(data, error);

            // invalidate list query since the penalty is updated during the details request server side (penalty.reviewing field)
            queryClient.invalidateQueries(penaltiesKeys.lists());
        },
        select: extractCQLFee,
    });
};

export const usePenaltyUpdate = (options?: UseMutationOptions<Penalty, AxiosError, PenaltyUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Penalty, AxiosError, PenaltyUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            queryClient.invalidateQueries(penaltiesKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(penaltiesKeys.detail(variables.id));
        },
    });
};

export const usePenaltyRemove = (options?: UseMutationOptions<undefined, AxiosError, PenaltyIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, PenaltyIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(penaltiesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(penaltiesKeys.lists());
        },
    });
};
