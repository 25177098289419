import { Select as AntdSelect, Spin } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';

import { classNames } from '../helpers';

declare type InternalSelectType = <VT extends SelectValue = SelectValue>(
    props: SelectProps<VT> & {
        ref?: ((instance: RefSelectProps | null) => void) | React.RefObject<RefSelectProps> | null | undefined;
    }
) => React.ReactElement;
interface SelectInterface extends InternalSelectType {
    SECRET_COMBOBOX_MODE_DO_NOT_USE: string;
    Option: typeof AntdSelect.Option;
    OptGroup: typeof AntdSelect.OptGroup;
}

const CustomSelect: SelectInterface = (props) => (
    <AntdSelect
        {...props}
        className={classNames('custom-select', props.className)}
        suffixIcon={
            props.loading ? (
                <Spin size="small" />
            ) : (
                <svg width="1em" height="1em" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.582.806A.754.754 0 0 1 12.188.5h1.184c.5 0 .815.573.504.995l-.002.002L8.31 9.088a1.007 1.007 0 0 1-1.623.002l-5.57-7.598A.625.625 0 0 1 1.625.5H2.81c.241 0 .465.117.605.306l.001.002 4.083 5.568 4.084-5.57Z"
                        fill="#06C"
                    />
                </svg>
            )
        }
    >
        {props.children}
    </AntdSelect>
);

CustomSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE = AntdSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE;
CustomSelect.Option = AntdSelect.Option;
CustomSelect.OptGroup = AntdSelect.OptGroup;

export default CustomSelect;
