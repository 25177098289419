import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, FormProps, Input, ModalProps, Row, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { requiredRule } from '../../../helpers';
import CustomModal from '../../CustomModal';

interface ModalFormRefusedProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'> {}

const AppointmentModalFormRefused = NiceModal.create<ModalFormRefusedProps>(({ ...props }) => {
    const modalFormRefused = useModal();

    const handleClickClose = () => {
        modalFormRefused.hide();
    };

    const onFinish: FormProps['onFinish'] = (values) => {
        modalFormRefused.resolve(values);
        modalFormRefused.hide();
    };

    return (
        <CustomModal footer={false} {...props} {...antdModal(modalFormRefused)}>
            <Form onFinish={onFinish} initialValues={{ comment: '' }}>
                <Row align="middle">
                    <Col span={2}>
                        <ExclamationCircleOutlined
                            style={{ color: '#F08E02', fontSize: '21px', verticalAlign: 'end' }}
                        />
                    </Col>
                    <Col>
                        <Typography.Title level={5}>Refus demande de rendez-vous</Typography.Title>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col span={22}>
                        <Typography.Paragraph>
                            Êtes-vous sûr de vouloir refuser cette demande de rendez-vous du gestionnaire ?
                        </Typography.Paragraph>
                        <p className="mb-8">Commentaire (obligatoire)</p>
                        <Form.Item name="comment" rules={[requiredRule]} noStyle>
                            <Input placeholder="Saisir votre commentaire" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="end" className="mt-24">
                    <Space size={10}>
                        <Col>
                            <Button type="primary" ghost onClick={handleClickClose}>
                                Annuler
                            </Button>
                        </Col>
                        <Col offset={1}>
                            <Button type="primary" danger htmlType="submit">
                                Refuser
                            </Button>
                        </Col>
                    </Space>
                </Row>
            </Form>
        </CustomModal>
    );
});

export default AppointmentModalFormRefused;
