import { useEffect, useState } from 'react';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { Button, Spin } from 'antd';

import CustomModal, { CustomModalProps } from '../components/CustomModal';
import { ArrowLeft, ArrowRight } from './icons';
import ApiResult from './ApiResult';
import useElementSize from '../hooks/useElementSize';
import { useAuth } from '../context/AuthContext';

interface TutorialModalProps
    extends Omit<CustomModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, unknown> {
    isLogin?: boolean;
}

const TutorialModal = NiceModal.create<TutorialModalProps>(() => {
    const [wrapperRef, { width }] = useElementSize();
    const modal = useModal();
    const { user, updateUser } = useAuth();
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        if (user && !user?.hasSeenTutorial && !updateUser.isLoading) {
            updateUser.mutate({ hasSeenTutorial: true });
        }
    }, [modal.visible, updateUser, user]);

    return (
        <CustomModal footer={null} width={960} {...antdModal(modal)} className="tutorial">
            <div ref={wrapperRef} className="w-full h-full">
                <Document
                    file={`${process.env.PUBLIC_URL}/tutorial.pdf`}
                    onLoadSuccess={onDocumentLoadSuccess}
                    error={<ApiResult />}
                    loading={<Spin />}
                >
                    <Page
                        pageNumber={pageNumber}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                        error={<ApiResult />}
                        loading={<Spin />}
                        width={width}
                    />
                </Document>
            </div>
            <Button
                shape="circle"
                icon={<ArrowLeft />}
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
            />
            <Button
                shape="circle"
                icon={<ArrowRight />}
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numPages}
            />
        </CustomModal>
    );
});

export default TutorialModal;
