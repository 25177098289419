import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    AppointmentCreatePayload,
    AppointmentIdPayload,
    AppointmentListPayload,
    AppointmentListResponse,
    AppointmentUpdatePayload,
    remove,
    update,
    AppointmentRemoveAttachmentPayload,
    removeAttachment,
} from './api/appointments';
import { Appointment } from './api/types';
import { appointmentsSlotsKeys } from './appointmentsSlots';

export const appointmentsKeys = {
    all: ['appointments'],
    lists: () => [...appointmentsKeys.all, 'list'],
    list: (params?: AppointmentListPayload) => [...appointmentsKeys.lists(), params],
    details: () => [...appointmentsKeys.all, 'details'],
    detail: (id?: AppointmentIdPayload) => [...appointmentsKeys.details(), id],
};

export const useAppointmentList = <TData = AppointmentListResponse>(
    params?: AppointmentListPayload,
    options?: UseQueryOptions<AppointmentListResponse, AxiosError, TData>
) => {
    return useQuery<AppointmentListResponse, AxiosError, TData>(
        appointmentsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useAppointmentDetails = <TData = Appointment>(
    id?: AppointmentIdPayload,
    options?: UseQueryOptions<Appointment, AxiosError, TData>
) => {
    return useQuery<Appointment, AxiosError, TData>(
        appointmentsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useAppointmentCreate = (
    options?: UseMutationOptions<Appointment, AxiosError, AppointmentCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Appointment, AxiosError, AppointmentCreatePayload>(async (params) => await create(params), {
        ...options,
        onSuccess: (...args) => {
            options?.onSuccess?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(appointmentsKeys.lists());
            queryClient.invalidateQueries(appointmentsSlotsKeys.lists());
        },
    });
};

export const useAppointmentUpdate = (
    options?: UseMutationOptions<Appointment, AxiosError, AppointmentUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<Appointment, AxiosError, AppointmentUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            queryClient.invalidateQueries(appointmentsKeys.lists());

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(appointmentsKeys.detail(variables.id));
        },
    });
};

export const useAppointmentRemove = (options?: UseMutationOptions<undefined, AxiosError, AppointmentIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, AppointmentIdPayload>(async (params) => await remove(params), {
        ...options,
        onSuccess: (data, variables, context) => {
            options?.onSuccess?.(data, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(appointmentsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(appointmentsKeys.lists());
        },
    });
};

export const useAppointmentRemoveAttachment = (
    options?: UseMutationOptions<undefined, AxiosError, AppointmentRemoveAttachmentPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, AppointmentRemoveAttachmentPayload>(
        async (params) => await removeAttachment(params),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context);

                // invalidate detail query since we removed some of its attachments
                queryClient.invalidateQueries(appointmentsKeys.detail(variables.id));
            },
        }
    );
};
