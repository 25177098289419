import { FC } from 'react';
import { Layout, Typography } from 'antd';
import '../assets/styles/Login.less';

import Seo from './Seo';
import Logo from './Logo';
import constants from '../config/constants';

interface LoginLayoutProps {
    seoTitle: string;
    title: string;
}

const LoginLayout: FC<LoginLayoutProps> = ({ seoTitle, title, children }) => (
    <Layout id="login-layout">
        <Seo title={seoTitle} />
        <div id="login-section">
            <Logo isDefault />
            <span className="bg-orange text-white text-uppercase rounded-sm py-4 px-16 font-16 leading-6 font-bold space-x-2 mb-40">
                {constants.PRODUCT_NAME}
            </span>
            <Typography.Title level={1} className="mb-32">
                {title}
            </Typography.Title>
            {children}
        </div>
    </Layout>
);

export default LoginLayout;
