import { useState } from 'react';
import { List, DrawerProps, Typography, Button, Tag, Skeleton } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { antdDrawer, useModal, create, show } from '@ebay/nice-modal-react';

import { RoleSlug, User } from '../../queries/api/types';
import CustomDrawer from '../CustomDrawer';
import { useUserRemove, useUsersList } from '../../queries/users';
import { getFullName } from '../../helpers';
import BasicList from '../BasicList';
import DeletePopConfirm from '../DeletePopConfirm';
import { errorMessage, successMessage } from '../../helpers/message';
import UserFormDrawer from './UserFormDrawer';
import { hasRole } from '../../helpers/security';
import { Star } from '../icons';
import ApiResult from '../ApiResult';

type Props = DrawerProps & Record<string, any>;
const userFormDrawerId = 'user-form-drawer';

const UserListDrawer = create<Props>(({ ...props }) => {
    const modal = useModal();
    const [page, setPage] = useState(0);
    const {
        data: users,
        isLoading,
        isError,
        error,
        isSuccess,
    } = useUsersList({
        page,
        roles: [RoleSlug.providerAdmin, RoleSlug.providerUser],
    });
    const removeUser = useUserRemove({
        onError: () => {
            errorMessage({ content: "Une erreur est survenue pendant la suppression l'utilisateur" });
        },
        onSuccess: () => {
            successMessage({
                content: 'Utilisateur supprimé avec succès',
            });
        },
    });
    const onConfirmRemoveUser = (user: User) => {
        removeUser.mutate(user.id);
    };
    const onClickAddUser = () => {
        show(userFormDrawerId);
    };
    const onClickEditUser = (id: User['id']) => {
        show(userFormDrawerId, { id });
    };
    const hasUsers = !!users?.totalCount;

    return (
        <CustomDrawer
            className="user-list-drawer"
            width={500}
            title="Utilisateurs"
            footer={
                hasUsers
                    ? [
                          <Button key="add" type="primary" size="large" onClick={onClickAddUser} block ghost>
                              Ajouter un utilisateur
                          </Button>,
                      ]
                    : null
            }
            {...props}
            {...antdDrawer(modal)}
        >
            {isError && <ApiResult status={error?.response?.status} />}
            {isLoading && <Skeleton />}
            {!isLoading && isSuccess && !hasUsers && (
                <>
                    <Typography.Paragraph className="mb-24">
                        Une fois ajoutées, vous retrouverez ici l&rsquo;ensemble des personnes de votre entreprise ayant
                        accès à cette plateforme.
                    </Typography.Paragraph>
                    <Button type="primary" size="large" onClick={onClickAddUser} block>
                        Ajouter un utilisateur
                    </Button>
                </>
            )}
            {!isLoading && isSuccess && hasUsers && (
                <>
                    <Typography.Paragraph className="mb-24">
                        Retrouvez ci-dessous l&rsquo;ensemble des personnes de votre entreprise ayant accès à cette
                        plateforme.
                    </Typography.Paragraph>
                    <List<User>
                        className="demo-loadmore-list"
                        itemLayout="vertical"
                        dataSource={users?.items}
                        size="large"
                        rowKey="id"
                        renderItem={(user) => (
                            <List.Item
                                extra={[
                                    <Button
                                        key="edit"
                                        type="text"
                                        icon={<EditOutlined />}
                                        onClick={() => onClickEditUser(user.id)}
                                    />,
                                    <DeletePopConfirm
                                        key="delete"
                                        placement="topRight"
                                        onConfirm={onConfirmRemoveUser.bind(null, user)}
                                    >
                                        <Button type="text" icon={<DeleteOutlined />} />
                                    </DeletePopConfirm>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={
                                        <>
                                            {getFullName(user)}
                                            {hasRole(user, [RoleSlug.providerAdmin, RoleSlug.admin]) && (
                                                <Star className="text-orange ml-4 font-12" />
                                            )}
                                        </>
                                    }
                                    description={user.job}
                                />
                                {!!user?.scope?.organizations?.length && (
                                    <BasicList className="flex-wrap -m-4" inline>
                                        {user?.scope?.organizations?.map((organization) => (
                                            <li key={organization.id} className="m-4">
                                                <Tag color="blue">
                                                    <Typography.Text style={{ color: 'inherit' }} ellipsis>
                                                        {organization.name}
                                                    </Typography.Text>
                                                </Tag>
                                            </li>
                                        ))}
                                    </BasicList>
                                )}
                            </List.Item>
                        )}
                        pagination={{
                            total: users?.totalCount,
                            current: page + 1,
                            pageSize: users?.pageSize,
                            onChange: (p) => setPage(p - 1),
                            hideOnSinglePage: true,
                        }}
                        bordered
                    />
                </>
            )}
            <UserFormDrawer id={userFormDrawerId} />
        </CustomDrawer>
    );
});

export default UserListDrawer;
