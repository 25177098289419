import { FC } from 'react';
import { Skeleton, SkeletonProps } from 'antd';

import '../assets/styles/TitleSkeleton.less';

const TitleSkeleton: FC<SkeletonProps> = ({ children, ...props }) => (
    <div className="title-skeleton">
        <Skeleton paragraph={{ rows: 0 }} title={{ width: '50%' }} active {...props}>
            {children}
        </Skeleton>
    </div>
);

export default TitleSkeleton;
