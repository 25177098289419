import { Alert, Button, DrawerProps, Form, FormProps, Spin, Typography } from 'antd';
import { antdDrawer, useModal, create } from '@ebay/nice-modal-react';
import { useEffect } from 'react';

import { useAuth } from '../../context/AuthContext';
import { defaultErrorMessage, requiredFieldsText } from '../../i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import ProvidersField from '../forms/ProvidersField';
import CompanyTermsUploadField from '../forms/CompanyTermsUploadField';
import CompanyFields from '../forms/CompanyFields';
import CustomDrawer from '../CustomDrawer';
import { useOrganizationDetails, useOrganizationUpdate } from '../../queries/organizations';

type Props = DrawerProps & Record<string, any>;

const CompanyFormDrawer = create<Props>(({ ...props }) => {
    const modal = useModal();
    const [form] = Form.useForm();
    const { user } = useAuth();
    const { data: organization, isLoading: isLoadingOrganization } = useOrganizationDetails(user?.organization?.id, {
        onSuccess: (data) => {
            form.setFieldsValue({
                user: {
                    organization: data,
                },
            });
        },
    });
    const { mutate: update, isLoading } = useOrganizationUpdate({
        onSuccess: () => {
            modal.hide();
            successMessage({
                content: 'Les informations de la société ont été modifiées avec succès',
            });
        },
        onError: (error) => {
            errorMessage({
                content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
            });
        },
    });
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        update({ id: organization?.id, hasVerifiedData: true, ...values.user.organization });
    };
    const hasVerifiedData = !!organization?.hasVerifiedData;

    useEffect(() => {
        if (modal.visible && !isLoadingOrganization) {
            form.setFieldsValue({
                user: {
                    organization: {
                        ...organization,
                        accountingEmails: organization?.accountingEmails || [''],
                    },
                },
            });
        }
    }, [form, isLoadingOrganization, modal.visible, organization]);

    return (
        <CustomDrawer width={500} title="Informations société" {...props} {...antdDrawer(modal)}>
            <Spin spinning={isLoadingOrganization}>
                <Form form={form} onFinish={onFormValidSubmit} layout="vertical" scrollToFirstError>
                    {!isLoadingOrganization && !hasVerifiedData && (
                        <Alert
                            className="mb-16"
                            type="warning"
                            message="Veuillez renseigner les informations suivantes"
                        />
                    )}
                    <CompanyFields inDrawer />
                    <ProvidersField readOnly />
                    <CompanyTermsUploadField />
                    <Form.Item className="mb-32" shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={isLoading}
                                disabled={
                                    !form.isFieldsTouched() ||
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                                }
                                block
                            >
                                Enregistrer les modifications
                            </Button>
                        )}
                    </Form.Item>
                    <Typography.Paragraph className="text-center mb-0">
                        <Typography.Text className="font-12">{requiredFieldsText}</Typography.Text>
                    </Typography.Paragraph>
                </Form>
            </Spin>
        </CustomDrawer>
    );
});

export default CompanyFormDrawer;
