import { createContext, FC, useMemo, useContext, useCallback, useState } from 'react';
import {
    QueryObserverResult,
    RefetchOptions,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
} from 'react-query';
import { AxiosError } from 'axios';

import { DeviceType, User } from '../queries/api/types';
import {
    me,
    login,
    logout,
    LoginPayload,
    facebookLogin,
    forgottenPassword,
    ForgottenPasswordPayload,
    ResetPasswordPayload,
    resetPassword,
    ResendInvitationPayload,
    resendInvitation,
    UpdateMePayload,
    update,
} from '../queries/api/auth';
import { setApiKey } from '../queries/api/apiClient';
import { errorMessage } from '../helpers/message';
import { penaltiesKeys } from '../queries/penalties';
import { userKeys } from '../queries/users';

export interface AuthContextType {
    user: User | undefined;
    isCheckingSession: boolean;
    isRefetchingUser: boolean;
    hasChosenOrganization: boolean;
    checkSessionError: AxiosError | null;
    login: UseMutationResult<User, AxiosError, LoginPayload>;
    facebookLogin: UseMutationResult<User, AxiosError, void>;
    logout: UseMutationResult<unknown, AxiosError, void>;
    forgottenPassword: UseMutationResult<User, AxiosError, ForgottenPasswordPayload>;
    resetPassword: UseMutationResult<User, AxiosError, ResetPasswordPayload>;
    resendInvitation: UseMutationResult<User, AxiosError, ResendInvitationPayload>;
    updateUser: UseMutationResult<User, AxiosError, UpdateMePayload>;
    refetchUser: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<User, Error>>;
    removeCurrentOrganization: () => void;
}

// context creator
export const AuthContext = createContext<AuthContextType | null>(null);
AuthContext.displayName = 'AuthContext';

// hook to retrieve auth data & helpers
export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context;
};

export const useCurrentHubName = () => {
    const { user } = useAuth();

    return `${user?.organization?.hub?.name || user?.organization?.name || 'SCA'}`.toLocaleUpperCase();
};

// context provider
export const AuthProvider: FC = ({ children }) => {
    const queryClient = useQueryClient();
    const [hasChosenOrganization, setHasChosenOrganization] = useState(false);
    const handleMeResponse = useCallback(
        (data: User, refetch: AuthContextType['refetchUser']) => {
            if (data) {
                if (data.organization) {
                    setHasChosenOrganization(true);

                    // invalidate queries when changing hub
                    queryClient.invalidateQueries(penaltiesKeys.all);
                    queryClient.invalidateQueries(userKeys.all);
                } else if (!hasChosenOrganization && data?.hubs?.length === 1) {
                    const apiKey = data.hubs?.[0]?.applications?.[0]?.applicationClients?.find(
                        (client) => client.type === DeviceType.web
                    )?.apiKey;

                    if (apiKey) {
                        setApiKey(apiKey);
                        setHasChosenOrganization(true);

                        refetch();
                    } else {
                        errorMessage({
                            content: 'Votre centrale ne possède pas de clé API. Veuillez contacter un administrateur',
                        });
                    }
                }
            }
        },
        [hasChosenOrganization, queryClient]
    );

    const {
        data: user,
        error: checkSessionError,
        isLoading: isCheckingSession,
        isRefetching: isRefetchingUser,
        refetch,
    } = useQuery<User, AxiosError>({
        retry: 0,
        queryKey: 'auth-user',
        queryFn: me,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 60 * 8, // 8h until data is marked as stale
        onSuccess: (data) => handleMeResponse(data, refetch),
    });

    const setUser = useCallback((data?: User) => queryClient.setQueryData('auth-user', data), [queryClient]);

    const removeCurrentOrganization = useCallback(() => {
        const newUser = user;
        delete newUser?.organization;
        setHasChosenOrganization(false);
        setUser(newUser);
    }, [setUser, user]);

    const facebookLoginMutation = useMutation<User, AxiosError>({
        mutationFn: facebookLogin,
        onSuccess: (response) => {
            setUser(response);
        },
    });

    const loginMutation = useMutation<User, AxiosError, LoginPayload>({
        mutationFn: login,
        onSuccess: (response) => {
            setUser(response);
        },
    });

    const logoutMutation = useMutation<unknown, AxiosError>({
        mutationFn: logout,
        onError: () => {
            // flush user especially if logout failed
            queryClient.clear();
        },
        onSuccess: () => {
            // flush user on logout success
            queryClient.clear();
        },
        onSettled: () => {
            setHasChosenOrganization(false);
        },
    });

    const updateUserMutation = useMutation<User, AxiosError, UpdateMePayload>({
        mutationFn: update,
        onSuccess: (response) => {
            // merge response with current user
            setUser({ ...user, ...response });
            handleMeResponse(response, refetch);
        },
    });

    const forgottenPasswordMutation = useMutation<User, AxiosError, ForgottenPasswordPayload>(forgottenPassword);
    const resetPasswordMutation = useMutation<User, AxiosError, ResetPasswordPayload>(resetPassword);
    const resendInvitationMutation = useMutation<User, AxiosError, ResendInvitationPayload>(resendInvitation);

    const value = useMemo(
        () => ({
            user,
            checkSessionError,
            isCheckingSession,
            isRefetchingUser,
            hasChosenOrganization,
            refetchUser: refetch,
            login: loginMutation,
            facebookLogin: facebookLoginMutation,
            logout: logoutMutation,
            forgottenPassword: forgottenPasswordMutation,
            resetPassword: resetPasswordMutation,
            resendInvitation: resendInvitationMutation,
            updateUser: updateUserMutation,
            removeCurrentOrganization,
        }),
        [
            user,
            checkSessionError,
            isCheckingSession,
            isRefetchingUser,
            hasChosenOrganization,
            refetch,
            loginMutation,
            facebookLoginMutation,
            logoutMutation,
            forgottenPasswordMutation,
            resetPasswordMutation,
            resendInvitationMutation,
            updateUserMutation,
            removeCurrentOrganization,
        ]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
