/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 55 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.313 3.493a2.179 2.179 0 0 1 2.524 1.768l.38 2.155L28.964 5.52l-.38-2.155a2.188 2.188 0 0 1 4.301-.759l.38 2.155 9.67-1.705a4.358 4.358 0 0 1 5.048 3.535l6.063 34.387a4.358 4.358 0 0 1-3.535 5.049l-38.678 6.82a4.367 4.367 0 0 1-5.058-3.534L.712 14.927a4.367 4.367 0 0 1 3.544-5.05l9.67-1.705-.38-2.154a2.179 2.179 0 0 1 1.767-2.525Zm-4.237 45.063 38.679-6.82L43.69 7.349l-9.67 1.705.38 2.155a2.188 2.188 0 0 1-4.3.758l-.38-2.155-10.747 1.895.38 2.155a2.179 2.179 0 0 1-4.292.757l-.38-2.155-9.67 1.705L6.53 22.77l4.547 25.786Zm25.115-30.864a2.282 2.282 0 0 0-1.426.964c-3.586 5.133-6.76 10.127-10.267 15.17l-5.885-3.216a2.291 2.291 0 1 0-2.196 4.024l7.704 4.215a2.292 2.292 0 0 0 2.971-.704c3.987-5.717 7.498-11.273 11.415-16.902a2.282 2.282 0 0 0-2.321-3.578l.005.027Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgCalendarCheck: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-calendar-check', props.className)} />
));
export default SvgCalendarCheck;
