import { FC, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Card, Spin, Typography } from 'antd';
import { useModal } from '@ebay/nice-modal-react';

import '../../assets/styles/ChooseHub.less';

import smallLogo from '../../assets/images/logo-leclerc-small.svg';
import Seo from '../../components/Seo';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import { DeviceType } from '../../queries/api/types';
import BasicList from '../../components/BasicList';
import { removeApiKey, setApiKey } from '../../queries/api/apiClient';
import AboutModal from '../../components/AboutModal';

const ChooseHub: FC = () => {
    const { user, refetchUser, isRefetchingUser, hasChosenOrganization } = useAuth();
    const aboutModal = useModal(AboutModal);
    const onSubmit = (apiKey: string) => async () => {
        setApiKey(apiKey);
        const newUser = await refetchUser();

        if (newUser.data?.organization && !newUser.data.acceptedTerms) {
            aboutModal.show({ isLogin: true });
        }
    };

    useEffect(() => {
        // remove api key on load in case we somehow arrived on this page with an api key
        removeApiKey();
    }, []);

    if (hasChosenOrganization && user?.acceptedTerms) {
        return <Redirect to={getRoute(RoutePathName.home)} />;
    }

    return (
        <>
            <Seo title="Choix de la centrale" />
            <div className="flex-auto flex flex-col justify-center items-center">
                <Card bodyStyle={{ maxWidth: 532, overflowY: 'auto', maxHeight: 398 }} bordered={false}>
                    <Typography.Title className="mb-16 font-24 text-center">Choisir une centrale</Typography.Title>
                    <Spin spinning={isRefetchingUser}>
                        <BasicList style={{ margin: '0 -0.5rem -0.5rem -0.5rem', justifyContent: 'center' }} inline>
                            {user?.hubs?.map((item) => {
                                const itemApiKey = item.applications?.[0]?.applicationClients?.find(
                                    (client) => client.type === DeviceType.web
                                )?.apiKey;
                                return (
                                    <li key={item.id} style={{ margin: '0.5rem' }}>
                                        <Button
                                            onClick={onSubmit(itemApiKey!)}
                                            disabled={!itemApiKey}
                                            className="flex flex-col items-center justify-center"
                                        >
                                            <img src={smallLogo} alt="E.Leclerc logo" />
                                            <span className="font-bold text-uppercase">{item.name}</span>
                                        </Button>
                                    </li>
                                );
                            })}
                        </BasicList>
                    </Spin>
                </Card>
            </div>
        </>
    );
};

export default ChooseHub;
