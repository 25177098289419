import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Typography } from 'antd';

import { tutorialModalId } from '../App';
import CustomModal from './CustomModal';

const AdminFirstLoginModal = NiceModal.create<{ hasSeenTutorial?: boolean }>(({ hasSeenTutorial }) => {
    const tutorialModal = useModal(tutorialModalId);
    const modal = useModal();
    const onClose = () => {
        if (!hasSeenTutorial) {
            tutorialModal.show();
        }
        modal.hide();
    };

    return (
        <CustomModal
            title="Ajoutez vos collaborateurs"
            {...antdModal(modal)}
            onCancel={onClose}
            footer={
                <Button type="primary" onClick={onClose} ghost>
                    Accéder au site
                </Button>
            }
            altTitle
        >
            <Typography.Paragraph>
                Nous vous conseillons d&rsquo;ajouter dès maintenant vos collaborateurs qui auront accès à cette
                plateforme. Rendez vous dans le menu situé en haut à droite (cliquez sur votre nom), puis cliquez sur «
                Utilisateurs ».
            </Typography.Paragraph>
        </CustomModal>
    );
});

export default AdminFirstLoginModal;
