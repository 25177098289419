/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 3.667a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM8 2a1.667 1.667 0 1 0 0 3.333A1.667 1.667 0 0 0 8 2Z"
            fill="currentColor"
        />
        <path
            d="M7.333 9C4.312 9 2 11.161 2 13.667a.667.667 0 0 1-1.333 0c0-3.386 3.06-6 6.666-6a.667.667 0 1 1 0 1.333Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.805 8.862a.667.667 0 0 0-.943 0l-3.333 3.333a.666.666 0 0 0-.196.472V14c0 .368.299.667.667.667h1.333c.177 0 .347-.07.472-.196l3.333-3.333a.667.667 0 0 0 0-.943l-1.333-1.333Zm-3.138 4.471v-.39l2.666-2.667.39.39-2.666 2.667h-.39Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgEditName: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-edit-name', props.className)} />
));
export default SvgEditName;
