import { Button, DrawerProps, Form, FormProps, Typography, Spin } from 'antd';
import { antdDrawer, useModal, create as createModal } from '@ebay/nice-modal-react';

import UserAccountFields from '../forms/UserAccountFields';
import { defaultErrorMessage, requiredFieldsText, translateRoleSlug } from '../../i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import CustomDrawer from '../CustomDrawer';
import RoleFormItem from '../forms/RoleFormItem';
import ProvidersField from '../forms/ProvidersField';
import { RoleSlug, User } from '../../queries/api/types';
import { requiredRule } from '../../helpers';
import { useUserCreate, useUserDetails, useUserUpdate } from '../../queries/users';
import { useRoleList } from '../../queries/roles';
import { useAuth } from '../../context/AuthContext';

const initialValuesFormatter = (data?: User) => ({
    user: {
        ...data,
        role: data?.role?.id,
        scope: {
            organizations: data?.scope?.organizations?.map((org) => org.id),
        },
    },
});

interface UserFormDrawerProps extends DrawerProps, Record<string, unknown> {
    id?: User['id'];
}

const UserFormDrawer = createModal<UserFormDrawerProps>(({ id, ...props }) => {
    const { user: me } = useAuth();
    const isEditing = !!id;
    const modal = useModal();
    const [form] = Form.useForm();
    const { data: userDetails, isLoading } = useUserDetails(id, {
        enabled: modal.visible && isEditing,
        onSuccess: (data) => {
            form.setFieldsValue(initialValuesFormatter(data));
        },
    });
    const { mutate: update, isLoading: isUpdating } = useUserUpdate({
        onSuccess: () => {
            modal.hide();
            successMessage({
                content: "L'utilisateur a été modifié avec succès",
            });
        },
        onError: (error) => {
            if (error?.response?.status === 409) {
                const field = Object.keys(error.response?.data?.fields)?.[0];

                if (field) {
                    form.setFields([
                        {
                            name: ['user', field],
                            errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                        },
                    ]);
                }
            } else {
                errorMessage({
                    content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
                });
            }
        },
    });
    const { mutate: create, isLoading: isCreating } = useUserCreate({
        onSuccess: () => {
            modal.hide();
            successMessage({
                content: "L'utilisateur a été créé avec succès",
            });
        },
        onError: (error) => {
            if (error?.response?.status === 409) {
                const field = Object.keys(error.response?.data?.fields)?.[0];

                if (field) {
                    form.setFields([
                        {
                            name: ['user', field],
                            errors: ['Valeur déjà utilisée, veuillez en saisir une autre'],
                        },
                    ]);
                }
            } else {
                errorMessage({
                    content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
                });
            }
        },
    });
    const { data: roles } = useRoleList();
    const providerUserRoleId = roles?.items.find((role) => role.slug === RoleSlug.providerUser)?.id;
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        if (isEditing) {
            update({ id, ...values.user });
        } else {
            create({
                ...values.user,
                organization: me?.organization?.id,
            });
        }
    };

    return (
        <CustomDrawer
            width={500}
            title={isEditing ? "Éditer l'utilisateur" : 'Ajouter un utilisateur'}
            {...props}
            {...antdDrawer(modal)}
        >
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    onFinish={onFormValidSubmit}
                    layout="vertical"
                    initialValues={
                        isEditing
                            ? {
                                  ...initialValuesFormatter(userDetails),
                              }
                            : undefined
                    }
                    scrollToFirstError
                >
                    <RoleFormItem
                        name={['user', 'role']}
                        rules={[requiredRule]}
                        roleSlugs={[RoleSlug.providerUser, RoleSlug.providerAdmin]}
                        optionLabelRender={(record) => translateRoleSlug(record.slug)}
                    />
                    <UserAccountFields />
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                            getFieldValue(['user', 'role']) === providerUserRoleId && (
                                <ProvidersField name={['user', 'scope', 'organizations']} />
                            )
                        }
                    </Form.Item>
                    <Form.Item className="mb-32" shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={isUpdating || isCreating}
                                disabled={
                                    !form.isFieldsTouched() ||
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                                }
                                block
                            >
                                {isEditing ? "Modifier l'utilisateur" : "Ajouter l'utilisateur"}
                            </Button>
                        )}
                    </Form.Item>
                    <Typography.Paragraph className="text-center mb-0">
                        <Typography.Text className="font-12">{requiredFieldsText}</Typography.Text>
                    </Typography.Paragraph>
                </Form>
            </Spin>
        </CustomDrawer>
    );
});

export default UserFormDrawer;
