import { Button, Space, Typography } from 'antd';
import { useEffect, VFC } from 'react';

import { classNames, downloadFile, getFullName } from '../../helpers';
import { formatDate } from '../../i18n';
import { Penalty, User } from '../../queries/api/types';
import PenaltyStatusTag from './PenaltyStatusTag';
import PenaltyAddAttachmentDrawer from '../drawers/PenaltyContestFormDrawer';
import { useScrollYPosition } from '../../hooks/useScrollPosition';

interface OpenByProps {
    user?: User;
    date?: string;
}

const OpenBy: VFC<OpenByProps> = ({ user, date }) => {
    if (!date) {
        return null;
    }

    return (
        <Typography.Text type="secondary">
            Ouvert {[user && `par ${getFullName(user)}`, `le ${formatDate(date)}`].filter(Boolean).join(' ')}
        </Typography.Text>
    );
};

const penaltyAttachmentDrawerId = 'penaltyAttachmentDrawer';

interface PenaltyHeaderProps {
    penalty?: Penalty;
}

const PenaltyHeader: VFC<PenaltyHeaderProps> = ({ penalty }) => {
    const scrollY = useScrollYPosition<HTMLElement>(
        document.querySelector('.penalty-details-drawer .ant-drawer-body') as HTMLElement
    );
    const isScrolled = Math.floor(scrollY) > 56;
    const onClickDownload = () => {
        downloadFile(penalty?.proforma?.url);
    };

    useEffect(() => {
        window.dispatchEvent(new CustomEvent('scroll'));
        // querySelector of useScrollYPosition sometimes is undefined, adding this dispatchEvent retrigger the component
        // render to avoid querySelector being sometimes undefined (ezee/scapnor/scapnor-gestion-incidents/support#54)
    }, []);

    return (
        <>
            <Space size={16}>
                <PenaltyStatusTag penalty={penalty} />
            </Space>
            <div
                className={classNames(
                    'flex justify-between items-center sticky -top-24 z-5 bg-white py-8 px-24 -mx-24 -mb-8 transition-all',
                    isScrolled && 'shadow'
                )}
            >
                <div>
                    <Typography.Text className="block text-primary leading-6 mb-0 font-medium font-16">
                        Avis émis le {formatDate(penalty?.date)}
                    </Typography.Text>
                    <OpenBy user={penalty?.firstViewedBy} date={penalty?.firstViewedAt} />
                </div>
                <div className="flex items-center">
                    {isScrolled ? (
                        <PenaltyStatusTag penalty={penalty} />
                    ) : (
                        <Button type="primary" onClick={onClickDownload} disabled={!penalty?.proforma?.url} ghost>
                            Télécharger
                        </Button>
                    )}
                </div>
            </div>
            <PenaltyAddAttachmentDrawer id={penaltyAttachmentDrawerId} penalty={penalty} />
        </>
    );
};

export default PenaltyHeader;
