import { Button, Divider, Form, FormProps, Input, Upload, UploadProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState, VFC } from 'react';

import { isMSGFile, requiredRule } from '../../helpers';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { CommentType, Penalty, PenaltyStatus } from '../../queries/api/types';
import { usePenaltyUpdate } from '../../queries/penalties';

const statusesAllowingComment = [
    PenaltyStatus.contested,
    PenaltyStatus.contestedPartially,
    PenaltyStatus.waitingForValidation,
];

interface PenaltyNewCommentProps {
    penalty?: Penalty;
}

const PenaltyNewComment: VFC<PenaltyNewCommentProps> = ({ penalty }) => {
    const [, forceUpdate] = useState(false);
    const [form] = useForm();
    const { mutate: updatePenalty, isLoading } = usePenaltyUpdate({
        onError: (error) => {
            errorMessage({
                content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
            });
        },
        onSuccess: () => {
            successMessage({ content: 'Message envoyé avec succès' });
            form.resetFields();
        },
    });
    const normFile = (e: any) => (Array.isArray(e) ? e : e?.fileList);
    const onBeforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (isMSGFile(file)) {
            errorMessage({ content: 'Les fichiers de type .msg ne sont pas acceptés' });
            return Upload.LIST_IGNORE;
        }

        if (file.size / 1024 > 100000) {
            errorMessage({
                content: 'Le fichier dépasse la limite de taille maximum de 100Mo',
            });
            return Upload.LIST_IGNORE;
        }

        return false;
    };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        updatePenalty({
            id: penalty?.id,
            ...values,
            comment: {
                ...values.comment,
                type: CommentType.contestation,
            },
        });
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate(true);
    }, [forceUpdate]);

    return penalty?.status && statusesAllowingComment.includes(penalty?.status) ? (
        <>
            <Divider orientation="left">Nouveau message</Divider>
            <Form form={form} onFinish={onFormValidSubmit} layout="vertical" scrollToFirstError>
                <Form.Item name={['comment', 'content']} rules={[requiredRule]}>
                    <Input.TextArea placeholder="Saisir un message" rows={5} />
                </Form.Item>
                <div className="flex justify-between items-center mt-32">
                    <Form.Item
                        name={['commentAttachments']}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                    >
                        <Upload beforeUpload={onBeforeUpload}>
                            <Button type="primary" ghost>
                                Joindre des fichiers
                            </Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={
                                    !form.isFieldsTouched() ||
                                    form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                                }
                            >
                                Envoyer le message
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </>
    ) : null;
};

export default PenaltyNewComment;
