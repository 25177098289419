import { VFC } from 'react';
import { SelectProps } from 'antd';

import TagSelect from '../TagSelect';
import { translatePenaltySelectStatus } from '../../i18n';
import { PenaltyStatus } from '../../queries/api/types';
import { getPenaltyStatusColor } from '../../helpers';

const options: Array<{
    label: string;
    value: PenaltyStatus;
}> = Object.values(PenaltyStatus)
    .map((status) => ({
        label: translatePenaltySelectStatus(status),
        value: status,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

const PenaltyStatusTagSelect: VFC<SelectProps<PenaltyStatus> & { exclude?: PenaltyStatus[] }> = ({
    exclude,
    ...props
}) => (
    <TagSelect<PenaltyStatus>
        placeholder="Choisir un statut"
        filterOption={false}
        options={options.filter((option) => !exclude?.includes(option.value))}
        tagColor={(value) => getPenaltyStatusColor(value)}
        showArrow
        {...props}
    />
);

export default PenaltyStatusTagSelect;
