import { message, MessageArgsProps } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

export const successMessage = (args: Partial<MessageArgsProps>) => {
    message.success({
        icon: <CheckCircleFilled />,
        ...args,
    });
};

export const errorMessage = (args: Partial<MessageArgsProps>) => {
    message.error({
        icon: <CloseCircleFilled />,
        ...args,
    });
};

export const warningMessage = (args: Partial<MessageArgsProps>) => {
    message.warning({
        icon: <ExclamationCircleFilled />,
        ...args,
    });
};
