import { Tag } from 'antd';
import { VFC } from 'react';

import { getPenaltyContestationStatusColor } from '../../helpers';
import { translatePenaltyContestationStatus } from '../../i18n';
import { Penalty } from '../../queries/api/types';

interface PenaltyCommentStatusTagProps {
    penalty?: Penalty;
}

const PenaltyCommentStatusTag: VFC<PenaltyCommentStatusTagProps> = ({ penalty }) => {
    return (
        <Tag color={getPenaltyContestationStatusColor(penalty?.contestationStatus)}>
            {translatePenaltyContestationStatus(penalty)}
        </Tag>
    );
};

export default PenaltyCommentStatusTag;
