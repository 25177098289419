import { Tag, TagProps } from 'antd';
import { FC } from 'react';

import '../assets/styles/TagAlt.less';

import { classNames } from '../helpers';

const TagAlt: FC<TagProps> = ({ className, ...props }) => (
    <Tag {...props} className={classNames('tag-alt', className)} />
);

export default TagAlt;
