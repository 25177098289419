import { VFC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Dropdown, Avatar, Menu, Button, Badge } from 'antd';
import { SwapOutlined, TeamOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';

import { getRawRoute, getRoute, RoutePathName } from '../routes';
import { useAuth } from '../context/AuthContext';
import { TipsOne, BuildingOne, Lock, Logout, EditName, Helpcenter } from './icons';
import AboutModal from './AboutModal';
import ProfileFormDrawer from './drawers/ProfileFormDrawer';
import PasswordFormDrawer from './drawers/PasswordFormDrawer';
import CompanyFormDrawer from './drawers/CompanyFormDrawer';
import UserListDrawer from './drawers/UserListDrawer';
import { hasRole } from '../helpers/security';
import { RoleSlug } from '../queries/api/types';
import { tutorialModalId } from '../App';

const UserDropdown: VFC = () => {
    const { user, logout, removeCurrentOrganization } = useAuth();
    const canValidateOrganization =
        user?.organization?.id &&
        user.scope?.organizations?.some((organization) => organization.id === user?.organization?.id);
    const hasVerifiedOrganizationData = !!user?.organization?.hasVerifiedData;
    const aboutModal = useModal(AboutModal);
    const tutorialModal = useModal(tutorialModalId);
    const profileDrawer = useModal(ProfileFormDrawer);
    const passwordDrawer = useModal(PasswordFormDrawer);
    const companyDrawer = useModal(CompanyFormDrawer);
    const userListDrawer = useModal(UserListDrawer);
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const isChooseHubPage = useRouteMatch(getRawRoute(RoutePathName.chooseHub));
    const menu = (
        <Menu>
            {!isValidateAccountPage && !isChooseHubPage && (
                <>
                    <Menu.Item
                        icon={<EditName />}
                        key="profileDrawer"
                        onClick={() => {
                            profileDrawer.show();
                        }}
                    >
                        Votre profil
                    </Menu.Item>
                    <Menu.Item
                        icon={<Lock />}
                        key="passwordDrawer"
                        onClick={() => {
                            passwordDrawer.show();
                        }}
                    >
                        Modifier le mot de passe
                    </Menu.Item>
                    {canValidateOrganization && (
                        <>
                            <Menu.Divider />
                            <Menu.ItemGroup title="Société">
                                <Menu.Item
                                    icon={<BuildingOne />}
                                    key="company"
                                    onClick={() => {
                                        companyDrawer.show();
                                    }}
                                >
                                    {hasVerifiedOrganizationData ? 'Informations' : <Badge dot>Informations</Badge>}
                                </Menu.Item>
                                {hasRole(user, [RoleSlug.providerAdmin]) && (
                                    <Menu.Item
                                        icon={<TeamOutlined />}
                                        key="companyUsers"
                                        onClick={() => {
                                            userListDrawer.show();
                                        }}
                                    >
                                        Utilisateurs
                                    </Menu.Item>
                                )}
                            </Menu.ItemGroup>
                        </>
                    )}
                    <Menu.Divider />
                    {(user?.hubs?.length ?? 0) > 1 && (
                        <Menu.Item
                            icon={<SwapOutlined />}
                            key="org"
                            onClick={() => {
                                removeCurrentOrganization();
                            }}
                        >
                            Changer de centrale
                        </Menu.Item>
                    )}
                    <Menu.Item
                        icon={<Helpcenter />}
                        key="tutorial"
                        onClick={() => {
                            tutorialModal.show();
                        }}
                    >
                        Revoir le tutoriel
                    </Menu.Item>
                    <Menu.Item
                        icon={<TipsOne />}
                        key="aboutModal"
                        onClick={() => {
                            aboutModal.show({ title: 'À propos' });
                        }}
                    >
                        À propos
                    </Menu.Item>
                    <Menu.Divider />
                </>
            )}
            <Menu.Item key="logout" icon={<Logout />}>
                <Link
                    to={getRoute(RoutePathName.login)}
                    onClick={() => {
                        logout.mutate();
                    }}
                >
                    Déconnexion
                </Link>
            </Menu.Item>
        </Menu>
    );

    const avatar = <Avatar size={40}>{user?.firstName?.substring(0, 1)}</Avatar>;

    return user?.firstName ? (
        <Dropdown trigger={['click']} overlay={menu} placement="topRight">
            <Button id="user-menu-button" type="text">
                {!isValidateAccountPage &&
                !isChooseHubPage &&
                canValidateOrganization &&
                !hasVerifiedOrganizationData ? (
                    <Badge offset={[-16, 4]} dot>
                        {avatar}
                    </Badge>
                ) : (
                    avatar
                )}
                <div id="user-menu-button-content">
                    <span>{user?.firstName}</span>
                    <span>{user?.organization?.name}</span>
                </div>
            </Button>
        </Dropdown>
    ) : null;
};

export default UserDropdown;
