/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.667 10a.667.667 0 0 0-1.334 0v2a.667.667 0 1 0 1.334 0v-2Z" fill="currentColor" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 4.667v2H2.667c-.737 0-1.334.597-1.334 1.333v6c0 .736.597 1.333 1.334 1.333h10.666c.737 0 1.334-.597 1.334-1.333V8c0-.736-.597-1.333-1.334-1.333H12v-2a4 4 0 0 0-8 0ZM8 2a2.667 2.667 0 0 0-2.667 2.667v2h5.334v-2A2.667 2.667 0 0 0 8 2ZM2.667 8h10.666v6H2.667V8Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgLock: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-lock', props.className)} />
));
export default SvgLock;
