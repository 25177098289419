import { Typography } from 'antd';
import { VFC } from 'react';

import { Penalty, CommentType } from '../../queries/api/types';
import Comment from '../Comment';

interface PenaltyStatusUpdateCommentsProps {
    penalty?: Penalty;
}

const PenaltyStatusUpdateComments: VFC<PenaltyStatusUpdateCommentsProps> = ({ penalty }) => {
    return penalty?.comments?.length ? (
        <>
            <div className="mb-16 space-x-4">
                <Typography.Text strong>Commentaire de modification de statut :</Typography.Text>
            </div>
            {penalty?.comments
                .filter((c) => c.type === CommentType.changeStatus)
                .map((comment, index) => (
                    <Comment
                        key={comment.id}
                        penaltyId={penalty?.id}
                        data={comment}
                        className={index > 0 ? 'mt-16' : undefined}
                    />
                ))}
        </>
    ) : null;
};

export default PenaltyStatusUpdateComments;
