import { Typography } from 'antd';
import { VFC } from 'react';

import { formatPrice } from '../../i18n';
import { Fee } from '../../queries/api/types';
import {
    classNames,
    getFeesTotalInitialAcceptedAmount,
    getFeesTotalInitialContestedAmount,
    getFeesTotalInitialAmount,
} from '../../helpers';

interface PenaltyFeesTotalBarProps {
    fees?: Fee[];
    isSubTotal?: boolean;
    positiveFees?: Fee[];
}

const PenaltyFeesTotalBar: VFC<PenaltyFeesTotalBarProps> = ({ fees, isSubTotal, positiveFees }) => {
    const total = getFeesTotalInitialAmount(fees);
    const acceptedTotal = getFeesTotalInitialAcceptedAmount(fees);
    const contestedTotal = getFeesTotalInitialContestedAmount(positiveFees);

    return (
        <>
            {!isSubTotal && (!!acceptedTotal || !!contestedTotal) && (
                <Typography.Text className="block my-16" strong>
                    Total montants
                </Typography.Text>
            )}
            <div className="rounded border-solid border-base border-2 flex">
                <div
                    className={classNames(
                        'py-16 px-24 flex-1 flex items-center',
                        !!acceptedTotal || !!contestedTotal ? 'justify-center gap-16' : 'justify-between'
                    )}
                >
                    <Typography.Text type="secondary" className="font-medium font-16 leading-7">
                        {isSubTotal ? 'Sous-total TTC' : 'Montant TTC'} :
                    </Typography.Text>
                    <Typography.Text className="font-16 leading-7" strong>
                        {formatPrice(total)}
                    </Typography.Text>
                </div>
                {!!acceptedTotal && (
                    <div className="py-16 px-24 flex-1 border-l-solid border-l-2 border-base flex justify-center gap-16 items-center bg-ultra-light-green">
                        <Typography.Text className="font-medium font-16 leading-7 text-green">
                            {isSubTotal ? 'Sous-total' : 'Montant'} Accepté :
                        </Typography.Text>
                        <Typography.Text className="font-16 leading-7 text-green" strong>
                            {formatPrice(acceptedTotal)}
                        </Typography.Text>
                    </div>
                )}
                {!!contestedTotal && (
                    <div className="py-16 px-24 flex-1 border-l-solid border-l-2 border-base flex justify-center gap-16 items-center bg-ultra-light-red">
                        <Typography.Text className="font-medium font-16 leading-7 text-red">
                            {isSubTotal ? 'Sous-total' : 'Montant'} Contesté :
                        </Typography.Text>
                        <Typography.Text className="font-16 leading-7 text-red" strong>
                            {formatPrice(contestedTotal)}
                        </Typography.Text>
                    </div>
                )}
            </div>
        </>
    );
};

export default PenaltyFeesTotalBar;
