import { compile } from 'path-to-regexp';

import { debug } from './helpers';

export enum RoutePathName {
    usersList = 'usersList',
    usersDetails = 'usersDetails',
    home = 'home',
    login = 'login',
    notFound = 'notFound',
    privacy = 'privacy',
    forgottenPassword = 'forgottenPassword',
    resetPassword = 'resetPassword',
    notifications = 'notifications',
    organizations = 'organizations',
    validateAccount = 'validateAccount',
    penalties = 'penalties',
    appointments = 'appointments',
    chooseHub = 'chooseHub',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.usersList]: '/users',
    [RoutePathName.usersDetails]: '/users/:userId',
    [RoutePathName.home]: '/',
    [RoutePathName.login]: '/login',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.privacy]: '/confidentialite',
    [RoutePathName.forgottenPassword]: '/mot-de-passe-oublie',
    [RoutePathName.resetPassword]: '/reset-password',
    [RoutePathName.notifications]: '/notifications',
    [RoutePathName.organizations]: '/organizations',
    [RoutePathName.validateAccount]: '/validation-compte',
    [RoutePathName.chooseHub]: '/centrale',
    [RoutePathName.penalties]: '/penalites/:penaltyId?',
    [RoutePathName.appointments]: '/rendez-vous',
};

export interface RouteParams {
    [param: string]: string | number;
}

// returns raw react-router string path eg: /user/:id
export const getRawRoute = (path: RoutePathName) => {
    if (!routes[path]) {
        debug.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path eg: /user/1337
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
    anchor?: string
) => {
    let route = getRawRoute(path);
    const compiler = compile(route, { encode: encodeURIComponent });

    try {
        route = compiler(params);
    } catch (error) {
        debug.error('[getRoute] route compile error :', error);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    if (anchor) {
        route = `${route}#${anchor}`;
    }

    return route;
};
