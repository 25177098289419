import { SearchPaginationQuery } from '.';
import api from './apiClient';
import { ListResponse, Organization, Role, RoleSlug, User } from './types';

export type UserListPayload = SearchPaginationQuery & {
    roles?: RoleSlug[];
};
export type UserIdPayload = User['id'];

export const details = async (payload?: UserIdPayload) => {
    if (!payload) {
        throw new Error('missing id');
    }

    return await api.get<User>(`/users/${payload}`).then((response) => response?.data);
};

export type UserslistResponse = ListResponse<User>;
export const list = async (payload: UserListPayload = {}) => {
    return await api.get<UserslistResponse>('/users', { params: payload }).then((response) => response?.data);
};

export interface UserCreatePayload
    extends Omit<User, 'id' | 'role' | 'organizations'>,
        Pick<User, 'firstName' | 'lastName' | 'job' | 'email'> {
    role?: RoleSlug;
    organizations?: Array<Organization['id']>;
}
export const create = async (payload: UserCreatePayload) => {
    return await api.post<User>('/users', payload).then((response) => response?.data);
};

export interface UserUpdatePayload extends Omit<User, 'id' | 'role'> {
    id?: User['id'];
    role?: Role['id'];
}
export const update = async ({ id, ...payload }: UserUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<User>(`/users/${id}`, payload).then((response) => response?.data);
};

export const remove = async (id?: UserIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/users/${id}`).then((response) => response?.data);
};
