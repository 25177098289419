import { Divider, Typography } from 'antd';
import { VFC } from 'react';

import { CommentType, Penalty } from '../../queries/api/types';
import Comment from '../Comment';
import PenaltyCommentStatusTag from './PenaltyCommentStatusTag';

interface PenaltyCommentsProps {
    penalty?: Penalty;
}

const PenaltyComments: VFC<PenaltyCommentsProps> = ({ penalty }) => {
    const comments = penalty?.comments?.filter((comment) => comment.type !== CommentType.acceptContestation);

    return comments?.length ? (
        <>
            <Divider style={{ marginLeft: -24, marginRight: -24, width: 'auto' }} />
            <div className="mb-16 space-x-4">
                <Typography.Text strong>Ticket de contestation</Typography.Text>
                <PenaltyCommentStatusTag penalty={penalty} />
            </div>
            {comments
                .filter((c) => c.type !== CommentType.changeStatus)
                .map((comment, index) => (
                    <Comment
                        key={comment.id}
                        penaltyId={penalty?.id}
                        data={comment}
                        className={index > 0 ? 'mt-16' : undefined}
                    />
                ))}
        </>
    ) : null;
};

export default PenaltyComments;
