import { Select as AntdSelect, Tag, TagProps } from 'antd';
import { RefSelectProps, SelectProps, SelectValue } from 'antd/lib/select';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { MouseEventHandler } from 'react';

import CustomSelect from './CustomSelect';

export type TagSelectProps<VT extends SelectValue = SelectValue> = SelectProps<VT> & {
    ref?: ((instance: RefSelectProps | null) => void) | React.RefObject<RefSelectProps> | null | undefined;
    tagColor?: TagProps['color'] | ((value: any) => TagProps['color']);
};

declare type InternalTagSelectType = <VT extends SelectValue = SelectValue>(
    props: TagSelectProps<VT>
) => React.ReactElement;
interface SelectInterface extends InternalTagSelectType {
    SECRET_COMBOBOX_MODE_DO_NOT_USE: string;
    Option: typeof AntdSelect.Option;
    OptGroup: typeof AntdSelect.OptGroup;
}

const TagSelect: SelectInterface = ({ tagColor, ...props }) => {
    function tagRender({ label, closable, onClose, value }: CustomTagProps) {
        const onPreventMouseDown: MouseEventHandler<HTMLSpanElement> = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={typeof tagColor === 'function' ? tagColor?.(value) : tagColor}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 4 }}
            >
                {label}
            </Tag>
        );
    }
    return <CustomSelect mode="multiple" maxTagCount="responsive" tagRender={tagRender} {...props} />;
};

TagSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE = CustomSelect.SECRET_COMBOBOX_MODE_DO_NOT_USE;
TagSelect.Option = CustomSelect.Option;
TagSelect.OptGroup = CustomSelect.OptGroup;

export default TagSelect;
