import { FC, useMemo } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';

import { FeeStatus, Penalty } from '../../queries/api/types';
import PenaltyAddAttachmentDrawer from '../drawers/PenaltyContestFormDrawer';
import { usePenaltyUpdate } from '../../queries/penalties';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { usePenaltyDrawerContext } from '../../context/PenaltyDrawerContext';

interface PenaltyContestFormProps {
    penalty?: Penalty;
}

const PenaltyContestForm: FC<PenaltyContestFormProps> = ({ penalty }) => {
    const { setIsSelectingFees, isSelectingFees, selectedFeeIdsByOrderRef, setSelectedFeeIdsByOrderRef } =
        usePenaltyDrawerContext();
    const selectedFeeIds = useMemo(
        () => (selectedFeeIdsByOrderRef ? Object.values(selectedFeeIdsByOrderRef).flat() : undefined),
        [selectedFeeIdsByOrderRef]
    );
    const contestFormDrawer = useModal(PenaltyAddAttachmentDrawer);
    const { mutateAsync: updatePenalty } = usePenaltyUpdate();
    const onContestAll = () => {
        Modal.confirm({
            width: 500,
            centered: true,
            title: 'Contester l’ensemble',
            icon: <ExclamationCircleOutlined className="text-red" />,
            content: 'Êtes-vous sûr de vouloir contester l’ensemble des lignes de cet avis de pénalité ?',
            okText: 'Confirmer',
            okType: 'primary',
            cancelText: 'Annuler',
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            onOk: () => {
                contestFormDrawer.show({ penalty, selectedFeeIds: penalty?.fees?.map((fee) => fee.id) });
            },
        });
    };
    const onContestPartial = () => {
        Modal.confirm({
            width: 500,
            centered: true,
            title: 'Contester des lignes',
            icon: <ExclamationCircleOutlined className="text-red" />,
            content: 'Sélectionnez les lignes que vous souhaitez contester',
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            onOk: () => {
                setIsSelectingFees(true);
            },
        });
    };
    const onAccept = () => {
        Modal.confirm({
            width: 500,
            centered: true,
            title: 'Accepter sans contestation',
            icon: <ExclamationCircleOutlined className="text-orange" />,
            content: (
                <Typography.Paragraph className="mb-0">
                    Êtes-vous sûr de vouloir accepter cet avis de pénalité sans contestation ?
                </Typography.Paragraph>
            ),
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            okText: 'Confirmer',
            onOk: async () => {
                return await updatePenalty(
                    {
                        id: penalty?.id,
                        fees: penalty?.fees?.map((fee) => ({
                            id: fee.id,
                            status: FeeStatus.accepted,
                        })),
                    },
                    {
                        onSuccess: () => {
                            successMessage({ content: 'Avis de pénalité sauvegardé avec succès' });
                        },
                        onError: () => {
                            errorMessage({ content: defaultErrorMessage });
                        },
                    }
                );
            },
        });
    };
    const onSubmitSelect = () => {
        contestFormDrawer
            .show({ penalty, selectedFeeIds })
            .then(() => {
                setIsSelectingFees(false);
                setSelectedFeeIdsByOrderRef(undefined);
            })
            .catch(() => {
                setIsSelectingFees(false);
                setSelectedFeeIdsByOrderRef(undefined);
            });
    };
    const onCancelSelect = () => {
        setIsSelectingFees(false);
    };

    return (
        <Spin spinning={!penalty}>
            {isSelectingFees ? (
                <div className="flex justify-between">
                    <Button type="primary" size="large" onClick={onCancelSelect} ghost>
                        Annuler
                    </Button>
                    <Button size="large" onClick={onSubmitSelect} disabled={!selectedFeeIds?.length} danger>
                        Contester les lignes sélectionnées
                    </Button>
                </div>
            ) : (
                <div className="flex justify-between">
                    <div>
                        <Button size="large" onClick={onContestAll} danger ghost>
                            Contester l&rsquo;ensemble
                        </Button>
                        <Button
                            className="ml-12"
                            size="large"
                            onClick={onContestPartial}
                            disabled={
                                (penalty?.fees?.filter((fee) => fee.status === FeeStatus.pending)?.length ?? 0) <= 1
                            }
                            danger
                            ghost
                        >
                            Contester des lignes
                        </Button>
                    </div>
                    <Button className="ant-btn-success" size="large" onClick={onAccept}>
                        Accepter sans contestation
                    </Button>
                </div>
            )}
        </Spin>
    );
};

export default PenaltyContestForm;
