/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.946 2.409h-.16c.088 0 .16-.072.16-.16v.16h6.107v-.16c0 .088.073.16.161.16h-.16v1.446H12.5V2.248c0-.709-.577-1.286-1.286-1.286H4.786c-.71 0-1.286.577-1.286 1.286v1.607h1.446V2.41Zm10.125 1.446H.93a.642.642 0 0 0-.643.643v.643c0 .088.072.16.16.16H1.66l.496 10.507a1.287 1.287 0 0 0 1.284 1.226h9.12c.687 0 1.252-.538 1.284-1.226l.496-10.506h1.213c.089 0 .161-.073.161-.161v-.643a.642.642 0 0 0-.643-.643Zm-2.665 11.733H3.594L3.108 5.302h9.784l-.486 10.286Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgBin: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-bin', props.className)} />
));
export default SvgBin;
