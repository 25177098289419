import { FC } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Penalty, PenaltyStatus } from '../../queries/api/types';
import { usePenaltyUpdate } from '../../queries/penalties';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { useCurrentHubName } from '../../context/AuthContext';

interface PenaltyAcceptDecisionFormProps {
    penalty?: Penalty;
    onCloseDrawer: () => void;
}

const PenaltyAcceptDecisionForm: FC<PenaltyAcceptDecisionFormProps> = ({ penalty, onCloseDrawer }) => {
    const hubName = useCurrentHubName();
    const { mutateAsync: updatePenalty } = usePenaltyUpdate();
    const onAccept = () => {
        Modal.confirm({
            width: 500,
            centered: true,
            title: 'Accepter la décision',
            icon: <ExclamationCircleOutlined className="text-red" />,
            content: 'Êtes-vous sûr de vouloir finalement accepter cet avis de pénalité ?',
            okText: 'Accepter et clôturer',
            okType: 'primary',
            cancelText: 'Annuler',
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            onOk: async () => {
                return await updatePenalty(
                    {
                        id: penalty?.id,
                        status: PenaltyStatus.accepted,
                    },
                    {
                        onSuccess: () => {
                            successMessage({ content: 'Avis de pénalité accepté avec succès' });
                            onCloseDrawer();
                        },
                        onError: () => {
                            errorMessage({ content: defaultErrorMessage });
                        },
                    }
                );
            },
        });
    };

    return (
        <Spin spinning={!penalty}>
            <div className="flex justify-between items-center">
                <Typography.Text strong>Acceptez-vous la décision de la {hubName} ?</Typography.Text>
                <Button className="ant-btn-success" size="large" onClick={onAccept}>
                    Accepter et clôturer l&rsquo;avis de pénalité
                </Button>
            </div>
        </Spin>
    );
};

export default PenaltyAcceptDecisionForm;
