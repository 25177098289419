import { useMemo } from 'react';
import dayjs from 'dayjs';
import { Spin } from 'antd';

import { AppointmentSlot } from '../../queries/api/types';
import { useAppointmentSlotList } from '../../queries/appointmentsSlots';
import '../../assets/styles/AppointmentTimeSlots.less';

interface AppointmentTimeSlotProps extends Pick<AppointmentSlot, 'fromDate' | 'toDate'> {
    timeSlots: Pick<AppointmentSlot, 'fromDate' | 'toDate'> | null;
    onTimeSlotChange: (fromDate: string, toDate: string) => void;
}

interface AppointmentTimeSlotsViews {
    [key: string]: AppointmentSlot[];
}

const AppointmentTimeSlots = ({ fromDate, toDate, timeSlots, onTimeSlotChange }: AppointmentTimeSlotProps) => {
    const { isLoading, isFetching, data } = useAppointmentSlotList({
        fromDate,
        toDate,
    });
    const timeSlotsViews = useMemo(() => {
        // @todo fill slots with '-'
        // const maxTimeInData = (data ?? []).reduce(
        //     (acc, slot) => {
        //         const currentDateHours = dayjs(slot.fromDate).hour();
        //         const currentDateMinutes = dayjs(slot.fromDate).minute();

        //         if (currentDateHours >= acc.hours) {
        //             if (currentDateHours > acc.hours || currentDateMinutes > acc.minutes) {
        //                 return {
        //                     hours: currentDateHours,
        //                     minutes: currentDateMinutes,
        //                 };
        //             }
        //         }

        //         return acc;
        //     },
        //     { hours: 0, minutes: 0 }
        // );

        return (data ?? []).reduce<AppointmentTimeSlotsViews>((acc, slot) => {
            const currentDate = dayjs(slot.fromDate).format('YYYY-MM-DD');

            return {
                ...acc,
                [currentDate]: [...(acc[currentDate] || []), ...(slot.isAvailable ? [slot] : [])],
            };
        }, {});
    }, [data]);

    return (
        <Spin spinning={isFetching}>
            <div className="flex w-full justify-around">
                {isLoading ? (
                    <Spin />
                ) : (
                    <>
                        {!Object.values(timeSlotsViews).length && <div>Aucun créneau horaire disponible</div>}
                        {Object.values(timeSlotsViews).map((timeSlotsView, index) => {
                            return (
                                <div key={index} className="appointment-time-slot flex flex-col items-center">
                                    <div className="appointment-time-slot-date font-14 font-bold">
                                        {dayjs(Object.keys(timeSlotsViews)[index]).format('dddd D MMM')}
                                    </div>
                                    {timeSlotsView.map((slot, index) => {
                                        const isActive = timeSlots?.fromDate === slot.fromDate;
                                        return (
                                            <div
                                                key={index}
                                                className={`appointment-time-slot-time font-14 font-medium cursor-pointer ${
                                                    isActive ? 'appointment-time-slot-time-active' : ''
                                                }`}
                                                onClick={() => onTimeSlotChange(slot.fromDate, slot.toDate)}
                                                onKeyDown={() => onTimeSlotChange(slot.fromDate, slot.toDate)}
                                                role="button"
                                                tabIndex={index}
                                            >
                                                {dayjs(slot.fromDate).format('HH[h]mm')}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </>
                )}
            </div>
        </Spin>
    );
};

export default AppointmentTimeSlots;
