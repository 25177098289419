import { useMemo, VFC } from 'react';
import { SelectProps } from 'antd';

import TagSelect from '../TagSelect';
import ApiResult from '../ApiResult';
import { usePenaltyListTypes } from '../../queries/penalties';

const PenaltyTypeTagSelect: VFC<SelectProps<string | 'all'>> = (props) => {
    const { data: types, isError } = usePenaltyListTypes();
    const options = useMemo(() => {
        return types?.items
            .map(({ id, fields }) => ({
                key: id,
                label: `${fields.penaltyTypeName}`,
                value: `${fields.penaltyTypeValue}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [types]);

    return (
        <TagSelect<string | 'all'>
            placeholder="Choisir un type"
            filterOption={false}
            options={options}
            tagColor="blue"
            showSearch={false}
            status={isError ? 'error' : undefined}
            notFoundContent={isError ? <ApiResult status={404} inline /> : undefined}
            showArrow
            {...props}
        />
    );
};

export default PenaltyTypeTagSelect;
