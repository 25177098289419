/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.448 1.028a.5.5 0 0 0-.897.002L4.138 3.912l-3.21.466a.5.5 0 0 0-.278.852l2.327 2.274-.556 3.16a.5.5 0 0 0 .728.527l2.85-1.52 2.852 1.52a.5.5 0 0 0 .728-.527l-.552-3.16L11.35 5.23a.5.5 0 0 0-.278-.852L7.88 3.912 6.448 1.028Z"
            fill="#F08E02"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgStar: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-star', props.className)} />
));
export default SvgStar;
