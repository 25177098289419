import { useEffect, useRef, useState, VFC } from 'react';
import { Dropdown, Badge, Button, Spin } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { BellOutlined, CheckOutlined, FileAddOutlined, FileExclamationOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useNotificationList, useNotificationListInfinite, useNotificationUpdate } from '../queries/notifications';
import constants from '../config/constants';
import { Notification, NotificationContextType, NotificationStatus } from '../queries/api/types';
import { CalendarThree, Inbox } from './icons';
import { formatDate, formatRelativeTime } from '../i18n';
import { useAuth } from '../context/AuthContext';
import { getRoute, RoutePathName } from '../routes';
import ApiResult from './ApiResult';
import BasicList from './BasicList';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { classNames } from '../helpers';

let animationTimeout: number;

const getNotificationIcon = (payloadContext?: { [key: string]: any }) => {
    switch (payloadContext?.type) {
        case NotificationContextType.newAppointment:
            return <Avatar size={32} className="font-16 bg-blue" icon={<CalendarThree />} />;

        case NotificationContextType.appointmentAccepted:
        case NotificationContextType.appointmentSigned:
            return <Avatar size={32} className="font-16 bg-green" icon={<CalendarThree />} />;

        case NotificationContextType.appointmentRefused:
        case NotificationContextType.appointmentCanceled:
            return <Avatar size={32} className="font-16 bg-red" icon={<CalendarThree />} />;

        case NotificationContextType.appointmentNotSigned:
            return <Avatar size={32} className="font-16 bg-orange" icon={<CalendarThree />} />;

        case NotificationContextType.contestAccepted:
        case NotificationContextType.contestAutoAccepted:
            return <Avatar size={32} className="font-16 bg-green" icon={<Inbox />} />;

        case NotificationContextType.contestRefused:
            return <Avatar size={32} className="font-16 bg-red" icon={<Inbox />} />;

        case NotificationContextType.newProforma:
            return <Avatar size={32} className="font-16 bg-orange" icon={<Inbox />} />;

        case NotificationContextType.documentsAdded:
            return <Avatar size={32} className="font-16 bg-blue" icon={<FileAddOutlined />} />;

        case NotificationContextType.documentsDeleted:
            return <Avatar size={32} className="font-16 bg-blue" icon={<FileExclamationOutlined />} />;

        default:
            return <Avatar size={32} className="font-16 bg-blue" icon={<Inbox />} />;
    }
};

const NotificationsDropdown: VFC = () => {
    const { user } = useAuth();
    const history = useHistory();
    const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [refetchInterval, setRefetchInterval] = useState(constants.NOTIFICATIONS_REFETCH_INTERVAL_IN_MS);
    const loadMoreRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const isAtBottom = useIntersectionObserver(loadMoreRef, { threshold: 0.1 });
    const { data: notificationCountList } = useNotificationList(
        {
            pageSize: 1,
        },
        {
            refetchInterval,
            onError: () => setRefetchInterval(0),
        }
    );
    const {
        data: notificationList,
        isError,
        isSuccess,
        error,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useNotificationListInfinite(
        {
            pageSize: 5,
        },
        {
            enabled: isMenuOpen,
            getNextPageParam: (lastPage) => (lastPage.page === lastPage.pageCount - 1 ? undefined : lastPage.page + 1),
            refetchInterval: refetchInterval ? refetchInterval + 5 : 0,
            onError: () => setRefetchInterval(0),
        }
    );
    const { mutate: updateNotification } = useNotificationUpdate();
    const notifications = notificationList?.pages?.map((page) => page.items).flat();
    const onClickNotificationItem = (notification: Notification) => {
        if (notification.status === NotificationStatus.received) {
            updateNotification({
                id: notification.id,
                status: NotificationStatus.opened,
            });
        }

        if (notification.payload.context?.penaltyId) {
            animationTimeout = window.setTimeout(() => {
                if (notification.payload.context?.penaltyId) {
                    history.replace({
                        pathname: getRoute(RoutePathName.penalties, {
                            penaltyId: notification.payload.context?.penaltyId,
                        }),
                        search: notification.payload.context?.commentId
                            ? new URLSearchParams({
                                  commentId: notification.payload.context?.commentId,
                              }).toString()
                            : undefined,
                    });
                }
            }, 210);
        } else if (notification.payload.context?.appointmentId) {
            history.push({
                pathname: getRoute(RoutePathName.appointments),
                search: `appointmentId=${notification.payload.context.appointmentId}`,
            });
        }

        setIsMenuOpen(false);
    };
    const onClickMarkAllAsRead = () => {
        setIsMarkingAllAsRead(true);
        updateNotification(
            {
                id: 'all',
                status: NotificationStatus.opened,
            },
            {
                onSuccess: () => setIsMenuOpen(false),
                onSettled: () => {
                    setIsMarkingAllAsRead(false);
                    setIsMenuOpen(false);
                },
            }
        );
    };

    const menu = (
        <div id="notifications-menu" className="pt-4 overflow-x-hidden overflow-y-auto max-h-460" ref={dropdownRef}>
            {isLoading && (
                <div key="loading" className="p-16 text-center">
                    <Spin />
                </div>
            )}
            {!isLoading && isError && (
                <div key="error" className="p-16">
                    <ApiResult status={error?.response?.status} inline />
                </div>
            )}
            {!isLoading && isSuccess && (
                <>
                    {!notifications?.length ? (
                        <div key="disabled" className="p-16 font-14 text-secondary">
                            Vous n&rsquo;avez pas de notifications
                        </div>
                    ) : (
                        <BasicList>
                            {notifications?.map((notification) => (
                                <li key={notification.id}>
                                    <Button
                                        onClick={onClickNotificationItem.bind(null, notification)}
                                        className="flex gap-12"
                                    >
                                        <div className="self-center">
                                            {getNotificationIcon(notification.payload.context)}
                                        </div>
                                        <div>
                                            <div className="mb-4">{notification.payload.text}</div>
                                            <time
                                                dateTime={notification.sendAt}
                                                title={formatDate(notification.sendAt, {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                                className="font-12 text-light-grey"
                                            >
                                                {formatRelativeTime(notification.sendAt)}
                                            </time>
                                        </div>
                                        <div className="notification-status">
                                            {notification.status === NotificationStatus.received && (
                                                <Badge color="#0066CC" dot />
                                            )}
                                        </div>
                                    </Button>
                                </li>
                            ))}
                            <li
                                key="load-more"
                                className={classNames(
                                    'text-center',
                                    (isFetchingNextPage || (hasNextPage && !isFetchingNextPage)) && 'p-16'
                                )}
                            >
                                {(isFetchingNextPage || (hasNextPage && !isFetchingNextPage)) && <Spin />}
                            </li>
                            <li className="mark-all-as-read-li" key="mark-all-as-read">
                                <Button
                                    onClick={onClickMarkAllAsRead}
                                    className="mark-all-as-read items-center justify-center font-medium text-blue"
                                >
                                    {isMarkingAllAsRead ? (
                                        <Spin className="text-16" />
                                    ) : (
                                        <CheckOutlined className="text-16" />
                                    )}
                                    <span className="ml-8">Tout marquer comme lu</span>
                                </Button>
                            </li>
                        </BasicList>
                    )}
                </>
            )}
            <div ref={loadMoreRef} style={{ height: 1, lineHeight: 0, marginTop: !isLoading && isSuccess ? -9 : 0 }}>
                &nbsp;
            </div>
        </div>
    );

    useEffect(() => {
        if (isAtBottom && hasNextPage) {
            fetchNextPage();
        }
    }, [isAtBottom, hasNextPage, fetchNextPage]);

    useEffect(
        () => () => {
            window.clearTimeout(animationTimeout);
        },
        []
    );

    return user?.hasVerifiedData ? (
        <Dropdown
            placement="topRight"
            trigger={['click']}
            overlay={menu}
            onOpenChange={(open) => {
                setIsMenuOpen(open);

                animationTimeout = window.setTimeout(() => {
                    dropdownRef?.current?.scrollTo({
                        top: 0,
                    });
                }, 210);
            }}
            open={isMenuOpen}
            overlayClassName="notification-dropdown"
            forceRender
        >
            <Button
                type="text"
                icon={
                    <Badge
                        count={notificationCountList?.receivedCount ?? 0}
                        offset={[0, 2]}
                        style={{ backgroundColor: '#F5222D', padding: '0 6px' }}
                    >
                        <BellOutlined className="font-24 text-blue" />
                    </Badge>
                }
            />
        </Dropdown>
    ) : null;
};

export default NotificationsDropdown;
