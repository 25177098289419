import { UploadChangeParam } from 'antd/lib/upload';
import { serialize } from 'object-to-formdata';

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import {
    Penalty,
    ListResponse,
    PenaltyStatus,
    Organization,
    PenaltyInvoiceStatus,
    Fee,
    CommentType,
    AppointmentStatus,
    User,
    ValueListItem,
    PenaltyContestationStatus,
    PenaltyUpdateAction,
} from './types';

// Controller Interface

export interface PenaltyUpdatePayload extends Partial<Omit<Penalty, 'id' | 'attachments' | 'fees'>> {
    id?: Penalty['id'];
    commentAttachments?: UploadChangeParam['fileList'];
    fees?: Array<Pick<Fee, 'id' | 'status'>>;
    comment?: {
        type: CommentType;
        content: string;
    };
    allCommentsReadUntil?: string;
    action?: PenaltyUpdateAction;
}

export type PenaltyIdPayload = Penalty['id'];

export type PenaltyListPayload = SearchPaginationQuery & {
    status?: PenaltyStatus[];
    contestationStatus?: PenaltyContestationStatus[];
    invoiceStatus?: PenaltyInvoiceStatus[];
    appointmentStatus?: AppointmentStatus[];
    type?: string[];
    organization?: Array<Organization['id']>;
    acceptedBy?: User['id'] | null;
    isAccepted?: boolean;
};
export type PenaltyListResponse = ListResponse<Penalty> | undefined;
export type PenaltyListTypesResponse = ListResponse<ValueListItem> | undefined;

// Routes
export const list = async (payload?: PenaltyListPayload) => {
    return await api.get<PenaltyListResponse>(`/penalties`, { params: payload }).then((response) => response?.data);
};

export const update = async ({ id, commentAttachments, ...payload }: PenaltyUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    const formData = serialize(payload, { indices: true });

    for (const attachment of commentAttachments ?? []) {
        const file = attachment.originFileObj;
        if (file) {
            formData.append('commentAttachments', file);
        }
    }

    return await api.put<Penalty>(`/penalties/${id}`, formData).then((response) => response?.data);
};

export const details = async (id?: PenaltyIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Penalty>(`/penalties/${id}`).then((response) => response?.data);
};

export const remove = async (id?: PenaltyIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/penalties/${id}`).then((response) => response?.data);
};

export const listTypes = async () => {
    return await api.get<PenaltyListTypesResponse>(`/penalties/types`).then((response) => {
        return response?.data;
    });
};
