import { FC, ReactNode, useEffect } from 'react';
import { Redirect, Link, useLocation, useHistory } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Spin } from 'antd';
import { useModal } from '@ebay/nice-modal-react';

import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import { defaultErrorMessage } from '../../i18n';
import AboutModal from '../../components/AboutModal';
import { hasRole } from '../../helpers/security';
import { RoleSlug } from '../../queries/api/types';
import { removeApiKey } from '../../queries/api/apiClient';

const Login: FC = () => {
    const history = useHistory();
    const { from } = useLocation<{ from?: Location }>().state ?? {};
    const aboutModal = useModal(AboutModal);
    const referrer = from && from.pathname !== getRoute(RoutePathName.login) ? from : undefined;
    const { user, isCheckingSession, login, hasChosenOrganization, checkSessionError } = useAuth();
    const { isLoading, mutate: sendLogin, isError, error } = login;
    const hasChosenOrganizationButHasntAcceptedTerms = hasChosenOrganization && user && !user.acceptedTerms;
    const hasChosenOrganizationAndAcceptedTerms = hasChosenOrganization && user && user?.acceptedTerms;

    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        sendLogin(values, {
            onSuccess: (data) => {
                if (data) {
                    if (data.organization && !data.acceptedTerms) {
                        aboutModal.show({ isLogin: true });
                    } else if (!data.organization && (data.hubs?.length ?? 0) > 1) {
                        history.push(getRoute(RoutePathName.chooseHub));
                    }
                }
            },
        });
    };

    let errorMessage: ReactNode = isError ? defaultErrorMessage : null;

    if (isError) {
        switch (error?.response?.status) {
            case 401:
                errorMessage = 'Adresse e-mail ou mot de passe incorrect';
                break;

            case 403:
                errorMessage = 'Non autorisé';
                break;

            case 404:
                errorMessage = "Nom d'utilisateur introuvable";
                break;

            default:
                errorMessage = defaultErrorMessage;
                break;
        }
    }

    useEffect(() => {
        // remove api key on load in case we somehow arrive on this page with an api key
        removeApiKey();
    }, []);

    useEffect(() => {
        if (hasChosenOrganizationButHasntAcceptedTerms && !hasRole(user, [RoleSlug.superAdmin])) {
            aboutModal.show({ isLogin: true });
        }
    }, [aboutModal, hasChosenOrganizationButHasntAcceptedTerms, user]);

    if ((!checkSessionError && hasChosenOrganizationAndAcceptedTerms) || hasRole(user, [RoleSlug.superAdmin])) {
        return <Redirect to={referrer ?? getRoute(RoutePathName.home)} />;
    }

    return (
        <LoginLayout title="Connectez-vous" seoTitle="Connexion">
            <Spin spinning={isCheckingSession}>
                <Form className="w-[430px]" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                    <Form.Item
                        name="username"
                        label="Adresse e-mail"
                        rules={[{ required: true, message: 'Veuillez renseigner votre e-mail' }]}
                    >
                        <Input placeholder="Saisissez votre e-mail" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Mot de passe"
                        rules={[{ required: true, message: 'Veuillez renseigner votre mot de passe' }]}
                    >
                        <Input.Password placeholder="Saisissez votre mot de passe" />
                    </Form.Item>

                    {errorMessage ? (
                        <Alert type="error" message={errorMessage} style={{ marginBottom: '1.5rem' }} />
                    ) : null}

                    <Form.Item style={{ margin: '2rem 0 2rem' }}>
                        <Button type="primary" size="large" block htmlType="submit" loading={isLoading}>
                            Me connecter
                        </Button>
                    </Form.Item>

                    <div className="text-center">
                        <Link className="login-link" to={getRoute(RoutePathName.forgottenPassword)}>
                            Mot de passe oublié ?
                        </Link>
                    </div>
                </Form>
            </Spin>
        </LoginLayout>
    );
};

export default Login;
