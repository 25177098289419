import { useEffect, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import useSessionStorage from './sessionStorage';
import { usePrevious } from '.';

/**
 * Get url query params via location.search, store them in session storage via a key for later reuse. Populate the current
 * params with stored params (session storage) by navigating to a route that uses the useQueryParams hook and by passing
 * the location state { restoreQueryParams: true }
 * @param {string} sessionStorageKey key used to save params in session storage
 * @returns {Array} [urlSearchParams, setParams]
 * - urlSearchParams is an URLSearchParams Map object (useurlSearchParams.get('paramName') to get a param in your component)
 * - setParams updates the current url with the given params as an object of key-value query params (eg: setParams({ key: value }))
 */
export default function useQueryParams(
    sessionStorageKey: string
): [URLSearchParams, (params: { [key: string]: any }) => void] {
    const history = useHistory();
    const location = useLocation<{ restoreQueryParams?: boolean }>();
    const previousLocation = usePrevious(location.pathname);
    const shouldRestoreQueryParams = location.state?.restoreQueryParams;
    const [storedParams, setStoredParams] = useSessionStorage(sessionStorageKey, {});
    const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const setParams = useCallback(
        (params: { [key: string]: any }) => {
            for (const name in params) {
                if (Object.prototype.hasOwnProperty.call(params, name)) {
                    if (params[name] === undefined) {
                        urlSearchParams.delete(name);
                    } else if (Array.isArray(params[name])) {
                        urlSearchParams.delete(name); // clear all values of this param
                        params[name].forEach((value: any) => {
                            urlSearchParams.append(name, value);
                        });
                    } else {
                        urlSearchParams.set(name, params[name]);
                    }
                }
            }

            history.push({
                pathname: location.pathname,
                search: urlSearchParams.toString(),
            });

            setStoredParams({
                ...storedParams,
                ...params,
            });
        },
        [history, location.pathname, setStoredParams, urlSearchParams, storedParams]
    );

    useEffect(() => {
        if (previousLocation !== location.pathname && shouldRestoreQueryParams) {
            setParams(storedParams);
        }
    }, [previousLocation, location.pathname, shouldRestoreQueryParams, setParams, storedParams]);

    return [urlSearchParams, setParams];
}
