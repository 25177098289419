import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Role, ListResponse, Organization } from './types';

// Controller Interface
export interface RoleCreatePayload extends Pick<Role, 'permissions' | 'scope' | 'removable'> {
    organization: Organization['id'];
    name: string;
}

export interface RoleUpdatePayload extends Omit<Role, 'id'> {
    id?: Role['id'];
}

export type RoleIdPayload = Role['id'];

export type RoleListPayload = SearchPaginationQuery;
export type RoleListResponse = ListResponse<Role>;

// Routes
export const list = async (payload?: RoleListPayload) => {
    return await api.get<RoleListResponse>(`/roles`, { params: payload }).then((response) => response?.data);
};

export const create = async (payload: RoleCreatePayload) => {
    return await api.post(`/roles`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: RoleUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Role>(`/roles/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: RoleIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Role>(`/roles/${id}`).then((response) => response?.data);
};

export const remove = async (id?: RoleIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/roles/${id}`).then((response) => response?.data);
};
