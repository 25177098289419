import { FC, useEffect } from 'react';
import { Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';
import { show } from '@ebay/nice-modal-react';

import { getRawRoute, getRoute, RoutePathName } from '../routes';
import { useAuth } from '../context/AuthContext';
import { hasPermission, hasRole } from '../helpers/security';
import { Permission, RoleSlug } from '../queries/api/types';
import AboutModal from './AboutModal';
import { removeApiKey } from '../queries/api/apiClient';

const ProtectedRoute: FC = ({ children }) => {
    const location = useLocation();
    const { isCheckingSession, checkSessionError, logout, user, hasChosenOrganization } = useAuth();
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const isChooseHubPage = useRouteMatch(getRawRoute(RoutePathName.chooseHub));
    const noOrganizationChosen = user && !hasChosenOrganization;
    const hasChosenOrganizationButHasntAcceptedTerms = hasChosenOrganization && user && !user?.acceptedTerms;
    const hasChosenOrganizationAndAcceptedTerms = hasChosenOrganization && user?.acceptedTerms;

    useEffect(() => {
        if (hasChosenOrganizationButHasntAcceptedTerms) {
            show(AboutModal, { isLogin: true });
        }
    }, [hasChosenOrganizationButHasntAcceptedTerms]);

    useEffect(() => {
        const listener = () => {
            if (!checkSessionError) {
                logout.mutate();
            }
        };
        // dispatched from axios interceptor in api/apiClient.ts
        window.addEventListener('unauthorized.error', listener);

        return () => window.removeEventListener('unauthorized.error', listener);
    }, [checkSessionError]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isCheckingSession || hasChosenOrganizationButHasntAcceptedTerms) {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin />
                </div>
            </Route>
        );
    } else if (checkSessionError || !user) {
        return (
            <Redirect
                to={{
                    pathname: getRawRoute(RoutePathName.login),
                    state: { from: location },
                }}
            />
        );
    } else if (process.env.NODE_ENV === 'production' && hasPermission(user, Permission.backOffice)) {
        removeApiKey();
        window.location.replace('/admin');
        return null;
    } else if (noOrganizationChosen && !isChooseHubPage) {
        return <Redirect to={getRoute(RoutePathName.chooseHub)} />;
    } else if (
        hasChosenOrganizationAndAcceptedTerms &&
        !isValidateAccountPage &&
        !user?.hasVerifiedData &&
        !hasRole(user, [RoleSlug.superAdmin])
    ) {
        return <Redirect to={getRoute(RoutePathName.validateAccount)} />;
    } else {
        return <>{children}</>;
    }
};

export default ProtectedRoute;
