import { FC } from 'react';
import { Button, Col, Divider, Form, Input, Row, Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { DeleteOutlined } from '@ant-design/icons';

import CountrySelect from '../CountrySelect';
import { requiredRule } from '../../helpers';
import LabelWithTooltipIcon from '../LabelWithTooltipIcon';

interface CompanyFieldsProps {
    title?: string;
    titleLevel?: TitleProps['level'];
    inDrawer?: boolean;
}

const CompanyFields: FC<CompanyFieldsProps> = ({ title, titleLevel = 2, inDrawer }) => {
    return (
        <>
            {title && <Typography.Title level={titleLevel}>{title}</Typography.Title>}

            <Form.Item name={['user', 'organization', 'name']} label="Nom commercial" rules={[requiredRule]}>
                <Input placeholder="Saisir le nom de la société" />
            </Form.Item>

            <Form.Item name={['user', 'organization', 'siret']} label="Numéro SIRET" rules={[requiredRule]}>
                <Input placeholder="Saisir un SIRET" />
            </Form.Item>

            <Form.Item
                name={['user', 'organization', 'address', 'street']}
                label="Adresse de facturation"
                rules={[requiredRule]}
            >
                <Input placeholder="Saisir une adresse de facturation" />
            </Form.Item>

            <Row gutter={24}>
                <Col xs={inDrawer ? 8 : 6}>
                    <Form.Item
                        name={['user', 'organization', 'address', 'zipCode']}
                        label="Code postal"
                        rules={[requiredRule]}
                    >
                        <Input placeholder="Code postal" />
                    </Form.Item>
                </Col>
                <Col xs={inDrawer ? 16 : 18}>
                    <Form.Item name={['user', 'organization', 'address', 'city']} label="Ville" rules={[requiredRule]}>
                        <Input placeholder="Saisir une ville" />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name={['user', 'organization', 'address', 'country']} label="Pays" rules={[requiredRule]}>
                <CountrySelect placeholder="Sélectionner un pays" />
            </Form.Item>

            <Divider className="divider-small" />

            <Form.Item
                label={
                    <span className="font-medium font-16 text-blue">
                        <LabelWithTooltipIcon
                            label="Contacts comptabilité"
                            tooltip="Les pièces comptables seront automatiquement envoyées à ces adresses e-mail"
                        />
                    </span>
                }
            >
                <Form.List name={['user', 'organization', 'accountingEmails']}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key} className="flex gap-8">
                                    <Form.Item
                                        label={`Contact n°${index + 1}`}
                                        name={field.name}
                                        className="flex-1"
                                        rules={[
                                            requiredRule,
                                            { type: 'email', message: 'Veuillez saisir un e-mail valide' },
                                        ]}
                                    >
                                        <Input placeholder="Saisir une adresse e-mail" />
                                    </Form.Item>
                                    {index > 0 && (
                                        <Button
                                            className="mt-40"
                                            type="text"
                                            icon={<DeleteOutlined />}
                                            size="small"
                                            onClick={remove.bind(null, index)}
                                        />
                                    )}
                                </div>
                            ))}

                            <Button type="primary" size="small" onClick={() => add()} ghost>
                                Ajouter un contact supplémentaire
                            </Button>
                        </>
                    )}
                </Form.List>
            </Form.Item>

            <Divider className="divider-small" />
        </>
    );
};

export default CompanyFields;
