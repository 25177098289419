import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Collapse, Divider, Form, FormProps, Typography } from 'antd';
import { useModal } from '@ebay/nice-modal-react';

import Seo from '../../components/Seo';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import { defaultErrorMessage, requiredFieldsText } from '../../i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import UserAccountFields from '../../components/forms/UserAccountFields';
import CompanyFields from '../../components/forms/CompanyFields';
import ProvidersField from '../../components/forms/ProvidersField';
import CompanyTermsUploadField from '../../components/forms/CompanyTermsUploadField';
import AdminFirstLoginModal from '../../components/AdminFirstLoginModal';
import { hasRole } from '../../helpers/security';
import { RoleSlug } from '../../queries/api/types';
import { useOrganizationUpdate } from '../../queries/organizations';
import { tutorialModalId } from '../../App';

const ValidateAccount: FC = () => {
    const history = useHistory();
    const { user, updateUser } = useAuth();
    const canValidateOrganization =
        user?.organization?.id &&
        user.scope?.organizations?.some((organization) => organization.id === user?.organization?.id);
    const [isOrgFormOpen, setIsOrgFormOpen] = useState(true);
    const { isLoading: isUpdatingUser, mutateAsync: updateMe } = updateUser;
    const { mutateAsync: updateOrganization, isLoading: isUpdatingOrganization } = useOrganizationUpdate();
    const adminFirstLoginModal = useModal(AdminFirstLoginModal);
    const tutorialModal = useModal(tutorialModalId);
    const [form] = Form.useForm();
    const hasVerifiedOrganizationData = user?.organization?.hasVerifiedData;
    const isFirstAdmin = hasRole(user, [RoleSlug.providerAdmin]) && !hasVerifiedOrganizationData;
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const validateUser = {
            hasVerifiedData: true,
            ...values.user,
            organization: undefined,
        };
        const validateOrganization = {
            id: user?.organization?.id,
            ...values.user.organization,
            hasVerifiedData: true,
        };
        Promise.allSettled([
            updateMe(validateUser),
            ...(canValidateOrganization && isOrgFormOpen ? [updateOrganization(validateOrganization)] : []),
        ]).then((results) => {
            if (results.every((result) => result.status === 'fulfilled')) {
                successMessage({ content: 'Vos informations ont été validées avec succès' });

                if (isFirstAdmin) {
                    adminFirstLoginModal.show({ hasSeenTutorial: user?.hasSeenTutorial });
                } else if (!user?.hasSeenTutorial) {
                    tutorialModal.show();
                }

                history.push(getRoute(RoutePathName.home));
            } else {
                errorMessage({ content: defaultErrorMessage });
            }
        });
    };

    return (
        <>
            <Seo title="Validez les informations du compte" />
            <div className="text-center pt-32">
                <Typography.Title className="mb-16" style={{ fontSize: '1.75rem' }}>
                    Validez les informations du compte
                </Typography.Title>
                <Typography.Paragraph className="mb-32">
                    Relisez et complétez / modifiez si nécessaire les informations ci-dessous :
                </Typography.Paragraph>
            </div>
            <Card>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFormValidSubmit}
                    scrollToFirstError
                    preserve={false}
                    initialValues={{
                        user: {
                            ...user,
                            organization: {
                                ...user?.organization,
                                accountingEmails: user?.organization?.accountingEmails || [''],
                            },
                        },
                    }}
                >
                    <UserAccountFields title="Vos informations" isMe />
                    {!hasVerifiedOrganizationData && canValidateOrganization && (
                        <>
                            <Divider />
                            <Collapse
                                activeKey={isOrgFormOpen ? 'org' : undefined}
                                className="mb-24"
                                destroyInactivePanel
                            >
                                <Collapse.Panel
                                    key="org"
                                    showArrow={false}
                                    header={
                                        <div className="flex items-center justify-between cursor-default">
                                            <span>Informations de la société</span>
                                            <Button size="small" onClick={() => setIsOrgFormOpen((val) => !val)}>
                                                {isOrgFormOpen ? 'Plus tard' : 'Saisir maintenant'}
                                            </Button>
                                        </div>
                                    }
                                >
                                    <CompanyFields />
                                    <CompanyTermsUploadField className="mb-0" />
                                </Collapse.Panel>
                            </Collapse>
                        </>
                    )}
                    <Divider />
                    <ProvidersField readOnly />
                    <Form.Item className="mb-32">
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="text-uppercase"
                            size="large"
                            loading={isUpdatingUser || isUpdatingOrganization}
                            block
                        >
                            Valider les informations ci-dessus
                        </Button>
                    </Form.Item>
                    <Typography.Paragraph className="text-center mb-0">
                        <Typography.Text className="font-12">{requiredFieldsText}</Typography.Text>
                    </Typography.Paragraph>
                </Form>
            </Card>
        </>
    );
};

export default ValidateAccount;
