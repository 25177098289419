import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { details, list, SettingSlugPayload, SettingListResponse, SettingUpdatePayload, update } from './api/settings';
import { Setting } from './api/types';

export const settingsKeys = {
    all: ['settings'],
    lists: () => [...settingsKeys.all, 'list'],
    details: () => [...settingsKeys.all, 'details'],
    detail: (slug?: SettingSlugPayload) => [...settingsKeys.details(), slug],
};

export const useSettingList = <TData = SettingListResponse>(
    options?: UseQueryOptions<SettingListResponse, AxiosError, TData>
) => {
    return useQuery<SettingListResponse, AxiosError, TData>(settingsKeys.lists(), async () => await list(), {
        keepPreviousData: true,
        ...options,
    });
};

export const useSettingDetails = <TData = Setting>(
    slug?: SettingSlugPayload,
    options?: UseQueryOptions<Setting, AxiosError, TData>
) => {
    return useQuery<Setting, AxiosError, TData>(settingsKeys.detail(slug), async () => await details(slug), options);
};

export const useSettingUpdate = (options?: UseMutationOptions<Setting, AxiosError, SettingUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Setting, AxiosError, SettingUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            queryClient.invalidateQueries(settingsKeys.lists());

            // invalidate detail query to refetch with the newly added item
            if (typeof variables.slug === 'string') {
                queryClient.invalidateQueries(settingsKeys.detail(variables.slug));
            }
        },
    });
};
