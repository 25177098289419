import api from './apiClient';
import { Setting } from './types';

// Controller Interface

interface UpdateSinglePayload extends Omit<Setting, 'slug'> {
    slug?: Setting['slug'];
}
interface UpdateBatchPayload {
    [slug: string]: Setting;
}

export type SettingUpdatePayload = UpdateSinglePayload | UpdateBatchPayload;

export type SettingSlugPayload = Setting['slug'];

export type SettingListResponse = Setting[];

// Routes
export const list = async () => {
    return await api.get<SettingListResponse>(`/settings`).then((response) => response?.data);
};

export const update = async ({ slug, ...payload }: SettingUpdatePayload) => {
    if (!payload && !slug) {
        throw new Error('missing slug or payload');
    }
    return await api.put<Setting>(`/settings/${(slug as string) ?? ''}`, payload).then((response) => response?.data);
};

export const details = async (slug?: SettingSlugPayload) => {
    if (!slug) {
        throw new Error('missing slug');
    }
    return await api.get<Setting>(`/settings/${slug}`).then((response) => response?.data);
};
