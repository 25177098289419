import { UploadFile } from 'antd/lib/upload/interface';
import { serialize } from 'object-to-formdata';

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Appointment, AppointmentReport, AppointmentStatus, ListResponse, Organization, RemoteFile } from './types';

// Controller Interface
export type AppointmentCreatePayload = Record<string, any>;

export type AppointmentUpdatePayload = Partial<Omit<Appointment, 'notes' | 'reports'>> & {
    notes?: string;
    comment?: string;
    report?: Partial<Pick<AppointmentReport, 'providerContent' | 'status'>> & {
        providerAttachments?: UploadFile[];
    };
};

export type AppointmentIdPayload = Appointment['id'];

export type AppointmentListPayload = SearchPaginationQuery & {
    status?: AppointmentStatus | AppointmentStatus[];
    organization?: Organization['id'] | null;
    createdByAdmin?: boolean;
};
export type AppointmentListResponse = ListResponse<Appointment>;

export interface AppointmentRemoveAttachmentPayload {
    id?: Appointment['id'];
    type: 'attachments' | 'reportFile' | 'noteAttachments' | 'providerAttachments';
    fileId?: RemoteFile['id'];
}

// Routes
export const list = async (payload?: AppointmentListPayload) => {
    return await api
        .get<AppointmentListResponse>(`/appointments`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: AppointmentCreatePayload) => {
    return await api.post(`/appointments`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: AppointmentUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    const { report, ...restPayload } = payload;
    const { providerAttachments, ...restReport } = report || {};

    const formData = serialize({ ...restPayload, report: restReport }, { indices: true, allowEmptyArrays: true });

    for (const attachment of providerAttachments ?? []) {
        const file = attachment.originFileObj;

        if (file) {
            formData.append('providerAttachments', file);
        }
    }

    return await api.put<Appointment>(`/appointments/${id}`, formData).then((response) => response?.data);
};

export const details = async (id?: AppointmentIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Appointment>(`/appointments/${id}`).then((response) => response?.data);
};

export const remove = async (id?: AppointmentIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/appointments/${id}`).then((response) => response?.data);
};

export const removeAttachment = async ({ id, type, fileId }: AppointmentRemoveAttachmentPayload) => {
    if (!id || !type || !fileId) {
        throw new Error('missing id, type or fileId');
    }
    return await api.delete<undefined>(`/appointments/${id}/${type}/${fileId}`).then((response) => response?.data);
};
