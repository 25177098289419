import { Typography } from 'antd';
import { useMemo, VFC } from 'react';

import { getPenaltyTotalContestationAcceptedAmount, getPenaltyTotalKeptAmount } from '../../helpers';
import { formatPrice } from '../../i18n';
import { Penalty } from '../../queries/api/types';
import { usePenaltyDetails } from '../../queries/penalties';

interface PenaltyFinalAmountsProps {
    penaltyId?: Penalty['id'];
    queryEnabled: boolean;
}

const PenaltyFinalAmounts: VFC<PenaltyFinalAmountsProps> = ({ penaltyId, queryEnabled }) => {
    const { data: penalty } = usePenaltyDetails(penaltyId, {
        enabled: queryEnabled,
    });
    const useInvoice = !penalty?.invoice?.outdated && penalty?.invoice?.amount !== undefined;
    const keptAmount = useMemo(
        () => (useInvoice ? penalty?.invoice?.amount?.kept : getPenaltyTotalKeptAmount(penalty)),
        [useInvoice, penalty]
    );
    const reimbursedAmount = useMemo(
        () => (useInvoice ? penalty?.invoice?.amount?.reimbursed : getPenaltyTotalContestationAcceptedAmount(penalty)),
        [useInvoice, penalty]
    );

    return (
        <>
            <Typography.Paragraph className="font-bold font-20 leading-7 text-white">
                Montants définitifs
            </Typography.Paragraph>
            <div className="rounded border-solid border-4091E1 border-2 flex">
                <div className="py-12 px-16 flex flex-1 items-center justify-center gap-24">
                    <Typography.Text className="font-medium font-16 leading-7 text-white">
                        Montant maintenu :
                    </Typography.Text>
                    <Typography.Text className="font-bold font-20 leading-7 text-white" strong>
                        {formatPrice(keptAmount)}
                    </Typography.Text>
                </div>
                <div className="py-12 px-16 border-l-solid border-l-2 border-4091E1 flex flex-1 items-center justify-center gap-24">
                    <Typography.Text className="font-medium font-16 leading-7 text-white">
                        Montant {useInvoice ? 'remboursé' : 'annulé'} :
                    </Typography.Text>
                    <Typography.Text className="font-bold font-20 leading-7 text-white" strong>
                        {formatPrice(reimbursedAmount)}
                    </Typography.Text>
                </div>
            </div>
        </>
    );
};

export default PenaltyFinalAmounts;
