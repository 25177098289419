import { Button, DrawerProps, Form, FormProps, Input, Modal, Typography, Upload, UploadProps } from 'antd';
import { antdDrawer, useModal, create } from '@ebay/nice-modal-react';
import { ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import { defaultErrorMessage, requiredFieldsText } from '../../i18n';
import { errorMessage, successMessage } from '../../helpers/message';
import CustomDrawer from '../CustomDrawer';
import { usePenaltyUpdate } from '../../queries/penalties';
import { CommentType, Fee, FeeStatus, Penalty } from '../../queries/api/types';
import { isMSGFile, requiredRule } from '../../helpers';

export type PenaltyContestFormDrawerProps = DrawerProps & {
    penalty?: Penalty;
    selectedFeeIds?: Array<Fee['id']>;
} & Record<string, any>;

const PenaltyContestFormDrawer = create<PenaltyContestFormDrawerProps>(({ penalty, selectedFeeIds, ...props }) => {
    const [canClose, setCanClose] = useState(false);
    const modal = useModal();
    const [form] = Form.useForm();
    const onCloseDrawer = () => {
        if (canClose) {
            modal.hide();
        } else {
            Modal.confirm({
                width: 500,
                centered: true,
                title: 'Annuler la contestation',
                icon: <ExclamationCircleOutlined className="text-red" />,
                content:
                    'Êtes-vous sûr de vouloir annuler la contestation de l’ensemble des lignes de cet avis de pénalité ?',
                okText: 'Confirmer',
                okType: 'primary',
                cancelText: 'Annuler',
                cancelButtonProps: {
                    ghost: true,
                    type: 'primary',
                },
                onOk: () => {
                    setCanClose(true);
                    modal.reject();
                },
            });
        }
    };
    const { mutate: updatePenalty, isLoading } = usePenaltyUpdate({
        onError: (error) => {
            errorMessage({
                content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
            });
        },
        onSuccess: () => {
            successMessage({ content: 'Avis de pénalité contesté avec succès' });
            setCanClose(true);
            modal.resolve();
        },
    });
    const normFile = (e: any) => (Array.isArray(e) ? e : e?.fileList);
    const onBeforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (isMSGFile(file)) {
            errorMessage({ content: 'Les fichiers de type .msg ne sont pas acceptés' });
            return Upload.LIST_IGNORE;
        }

        if (file.size / 1024 > 20000) {
            errorMessage({
                content: 'Le fichier dépasse la limite de taille maximum de 20Mo',
            });
            return Upload.LIST_IGNORE;
        }

        return false;
    };
    const onFormValidSubmit: FormProps['onFinish'] = (values) => {
        const contestedFees = selectedFeeIds?.map((feeId) => ({
            id: feeId,
            status: FeeStatus.contested,
        }));

        const negativeFees = penalty?.fees
            ?.filter((fee) => fee.amount < 0)
            .map((fee) => ({
                id: fee.id,
                status: FeeStatus.contested,
            }));

        const acceptedFees = penalty?.fees
            ?.filter(
                (fee) =>
                    contestedFees?.every((contestedFee) => contestedFee.id !== fee.id) &&
                    negativeFees?.every((negativeFee) => negativeFee.id !== fee.id)
            )
            .map((fee) => ({
                id: fee.id,
                status: FeeStatus.accepted,
            }));
        const feesResult = [...(contestedFees ?? []), ...(acceptedFees ?? []), ...(negativeFees ?? [])];

        updatePenalty({
            id: penalty?.id,
            ...values,
            comment: {
                ...values.comment,
                type: CommentType.contestation,
            },
            fees: feesResult?.length ? feesResult : undefined,
        });
    };

    useEffect(() => {
        if (canClose) {
            modal.hide();
        }
    }, [canClose, modal]);

    return (
        <CustomDrawer
            width={500}
            title="Contestation - Saisie du justificatif"
            {...props}
            {...antdDrawer(modal)}
            onClose={onCloseDrawer}
        >
            <Typography.Paragraph>
                Pour valider votre contestation vous devez justifier votre demande ci-dessous :
            </Typography.Paragraph>
            <Form form={form} onFinish={onFormValidSubmit} layout="vertical" scrollToFirstError>
                <Form.Item
                    name={['comment', 'content']}
                    label="Message de justification/Bienfondé de la contestation"
                    rules={[requiredRule]}
                >
                    <Input.TextArea placeholder="Saisir un message" rows={5} />
                </Form.Item>
                <Form.Item
                    name={['commentAttachments']}
                    label="Pièce jointe/Analyse documentée de la contestation"
                    rules={[requiredRule]}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload.Dragger beforeUpload={onBeforeUpload}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                            Cliquez ou faites glisser ici votre pièce jointe pour l&rsquo;envoyer
                        </p>
                        <p className="ant-upload-hint">(Max 20 Mo)</p>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item className="mb-32 mt-32" shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={isLoading}
                            disabled={
                                !form.isFieldsTouched(true) ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                            }
                            block
                        >
                            Confirmer la demande de contestation
                        </Button>
                    )}
                </Form.Item>
                <Typography.Paragraph className="text-center mb-0">
                    <Typography.Text className="font-12">{requiredFieldsText}</Typography.Text>
                </Typography.Paragraph>
            </Form>
        </CustomDrawer>
    );
});

export default PenaltyContestFormDrawer;
