import { useEffect, useRef, useState, VFC } from 'react';
import dayjs from 'dayjs';
import { Button, Card, Tag, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Appointment, AppointmentReportStatus, AppointmentStatus, User } from '../../queries/api/types';
import { classNames, getFullName } from '../../helpers';
import '../../assets/styles/Appointments.less';
import AppointmentStatusTag from './AppointmentStatusTag';
import { Bin } from '../icons';
import { useAppointmentUpdate } from '../../queries/appointments';
import AppointmentModalFormCancelled from './appointmentModal/AppointmentModalFormCancelled';
import { errorMessage, successMessage } from '../../helpers/message';
import AppointmentFollowUpFormDrawer from '../drawers/AppointmentFollowUpFormDrawer';
import AppointmentModalFormRefused from './appointmentModal/AppointmentModalFormRefused';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import useQueryParams from '../../hooks/queryParams';
import { useAuth } from '../../context/AuthContext';

const getCommentAuthorName = (user: User | undefined, refusedBy: User | undefined) => {
    if (refusedBy && user?.id === refusedBy.id) {
        return 'Votre commentaire';
    } else {
        return `Commentaire de ${getFullName(user)}`;
    }
};

interface AppointmentCardProps {
    appointment: Appointment;
}

const AppointmentCard: VFC<AppointmentCardProps> = ({ appointment }) => {
    const { user } = useAuth();
    const appointmentFollowUpFormDrawer = useModal(AppointmentFollowUpFormDrawer);
    const isAppointmentArchived = [
        AppointmentStatus.canceled,
        AppointmentStatus.refused,
        AppointmentStatus.signed,
    ].includes(appointment.status);
    const isAppointmentPending = AppointmentStatus.pending === appointment.status;
    const [queryParams, setQueryParams] = useQueryParams('appointment');
    const appointementSelect = queryParams.get('appointmentId');
    const isAppointmentSelected = appointementSelect === appointment.id;
    const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
    const [isAccepting, setIsAccepting] = useState(false);
    const [isRefusing, setIsRefusing] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    const isVisible = useIntersectionObserver(ref, { threshold: 1, freezeOnceVisible: true });

    const { mutate: updateAppointment, isLoading: isUpdating } = useAppointmentUpdate();

    const handleClickValidated = (id: string) => {
        setIsAccepting(true);
        updateAppointment(
            {
                id,
                status: AppointmentStatus.accepted,
            },
            {
                onSuccess: () => {
                    successMessage({ content: 'Demande de rendez-vous acceptée avec succès' });
                },
                onError: (error) => {
                    errorMessage({
                        content: `Une erreur est survenue pendant la validation du rendez-vous (${
                            error?.response?.status ?? 0
                        })`,
                    });
                },
                onSettled: () => {
                    setIsAccepting(false);
                },
            }
        );
    };

    const handleClickRefused = async (id: string) => {
        const response = await NiceModal.show(AppointmentModalFormRefused);
        const data = response as Pick<Appointment, 'comment'>;
        setIsRefusing(true);
        updateAppointment(
            {
                id,
                status: AppointmentStatus.refused,
                comment: data.comment,
            },
            {
                onSuccess: () => {
                    successMessage({ content: 'Demande de rendez-vous refusée avec succès' });
                },
                onError: (error) => {
                    errorMessage({
                        content: `Une erreur est survenue pendant le refus du rendez-vous (${
                            error?.response?.status ?? 0
                        })`,
                    });
                },
                onSettled: () => {
                    setIsRefusing(false);
                },
            }
        );
    };

    const handleClickCancelled = async (id: string) => {
        await NiceModal.show(AppointmentModalFormCancelled);
        updateAppointment(
            {
                id,
                status: AppointmentStatus.canceled,
            },
            {
                onSuccess: () => {
                    successMessage({ content: 'Demande de rendez-vous annulée avec succès' });
                },
                onError: (error) => {
                    errorMessage({
                        content: `Une erreur est survenue pendant l'annulation du rendez-vous (${
                            error?.response?.status ?? 0
                        })`,
                    });
                },
            }
        );
    };

    const handleClickSeeReport = (appointment: Appointment) => {
        appointmentFollowUpFormDrawer.show({
            appointmentId: appointment.id,
        });
    };

    useEffect(() => {
        if (isVisible) {
            if (isAppointmentSelected) {
                setHasScrolledIntoView(true);
                setQueryParams({ appointmentId: undefined });
            }
            setHasScrolledIntoView(false);
        }
    }, [isVisible, isAppointmentSelected, setQueryParams]);

    useEffect(() => {
        if (isAppointmentSelected && !hasScrolledIntoView) {
            window.setTimeout(() => {
                ref.current?.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
            }, 50);
        }
    }, [isAppointmentSelected, hasScrolledIntoView]);

    return (
        <Card className={classNames('mb-8 ', isAppointmentArchived && 'appointment-archive-card')}>
            <div
                className={classNames(
                    'flex items-center ',
                    (isAppointmentArchived || (!appointment.createdByAdmin && isAppointmentPending)) &&
                        'justify-between'
                )}
                ref={ref}
            >
                <div>
                    <Tag color="blue">
                        <Typography.Text style={{ color: 'inherit' }} ellipsis>
                            {appointment.organization?.name ?? 'Inconnu'}
                        </Typography.Text>
                    </Tag>
                    <div className="appointment-card-date font-black font-20 mt-16">
                        {dayjs(appointment.fromDate).format('dddd DD MMMM')}
                    </div>
                    <div className="font-medium font-14 text-grey">
                        {dayjs(appointment.fromDate).format('HH[h]mm')} - {dayjs(appointment.toDate).format('HH[h]mm')}
                    </div>
                </div>
                <div className="appointment-card-status">
                    <AppointmentStatusTag status={appointment?.status} reportStatus={appointment.reportStatus} />
                </div>
                {isAppointmentPending && appointment.createdByAdmin && (
                    <div className="appointments-card-buttons flex flex-col">
                        <Button
                            className="appointments-card-button"
                            type="primary"
                            onClick={() => handleClickValidated(appointment.id)}
                            loading={isAccepting}
                        >
                            Accepter
                        </Button>
                        <Button
                            danger
                            onClick={async () => await handleClickRefused(appointment.id)}
                            loading={isRefusing}
                        >
                            Refuser
                        </Button>
                    </div>
                )}

                {appointment.status === AppointmentStatus.accepted && (
                    <Button
                        shape="circle"
                        type="text"
                        icon={<Bin className="text-blue" />}
                        onClick={async () => await handleClickCancelled(appointment.id)}
                        loading={isUpdating}
                    />
                )}
                {appointment.status === AppointmentStatus.notSigned &&
                    appointment.reportStatus &&
                    [
                        AppointmentReportStatus.pending,
                        AppointmentReportStatus.inProgress,
                        AppointmentReportStatus.refused,
                    ].includes(appointment.reportStatus) && (
                        <Button
                            className="appointments-card-button"
                            type="primary"
                            onClick={() => handleClickSeeReport(appointment)}
                        >
                            {`Saisir ${
                                appointment.reportStatus === AppointmentReportStatus.refused ? 'nouveau' : 'le'
                            } CR`}
                        </Button>
                    )}

                {[AppointmentStatus.inProgress, AppointmentStatus.signed].includes(appointment.status) && (
                    <Button
                        shape="circle"
                        type="text"
                        icon={<EyeOutlined className="text-blue" />}
                        onClick={() => handleClickSeeReport(appointment)}
                    />
                )}
                {appointment.status === AppointmentStatus.pending && !appointment.createdByAdmin && (
                    <Button
                        shape="circle"
                        type="text"
                        icon={<Bin className="text-blue" />}
                        onClick={async () => await handleClickCancelled(appointment.id)}
                        loading={isUpdating}
                    />
                )}
            </div>
            {isAppointmentArchived && appointment.comment && (
                <div className="appointment-card-comment">
                    <div className="text-blue font-14 font-medium">
                        {getCommentAuthorName(user, appointment.refusedBy)}
                    </div>
                    <div className="font-14">{appointment.comment}</div>
                </div>
            )}
        </Card>
    );
};

export default AppointmentCard;
