import { FC, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormProps } from 'antd/lib/form/Form';
import { Form, Input, Button, Alert, Typography } from 'antd';

import LoginLayout from '../../components/LoginLayout';
import { getRoute, RoutePathName } from '../../routes';
import { useAuth } from '../../context/AuthContext';
import { ForgottenPasswordPayload } from '../../queries/api/auth';
import { requiredRule } from '../../helpers';

const ForgottenPassword: FC = () => {
    const { forgottenPassword, user, checkSessionError } = useAuth();
    const { mutate: sendForgottenPassword, error, isSuccess, isLoading } = forgottenPassword;
    const [email, setEmail] = useState<string | undefined>();
    const onFormValidSubmit: FormProps['onFinish'] = (values: ForgottenPasswordPayload) => {
        setEmail(values.username);
        sendForgottenPassword(values);
    };

    let errorMessage = error ? 'Nous sommes désolés, une erreur est survenue. Veuillez réessayer plus tard.' : null;

    if (error?.response?.status === 404) {
        errorMessage = 'Adresse e-mail non trouvée.';
    }

    useEffect(
        () => () => {
            setEmail(undefined);
        },
        [setEmail]
    );

    if (user && !checkSessionError) {
        return <Redirect to={getRoute(user.acceptedTerms ? RoutePathName.home : RoutePathName.login)} />;
    }

    return (
        <LoginLayout title="Mot de passe oublié ?" seoTitle="Mot de passe oublié">
            <Form className="w-[430px]" onFinish={onFormValidSubmit} layout="vertical" requiredMark={false}>
                {isSuccess ? (
                    <Typography.Paragraph className="text-center">
                        <p>
                            Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe à l’adresse{' '}
                            {email}.<br />
                            <br />
                            Merci de surveiller vos e-mails.
                        </p>
                    </Typography.Paragraph>
                ) : (
                    <>
                        <Typography.Paragraph className="text-center">
                            <p>
                                Saisissez l’adresse e-mail associée à votre compte, nous vous enverrons un mail avec un
                                lien pour réinitialiser votre mot de passe.
                            </p>
                        </Typography.Paragraph>
                        <Form.Item
                            name="username"
                            label="Adresse e-mail"
                            rules={[
                                requiredRule,
                                {
                                    type: 'email',
                                    message: 'Adresse e-mail non valide',
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input placeholder="Saisissez votre e-mail" />
                        </Form.Item>
                        {errorMessage && <Alert type="error" message={errorMessage} className="mb-24" />}
                        <Form.Item>
                            <Button type="primary" size="large" htmlType="submit" loading={isLoading} block>
                                Réinitialiser le mot de passe
                            </Button>
                        </Form.Item>
                    </>
                )}
                <div className="text-center">
                    <Link className="login-link" to={getRoute(RoutePathName.login)}>
                        Revenir à l’écran de connexion
                    </Link>
                </div>
            </Form>
        </LoginLayout>
    );
};

export default ForgottenPassword;
