import { Divider, Typography, Upload } from 'antd';
import { VFC, HTMLAttributes, memo, useRef, useEffect, useState, useMemo } from 'react';

import '../assets/styles/Comment.less';

import { useAuth, useCurrentHubName } from '../context/AuthContext';
import { classNames, getFullName } from '../helpers';
import useQueryParams from '../hooks/queryParams';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import { formatDate } from '../i18n';
import { Comment as CommentType, Penalty } from '../queries/api/types';
import { usePenaltyUpdate } from '../queries/penalties';

let timeout: number;

interface CommentProps extends HTMLAttributes<HTMLDivElement> {
    data: CommentType;
    penaltyId?: Penalty['id'];
}

const Comment: VFC<CommentProps> = memo(
    ({ data, penaltyId, ...props }) => {
        const hubName = useCurrentHubName();
        const [queryParams] = useQueryParams('penaltyDetailsDrawer');
        const commentId = queryParams.get('commentId') ?? undefined;
        const shouldScrollIntoView = commentId === data.id;
        const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
        const { user } = useAuth();
        const ref = useRef<HTMLDivElement | null>(null);
        const isVisible = useIntersectionObserver(ref, { threshold: 1, freezeOnceVisible: true });
        const { mutate: updatePenalty, isLoading: isUpdating } = usePenaltyUpdate();
        const username = useMemo(() => {
            const name = user?.id === data.user.id ? 'Vous' : getFullName(data.user);
            const org = user?.id === data.user.id ? '' : `pour la ${hubName}`;

            return [name, org].filter(Boolean).join(' ');
        }, [user, data, hubName]);

        useEffect(() => {
            if (isVisible) {
                if (data && data.readByUser === undefined) {
                    timeout = window.setTimeout(() => {
                        updatePenalty({
                            id: penaltyId,
                            allCommentsReadUntil: data.createdAt,
                        });
                    }, 2000);
                }

                if (shouldScrollIntoView) {
                    setHasScrolledIntoView(true);
                }
            }
        }, [isVisible, data, penaltyId, updatePenalty, shouldScrollIntoView]);

        useEffect(() => {
            if (shouldScrollIntoView && !hasScrolledIntoView) {
                window.setTimeout(() => {
                    ref.current?.scrollIntoView({ block: 'center', inline: 'nearest', behavior: 'smooth' });
                }, 50);
            }
        }, [shouldScrollIntoView, hasScrolledIntoView]);

        useEffect(
            () => () => {
                window.clearTimeout(timeout);
            },
            []
        );

        return (
            <div
                {...props}
                className={classNames(
                    'comment rounded-xl bg-blue-grey p-16',
                    !data.readByUser && 'comment-unread',
                    !data.readByUser && isUpdating && 'comment-unread-updating',
                    shouldScrollIntoView && !hasScrolledIntoView && 'comment-scroll-highlight',
                    props.className
                )}
                ref={ref}
            >
                <div className="text-grey font-14 font-medium mb-8">
                    {username} &#8226;{' '}
                    {formatDate(data.createdAt, {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                </div>
                <div dangerouslySetInnerHTML={{ __html: data.content }} className="font-14 whitespace-pre-wrap" />
                {!!data.attachments?.length && (
                    <>
                        <Divider className="my-16" />
                        <Typography.Text className="mb-4 font-14 block" strong>
                            Pièces jointes :
                        </Typography.Text>
                        <Upload
                            defaultFileList={data.attachments.map((attachment) => ({
                                uid: attachment.id,
                                name: attachment.originalName,
                                status: 'done',
                                url: attachment.url,
                            }))}
                            showUploadList={{
                                showRemoveIcon: false,
                            }}
                        />
                    </>
                )}
            </div>
        );
    },
    (prevProps, nextProps) =>
        prevProps.data?.id === nextProps.data?.id && prevProps.data?.readByUser === nextProps.data?.readByUser
);

export default Comment;
