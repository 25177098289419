import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { AppointmentSlot } from './types';

// Controller Interface
export type AppointmentSlotCreatePayload = Omit<AppointmentSlot, 'id'>;

export interface AppointmentSlotUpdatePayload extends Omit<AppointmentSlot, 'id'> {
    id?: AppointmentSlot['id'];
}

export type AppointmentSlotIdPayload = AppointmentSlot['id'];

export type AppointmentSlotListPayload = SearchPaginationQuery;
export type AppointmentSlotListResponse = AppointmentSlot[];

// Routes
export const list = async (payload?: AppointmentSlotListPayload) => {
    return await api
        .get<AppointmentSlotListResponse>(`/appointments/slots`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: AppointmentSlotCreatePayload) => {
    return await api.post(`/appointments/slots`, payload).then((response) => response?.data);
};

export const update = async ({ id, ...payload }: AppointmentSlotUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<AppointmentSlot>(`/appointments/slots/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: AppointmentSlotIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<AppointmentSlot>(`/appointments/slots/${id}`).then((response) => response?.data);
};

export const remove = async (id?: AppointmentSlotIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/appointments/slots/${id}`).then((response) => response?.data);
};
