import { VFC } from 'react';
import dayjs from 'dayjs';
import { Button } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import AppointmentPending from '../../components/appointment/AppointmentPending';
import AppointmentModalFormAdd from '../../components/appointment/appointmentModal/AppointmentModalFormAdd';
import { AppointmentStatus } from '../../queries/api/types';
import { useAppointmentList } from '../../queries/appointments';
import AppointmentRequestPending from '../../components/appointment/AppointmentRequestPending';
import AppointmentArchive from '../../components/appointment/AppointmentArchive';
import { CalendarCheck } from '../../components/icons';
import AppointmentInProgress from '../../components/appointment/AppointmentInProgress';
import '../../assets/styles/Appointments.less';

const AppointmentsList: VFC = () => {
    const appointmentListRequestPending = useAppointmentList({
        fromDate: dayjs().startOf('year').subtract(5, 'year').toISOString(),
        toDate: dayjs().endOf('year').toISOString(),
        status: [AppointmentStatus.pending],
        createdByAdmin: true,
        sort: 'fromDate',
        sortOrder: 'desc',
    });
    const appointmentListPending = useAppointmentList({
        fromDate: dayjs().startOf('year').subtract(5, 'year').toISOString(),
        toDate: dayjs().endOf('year').toISOString(),
        status: [AppointmentStatus.pending],
        createdByAdmin: false,
        sort: 'fromDate',
        sortOrder: 'desc',
    });
    const appointmentListInProgress = useAppointmentList({
        fromDate: dayjs().startOf('year').subtract(5, 'year').toISOString(),
        toDate: dayjs().endOf('year').toISOString(),
        status: [
            AppointmentStatus.accepted,
            AppointmentStatus.inProgress,
            AppointmentStatus.done,
            AppointmentStatus.notSigned,
        ],
        sort: 'fromDate',
        sortOrder: 'desc',
    });

    const isAppointmentListRequestPendingNoItems =
        !appointmentListRequestPending.isLoading &&
        appointmentListRequestPending.isSuccess &&
        !appointmentListRequestPending.data?.items.length;
    const isAppointmentListPendingNoItems =
        !appointmentListPending.isLoading &&
        appointmentListPending.isSuccess &&
        !appointmentListPending.data?.items?.length;
    const isAppointmentListInProgressNoItems =
        !appointmentListInProgress.isLoading &&
        appointmentListInProgress.isSuccess &&
        !appointmentListInProgress.data?.items.length;

    return (
        <div className="appointments">
            <div className="appointments-header p-24 text-center">
                <div className="font-regular font-14">{`Aujourd'hui nous sommes le`}</div>
                <div className="appointments-header-date font-black font-32 text-uppercase">
                    {dayjs().format('dddd DD MMMM YYYY')}
                </div>
                <Button
                    className="appointments-header-button mt-16"
                    type="primary"
                    size="large"
                    onClick={() => {
                        NiceModal.show(AppointmentModalFormAdd);
                    }}
                >
                    Demander un rendez-vous
                </Button>
            </div>
            <div>
                {isAppointmentListRequestPendingNoItems &&
                    isAppointmentListPendingNoItems &&
                    isAppointmentListInProgressNoItems && (
                        <div className="appointments-empty text-center">
                            <CalendarCheck style={{ color: '#0066CC', fontSize: 48 }} />
                            <div className="font-medium mt-16">
                                {`Vous n’avez aucun rendez-vous de prévu / en cours. Si vous le souhaitez vous pouvez faire
                            une demande ci-dessus.`}
                            </div>
                        </div>
                    )}
                <AppointmentRequestPending />
                <AppointmentPending />
                <AppointmentInProgress />
                <AppointmentArchive />
            </div>
        </div>
    );
};

export default AppointmentsList;
