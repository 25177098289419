import { useState, VFC } from 'react';
import { Form, FormItemProps, Tag, Typography } from 'antd';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { list, OrganizationListPayload, OrganizationslistResponse } from '../../queries/api/organizations';
import LabelWithTooltipIcon from '../LabelWithTooltipIcon';
import BasicList from '../BasicList';
import { useAuth } from '../../context/AuthContext';
import TagSelect, { TagSelectProps } from '../TagSelect';
import { errorMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import { useDebounce } from '../../hooks';
import { Organization } from '../../queries/api/types';

interface ProvidersFieldProps extends FormItemProps {
    readOnly?: boolean;
    selectProps?: TagSelectProps<Organization['id']>;
    queryPayload?: OrganizationListPayload;
}

const ProvidersField: VFC<ProvidersFieldProps> = ({ readOnly, selectProps, queryPayload, ...props }) => {
    const { user } = useAuth();
    const [search, setSearch] = useState<string>();
    const debouncedSearch = useDebounce(search, 300);
    const { data: organizations, isLoading } = useQuery<OrganizationslistResponse, AxiosError>(
        ['providers-field', debouncedSearch],
        async () =>
            await list({
                ...queryPayload,
                ...(debouncedSearch ? { search: debouncedSearch } : {}),
            }),
        {
            enabled: !readOnly,
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );

    return (
        <>
            <Form.Item
                label={<LabelWithTooltipIcon label="Fournisseurs" tooltip="Fournisseurs rattachés à votre compte" />}
                {...props}
            >
                {readOnly ? (
                    user?.scope?.organizations?.length ? (
                        <BasicList style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }} inline>
                            {user?.scope?.organizations?.map((organization) => (
                                <li key={organization.id} style={{ margin: '0.25rem' }}>
                                    <Tag>{organization.name}</Tag>
                                </li>
                            ))}
                        </BasicList>
                    ) : (
                        <Typography.Text type="secondary" className="font-12" italic>
                            Aucuns fournisseurs
                        </Typography.Text>
                    )
                ) : (
                    <TagSelect
                        {...selectProps}
                        placeholder={selectProps?.placeholder ?? 'Choisir un fournisseur'}
                        filterOption={false}
                        onSearch={setSearch}
                        loading={isLoading}
                        options={organizations?.items?.map((org) => ({
                            label: org.name,
                            value: org.id,
                        }))}
                        showSearch
                    />
                )}
            </Form.Item>
        </>
    );
};

export default ProvidersField;
