import { QueryFunction } from 'react-query';

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Notification, ListResponse } from './types';

// Controller Interface

export type NotificationUpdatePayload = Partial<Notification>;

export type NotificationIdPayload = Notification['id'];

export type NotificationListPayload = SearchPaginationQuery;
export type NotificationListResponse = ListResponse<Notification> & {
    receivedCount: number;
};

// Routes
export const list: QueryFunction<NotificationListResponse> = async ({ queryKey, pageParam }) => {
    return await api
        .get<NotificationListResponse>(`/me/notifications`, {
            params: {
                ...(typeof queryKey[2] === 'object' ? queryKey[2] : {}),
                page: pageParam ?? 0,
            },
        })
        .then((response) => response?.data);
};

export const update = async ({ id, ...payload }: NotificationUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.put<Notification>(`/me/notifications/${id}`, payload).then((response) => response?.data);
};
