import { FC } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';

import '../assets/styles/Header.less';

import { getRawRoute, getRoute, RoutePathName } from '../routes';
import MainMenu from './MainMenu';
import Logo from './Logo';
import UserDropdown from './UserDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import { useAuth } from '../context/AuthContext';

const Header: FC = () => {
    const { user } = useAuth();
    const hubName = user?.organization?.hub?.name;
    const isValidateAccountPage = useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const isChooseHubPage = useRouteMatch(getRawRoute(RoutePathName.chooseHub));

    return (
        <Layout.Header id="main-header">
            <div className="container">
                <nav className="flex">
                    <Link
                        to={getRoute(RoutePathName.home)}
                        title="Accueil"
                        style={{ marginLeft: '0.5rem' }}
                        className="flex items-center space-x-4"
                    >
                        <Logo />
                        {hubName && (
                            <span className="bg-orange text-white text-uppercase rounded-sm py-4 px-8 font-14 leading-4 font-bold">
                                {hubName}
                            </span>
                        )}
                    </Link>
                    {!isValidateAccountPage && !isChooseHubPage && <MainMenu />}
                </nav>
                <div className="h-full flex items-center space-x-4">
                    {!isValidateAccountPage && !isChooseHubPage && <NotificationsDropdown />}
                    <UserDropdown />
                </div>
            </div>
        </Layout.Header>
    );
};

export default Header;
