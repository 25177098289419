import { RcFile } from 'antd/lib/upload';
import jwt_decode from 'jwt-decode';

import {
    Address,
    AppointmentReportStatus,
    AppointmentStatus,
    Fee,
    FeeStatus,
    Order,
    Penalty,
    PenaltyContestationStatus,
    PenaltyStatus,
    User,
} from '../queries/api/types';

interface LoggerType {
    isAllowed: boolean;
    log: (messages?: any, ...optionalParams: any[]) => void;
    warn: (messages?: any, ...optionalParams: any[]) => void;
}

class Logger implements LoggerType {
    public isAllowed: boolean;

    constructor() {
        this.isAllowed = process.env.NODE_ENV !== 'production';
    }

    public log(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: dodgerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public info(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: cornflowerblue; font-weight: bold', messages, ...optionalParams);
        }
    }

    public warn(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: darkorange; font-weight: bold', messages, ...optionalParams);
        }
    }

    public error(messages?: any, ...optionalParams: any[]) {
        if (this.isAllowed) {
            console.log('%c[Logger]', 'color: tomato; font-weight: bold', messages, ...optionalParams);
        }
    }
}

export const debug = new Logger();

export const getFullName = (user?: Pick<User, 'firstName' | 'lastName'>) => {
    if (!user || (!user?.firstName && !user?.lastName)) {
        return '';
    }

    return `${user?.firstName ?? ''}${user?.lastName ? ` ${user?.lastName}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const addYear = (date: Date) => {
    date.setFullYear(date.getFullYear() + 1);

    return date;
};

export function classNames(...args: Array<string | undefined | boolean>) {
    return [...args].filter(Boolean).join(' ');
}

export const stripUndefinedKeysFromObject = (object: { [key: string]: any }) =>
    Object.keys(object).forEach((key) => {
        if (object[key] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete object[key];
        }
    });

export const checkIfTokenExpired = (token: string) => {
    try {
        const decoded: { exp: number } = jwt_decode(token);
        return decoded.exp * 1000 - Date.now() < 0;
    } catch (error) {
        return true;
    }
};

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams) =>
    Array.from(urlSearchParams.entries()).reduce<Record<string, any>>((acc, param) => {
        if (Object.prototype.hasOwnProperty.call(acc, param[0])) {
            if (Array.isArray(acc[param[0]])) {
                return { ...acc, [param[0]]: [...acc[param[0]], param[1]] };
            } else {
                return { ...acc, [param[0]]: [acc[param[0]], param[1]] };
            }
        }

        return { ...acc, [param[0]]: param[1] };
    }, {});

export const downloadFile = (url?: string, filename?: string) => {
    if (!url) {
        return;
    }

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename ?? 'download');
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
};

export const requiredRule = { required: true, message: 'champ requis' };

export const sortOrderConverter = (value: string) => {
    switch (value) {
        case 'ascend':
            return 'asc';
        case 'descend':
            return 'desc';
        default:
            return value;
    }
};

export const getPenaltyStatusColor = (status?: PenaltyStatus) => {
    switch (status) {
        case PenaltyStatus.closedReimbursed:
        case PenaltyStatus.closedToReimburseAfterMeeting:
        case PenaltyStatus.closedAfterMeeting:
        case PenaltyStatus.contestationAccepted:
        case PenaltyStatus.notContested:
            return 'green';
        case PenaltyStatus.pending:
        case PenaltyStatus.received:
            return 'blue';
        case PenaltyStatus.contested:
        case PenaltyStatus.contestedPartially:
        case PenaltyStatus.toCheckAfterMeeting:
            return 'orange';
        case PenaltyStatus.waitingForValidation:
            return 'red';

        default:
            return undefined;
    }
};

export const getPenaltyContestationStatusColor = (contestationStatus?: PenaltyContestationStatus) => {
    switch (contestationStatus) {
        case PenaltyContestationStatus.contestationAccepted:
            return 'green';
        case PenaltyContestationStatus.contestationRejected:
            return 'red';

        default:
            return 'orange';
    }
};

export const getFeesTotalInitialAmount = (fees?: Fee[]) => fees?.reduce((acc, fee) => (acc += fee.originalAmount), 0);

export const getAppointmentStatusColor = (status?: AppointmentStatus, reportStatus?: AppointmentReportStatus) => {
    switch (status) {
        case AppointmentStatus.pending:
            return 'orange';
        case AppointmentStatus.accepted:
        case AppointmentStatus.inProgress:
            return '#0066CC';
        case AppointmentStatus.done:
        case AppointmentStatus.notSigned:
            return reportStatus === AppointmentReportStatus.refused ? 'red' : 'orange';
        case AppointmentStatus.signed:
            return 'green';
        case AppointmentStatus.refused:
            return 'red';

        default:
            return 'default';
    }
};

export const getFeesTotalAmount = (fees?: Fee[]) => fees?.reduce((acc, fee) => (acc += fee.amount), 0);

export const getFeesTotalInitialAcceptedAmount = (fees?: Fee[]) =>
    fees?.reduce(
        (total, fee) =>
            (total += fee.status === FeeStatus.accepted && fee.originalAmount === fee.amount ? fee.originalAmount : 0),
        0
    );

export const getFeesTotalInitialContestedAmount = (fees?: Fee[]) =>
    fees?.reduce(
        (total, fee) =>
            (total +=
                [
                    FeeStatus.contested,
                    FeeStatus.contestationAccepted,
                    FeeStatus.contestationRejected,
                    FeeStatus.waitingForProvider,
                ].includes(fee.status) || fee.originalAmount !== fee.amount
                    ? fee.originalAmount
                    : 0),
        0
    );

export const getPenaltyTotalKeptAmount = (penalty?: Penalty) => penalty?.computedProperties?.finalAmount ?? 0;

export const getPenaltyTotalContestationAcceptedAmount = (penalty?: Penalty) =>
    Math.max((penalty?.originalAmount ?? 0) - (penalty?.computedProperties?.finalAmount ?? 0), 0);

export const penaltyFinishedStatuses = [
    PenaltyStatus.accepted,
    PenaltyStatus.closedAfterMeeting,
    PenaltyStatus.closedReimbursed,
    PenaltyStatus.closedToReimburseAfterMeeting,
    PenaltyStatus.notContested,
    PenaltyStatus.contestationAccepted,
];

export const formatAddress = (address?: Address) => {
    const parts = [
        (address?.street ?? '').trim(),
        (address?.zipCode ?? '').trim(),
        (address?.street ?? address?.zipCode) && address?.city && ',',
        (address?.city ?? '').trim(),
    ].filter(Boolean);

    return parts.length ? parts.join(' ') : '—';
};

export const formatOrderDelivery = (order?: Order) => {
    const parts = [order?.reference?.trim(), (order?.deliveryNumber ?? '').trim().padStart(2, '0')].filter(Boolean);

    return parts.length ? parts.join(' ').padStart(9, '0') : 'Inconnue';
};

export const isMSGFile = (file: RcFile) =>
    file.type === 'application/vnd.ms-outlook' || (!file.type && file.name.endsWith('.msg'));
