import { Divider, SelectProps } from 'antd';
import { FC } from 'react';

import { classNames } from '../helpers';
import { Language } from '../i18n/lang';
import CustomSelect from './CustomSelect';
import countries from '../i18n/countries/fr.json';

const CountrySelect: FC<SelectProps<Language>> = (props) => (
    <CustomSelect
        {...props}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
            option?.text?.toString().toLowerCase().startsWith(input.toLowerCase()) ?? false
        }
        dropdownClassName="lang-select-dropdown"
    >
        <CustomSelect.Option value="fr" key="fr" text={countries.fr}>
            <span className={classNames('flag', 'fr')} /> {countries.fr}
        </CustomSelect.Option>
        <CustomSelect.Option value="" style={{ minHeight: 0, padding: 0 }} disabled>
            <Divider style={{ margin: '0.25rem 0' }} />
        </CustomSelect.Option>
        {(Object.keys(countries) as Array<keyof typeof countries>)
            .filter((key) => !['fr'].includes(key))
            .map((lang) => (
                <CustomSelect.Option value={lang} key={lang} text={countries[lang]}>
                    <span className={classNames('flag', lang)} /> {countries[lang]}
                </CustomSelect.Option>
            ))}
    </CustomSelect>
);

export default CountrySelect;
