import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo, VFC } from 'react';

import { usePenaltyDrawerContext } from '../../context/PenaltyDrawerContext';
import { formatNumber, formatPrice, translateChangeAmountFeeStatus, translateFeeStatus } from '../../i18n';
import { Fee, FeeStatus, Penalty, PenaltyContestationStatus, PenaltyStatus } from '../../queries/api/types';
import { usePenaltyDetails } from '../../queries/penalties';
import TruncatedText from '../TruncatedText';
import PenaltyFeesTotalBar from './PenaltyFeesTotalBar';

const getFeeColorVariant = (fee: Fee) => {
    const hasFeeChangedAmount = fee.amount !== fee.originalAmount;

    if (fee.status === FeeStatus.pending) {
        return 'default';
    } else if (fee.status === FeeStatus.accepted && !hasFeeChangedAmount) {
        return 'success';
    } else {
        return 'error';
    }
};

const isFeeSelectionDisabled = (fee: Fee, penalty: Penalty | undefined) => {
    if (!penalty) {
        return true;
    }

    if (penalty.contestationStatus === PenaltyContestationStatus.waitingForProvider) {
        return fee.status !== FeeStatus.waitingForProvider;
    } else {
        return fee.status === FeeStatus.accepted;
    }
};

interface PenaltyFeesTableProps {
    penaltyId?: Penalty['id'];
    orderKey: string; // Order reference || 'unknown'
    fees?: Fee[];
}

const PenaltyFeesTable: VFC<PenaltyFeesTableProps> = ({ penaltyId, orderKey, fees }) => {
    const { isSelectingFees, selectedFeeIdsByOrderRef, setSelectedFeeIdsByOrderRef } = usePenaltyDrawerContext();
    const selectedFeeIds = useMemo(
        () => (selectedFeeIdsByOrderRef ? Object.values(selectedFeeIdsByOrderRef).flat() : undefined),
        [selectedFeeIdsByOrderRef]
    );
    const { data: penalty } = usePenaltyDetails(penaltyId, {
        enabled: !!penaltyId,
    });
    const { hasFirstAnswer, hasSecondAnswer } = useMemo(
        () => ({
            hasFirstAnswer: !!penalty?.fees?.some((fee) => !!fee.statusHistory?.[0]),
            hasSecondAnswer: !!penalty?.fees?.some((fee) => !!fee.statusHistory?.[1]),
        }),
        [penalty?.fees]
    );
    const hasMultipleOrders = useMemo(() => Boolean((penalty?.orders || [])?.length > 1), [penalty?.orders]);
    const hasChangedAmount = useMemo(() => !!fees?.some((fee) => fee.amount !== fee.originalAmount), [fees]);
    const isFinalDecisionVisible = useMemo(
        () =>
            hasChangedAmount &&
            fees?.some((fee) => fee.status !== FeeStatus.accepted) &&
            penalty?.contestationStatus &&
            ![PenaltyContestationStatus.pending, PenaltyContestationStatus.waitingForProvider].includes(
                penalty.contestationStatus
            ),
        [hasChangedAmount, penalty?.contestationStatus, fees]
    );
    const columns: ColumnsType<Fee> = useMemo(() => {
        let columns: ColumnsType<Fee> = [
            {
                key: 'description',
                title: 'Désignation',
                render: (_, record) => {
                    const feeColorStatus = getFeeColorVariant(record);
                    return (
                        <div className="flex items-center">
                            {feeColorStatus === 'error' && <CloseCircleFilled className="text-red font-24 mr-16" />}
                            {feeColorStatus === 'success' && <CheckCircleFilled className="text-green font-24 mr-16" />}
                            <TruncatedText maxHeight={110} expandText="tout afficher" expandedText="réduire">
                                <div dangerouslySetInnerHTML={{ __html: record.description ?? '' }} />
                            </TruncatedText>
                        </div>
                    );
                },
                width: hasChangedAmount ? 350 : 680,
            },
            {
                key: 'qty',
                title: 'Quantité',
                width: 120,
                render: (_, record) => formatNumber(record?.quantity, { minimumFractionDigits: 2 }),
            },
            {
                key: 'price',
                title: 'Prix unitaire',
                width: 150,
                render: (_, record) => formatPrice(record?.pricePerUnit, { minimumFractionDigits: 2 }),
            },
            {
                key: 'ttc',
                title: 'Montant TTC',
                width: 150,
                render: (_, record) => formatPrice(record?.originalAmount, { minimumFractionDigits: 2 }),
            },
        ];

        if (hasChangedAmount) {
            const isProviderAnswerVisible =
                hasChangedAmount &&
                penalty?.contestationStatus &&
                ![PenaltyContestationStatus.pending, PenaltyContestationStatus.waitingForProvider].includes(
                    penalty.contestationStatus
                );

            columns = (
                [
                    ...columns,
                    {
                        key: 'answers',
                        title: 'Montants corrigés',
                        className:
                            'ignore-row-bg-color border-l-2 border-l-solid border-b-2 border-b-solid border-base text-center py-8 px-16',
                        children: (
                            [
                                {
                                    key: 'canceled',
                                    title: 'Annulé',
                                    render: (_, record) =>
                                        formatPrice(record.originalAmount - record.amount, {
                                            minimumFractionDigits: 2,
                                        }),
                                    width: 150,
                                    className:
                                        'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center py-8 px-16',
                                },
                                {
                                    key: 'kept',
                                    title: 'Maintenu',
                                    render: (_, record) =>
                                        formatPrice(record.amount, {
                                            minimumFractionDigits: 2,
                                        }),
                                    shouldCellUpdate: () => true,
                                    width: 150,
                                    className:
                                        'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center py-8 px-16',
                                },
                                isProviderAnswerVisible && {
                                    key: 'answer',
                                    title: 'Votre réponse',
                                    render: (_, record) => translateChangeAmountFeeStatus(record.status),
                                    shouldCellUpdate: () => true,
                                    width: 150,
                                    className:
                                        'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center py-8 px-16',
                                },
                            ] as ColumnsType<Fee>
                        ).filter(Boolean),
                    },
                    isFinalDecisionVisible && {
                        key: 'finalDecision',
                        title: 'Décision définitive',
                        render: (_, record) => {
                            if (record.status === FeeStatus.contestationAccepted) {
                                return translateFeeStatus(record.status);
                            } else if (penalty?.status === PenaltyStatus.waitingForValidation) {
                                return 'En attente';
                            } else if (record.statusHistory?.[1]) {
                                return translateFeeStatus(record.status);
                            }

                            return translateFeeStatus(FeeStatus.waitingForProvider);
                        },
                        className:
                            'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center p-16',
                        width: 122,
                        fixed: 'right',
                    },
                ] as ColumnsType<Fee>
            ).filter(Boolean);
        } else {
            columns = (
                [
                    ...columns,
                    hasSecondAnswer && {
                        key: 'answers',
                        title: 'Décision',
                        className:
                            'ignore-row-bg-color border-l-2 border-l-solid border-b-2 border-b-solid border-base text-center',
                        children: [
                            {
                                key: 'firstResponse',
                                title: 'Initiale',
                                render: (_, record) => translateFeeStatus(record.statusHistory?.[0]),
                                width: 124,
                                className:
                                    'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center',
                            },
                            {
                                key: 'secondResponse',
                                title: 'Définitive',
                                render: (_, record) => translateFeeStatus(record.statusHistory?.[1]),
                                width: 124,
                                className:
                                    'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center',
                            },
                        ],
                    },
                    !hasSecondAnswer &&
                        hasFirstAnswer && {
                            key: 'answer',
                            title: 'Décision',
                            render: (_, record) => translateFeeStatus(record.statusHistory?.[0]),
                            width: 124,
                            className:
                                'ignore-row-bg-color border-l-2 border-l-solid border-base border-b-transparent text-center',
                        },
                ] as ColumnsType<Fee>
            ).filter(Boolean);
        }

        return columns;
    }, [
        hasChangedAmount,
        hasFirstAnswer,
        hasSecondAnswer,
        isFinalDecisionVisible,
        penalty?.contestationStatus,
        penalty?.status,
    ]);

    return (
        <div className="space-y-6">
            <Table<Fee>
                rowKey="id"
                columns={columns}
                dataSource={fees}
                pagination={false}
                scroll={isFinalDecisionVisible ? { x: 1031 } : undefined}
                rowClassName={(record) => {
                    const feeColorStatus = getFeeColorVariant(record);

                    switch (feeColorStatus) {
                        case 'success':
                            return 'table-row-success';
                        case 'error':
                            return 'table-row-error';

                        default:
                            return '';
                    }
                }}
                rowSelection={
                    isSelectingFees
                        ? {
                              onChange: (keys) => {
                                  setSelectedFeeIdsByOrderRef((selected) => ({
                                      ...selected,
                                      [orderKey]: keys as string[],
                                  }));
                              },
                              getCheckboxProps: (record) => ({
                                  // hide and disable checkbox for unwanted rows
                                  disabled: isFeeSelectionDisabled(record, penalty),
                                  style: isFeeSelectionDisabled(record, penalty) ? { display: 'none' } : undefined,
                              }),
                              selectedRowKeys: selectedFeeIds,
                          }
                        : undefined
                }
            />
            {hasMultipleOrders && <PenaltyFeesTotalBar fees={fees} isSubTotal />}
        </div>
    );
};

export default PenaltyFeesTable;
