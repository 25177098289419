import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, FormProps, ModalProps, Row, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import CustomModal from '../../CustomModal';

interface ModalFormCancelledProps extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'> {}

const AppointmentModalFormCancelled = NiceModal.create<ModalFormCancelledProps>(({ ...props }) => {
    const modalFormCancelled = useModal();

    const handleClickClose = () => {
        modalFormCancelled.hide();
    };

    const onFinish: FormProps['onFinish'] = () => {
        modalFormCancelled.resolve();
        modalFormCancelled.hide();
    };

    return (
        <CustomModal footer={false} {...props} {...antdModal(modalFormCancelled)}>
            <Form onFinish={onFinish} initialValues={{ comment: '' }}>
                <Row align="middle">
                    <Col span={2}>
                        <ExclamationCircleOutlined
                            style={{ color: '#F5222D', fontSize: '21px', verticalAlign: 'end' }}
                        />
                    </Col>
                    <Col>
                        <Typography.Title level={5}>Annulation de demande de rendez-vous</Typography.Title>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col span={22}>
                        <Typography.Paragraph>
                            Êtes-vous sûr de vouloir annuler cette demande de rendez-vous ?
                        </Typography.Paragraph>
                    </Col>
                </Row>
                <Row justify="end" className="mt-24">
                    <Space size={10}>
                        <Col>
                            <Button type="primary" ghost onClick={handleClickClose}>
                                Annuler
                            </Button>
                        </Col>
                        <Col offset={1}>
                            <Button type="primary" htmlType="submit">
                                Confirmer
                            </Button>
                        </Col>
                    </Space>
                </Row>
            </Form>
        </CustomModal>
    );
});

export default AppointmentModalFormCancelled;
