import { DrawerProps, Skeleton, Divider, Typography } from 'antd';
import { antdDrawer, useModal, create as createModal } from '@ebay/nice-modal-react';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CustomDrawer from '../CustomDrawer';
import { CommentType, PenaltyContestationStatus, PenaltyStatus } from '../../queries/api/types';
import { usePenaltyDetails } from '../../queries/penalties';
import ApiResult from '../ApiResult';
import PenaltyHeader from '../penalty/PenaltyHeader';
import PenaltyAttachments from '../penalty/PenaltyAttachments';
import PenaltyJustificationAttachments from '../penalty/PenaltyJustificationAttachments';
import PenaltyContestForm from '../forms/PenaltyContestForm';
import PenaltyAcceptDecisionForm from '../forms/PenaltyAcceptDecisionForm';
import PenaltyComments from '../penalty/PenaltyComments';
import { getRoute, RoutePathName } from '../../routes';
import PenaltyNewComment from '../penalty/PenaltyNewComment';
import TagAlt from '../TagAlt';
import { formatPrice, translatePenaltyType } from '../../i18n';
import { classNames, penaltyFinishedStatuses } from '../../helpers';
import { useCurrentHubName } from '../../context/AuthContext';
import PenaltyFeesTotalBar from '../penalty/PenaltyFeesTotalBar';
import PenaltyOrderCollapse from '../penalty/PenaltyOrderCollapse';
import PenaltyContestChangeAmountForm from '../forms/PenaltyContestChangeAmountForm';
import PenaltyFinalAmounts from '../penalty/PenaltyFinalAmounts';
import PenaltyFeesChangedAmountTotalBar from '../penalty/PenaltyFeesChangedAmountTotalBar';
import { PenaltyDrawerContextProvider } from '../../context/PenaltyDrawerContext';
import PenaltyStatusUpdateComments from '../penalty/PenaltyStatusUpdateComments';
import PenaltyNegativeFeesTable from '../penalty/PenaltyNegativeFeesTable';

const PenaltyDetailsDrawer = createModal<DrawerProps & Record<string, unknown>>((props) => {
    const hubName = useCurrentHubName();
    const { penaltyId } = useParams<{ penaltyId?: string }>();
    const location = useLocation();
    const history = useHistory();
    const modal = useModal();
    const {
        data: penalty,
        isLoading,
        isError,
        error,
        isSuccess,
    } = usePenaltyDetails(penaltyId, {
        enabled: !!penaltyId && modal.visible,
    });
    const onClose = () => {
        const search = new URLSearchParams(location.search);
        search.delete('commentId');

        history.push({
            pathname: getRoute(RoutePathName.penalties),
            search: search.toString(),
        });
    };
    const hasChangedAmount = useMemo(
        () => !!penalty?.fees?.some((fee) => fee.amount !== fee.originalAmount),
        [penalty?.fees]
    );
    const [positiveFees, negativeFees] = useMemo(
        () => [
            penalty?.fees?.filter((fee) => fee.originalAmount >= 0),
            penalty?.fees?.filter((fee) => fee.originalAmount < 0),
        ],
        [penalty?.fees]
    );
    const isShowingFinishedAmounts =
        !isLoading && isSuccess && penalty && penaltyFinishedStatuses.includes(penalty.status);
    const renderFooter = () => {
        if (isLoading || !isSuccess || !penalty) {
            return undefined;
        }

        if (isShowingFinishedAmounts) {
            return <PenaltyFinalAmounts penaltyId={penaltyId} queryEnabled={!!penaltyId && modal.visible} />;
        } else if (
            penalty.status &&
            penalty.status !== PenaltyStatus.waitingForValidation &&
            penalty.contestationStatus === PenaltyContestationStatus.waitingForProvider &&
            hasChangedAmount
        ) {
            return <PenaltyContestChangeAmountForm penalty={penalty} />;
        } else if ([PenaltyStatus.pending, PenaltyStatus.received].includes(penalty.status)) {
            return <PenaltyContestForm penalty={penalty} />;
        } else if (penalty.status === PenaltyStatus.waitingForValidation) {
            return <PenaltyAcceptDecisionForm penalty={penalty} onCloseDrawer={onClose} />;
        }

        return undefined;
    };
    const { hasFirstAnswer, hasSecondAnswer } = useMemo(
        () => ({
            hasFirstAnswer: !!penalty?.fees?.some((fee) => !!fee.statusHistory?.[0]),
            hasSecondAnswer: !!penalty?.fees?.some((fee) => !!fee.statusHistory?.[1]),
        }),
        [penalty?.fees]
    );

    return (
        <PenaltyDrawerContextProvider>
            <CustomDrawer
                width={1080}
                title={`Avis de pénalité n° ${penalty?.reference || ''}`}
                {...props}
                {...antdDrawer(modal)}
                onClose={onClose}
                footer={renderFooter()}
                className={classNames('penalty-details-drawer', isShowingFinishedAmounts && 'custom-drawer-footer-alt')}
            >
                {!isLoading && isError && <ApiResult status={error?.response?.status} />}
                {isLoading && <Skeleton active />}
                {!isLoading && isSuccess && (
                    <>
                        <PenaltyHeader penalty={penalty} />
                        <Divider />
                        <div className="space-y-6 mb-32">
                            <div className="flex justify-end">
                                <TagAlt color="orange">{translatePenaltyType(penalty)}</TagAlt>
                            </div>

                            <PenaltyJustificationAttachments penalty={penalty} />

                            {penalty?.cqlFee && (
                                <div className="flex justify-between items-center p-16 rounded border-solid border-2 border-base bg-ultra-light-blue">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: penalty?.cqlFee.description ?? '' }}
                                        className="whitespace-pre-wrap font-14 text-blue"
                                    />
                                </div>
                            )}
                            {positiveFees && positiveFees.length > 0 && (
                                <PenaltyOrderCollapse penaltyId={penaltyId} positiveFees={positiveFees} />
                            )}
                            {negativeFees && negativeFees.length > 0 && (
                                <PenaltyNegativeFeesTable penaltyId={penaltyId} fees={negativeFees} />
                            )}
                        </div>

                        <PenaltyFeesTotalBar fees={penalty?.fees} positiveFees={positiveFees} />
                        <PenaltyFeesChangedAmountTotalBar penalty={penalty} />

                        {!hasChangedAmount && (hasFirstAnswer || hasSecondAnswer) && (
                            <>
                                <Typography.Text className="block my-16" strong>
                                    Réponse de la {hubName} à la contestation
                                </Typography.Text>
                                <div className="rounded border-solid border-base border-2 flex mb-24">
                                    <div className="py-16 px-24 flex flex-1 items-center justify-center gap-24">
                                        <Typography.Text type="secondary" className="font-medium font-16 leading-7">
                                            Montant accordé :
                                        </Typography.Text>
                                        <Typography.Text className="font-16 leading-7" strong>
                                            {formatPrice(penalty?.computedProperties?.contestationAcceptedAmount)}
                                        </Typography.Text>
                                    </div>
                                    <div className="py-16 px-24 flex flex-1 items-center justify-center gap-24 border-l-solid border-l-2 border-base">
                                        <Typography.Text type="secondary" className="font-medium font-16 leading-7">
                                            Montant refusé :
                                        </Typography.Text>
                                        <Typography.Text className="font-16 leading-7" strong>
                                            {formatPrice(penalty?.computedProperties?.contestationRejectedAmount)}
                                        </Typography.Text>
                                    </div>
                                </div>
                            </>
                        )}
                        <PenaltyAttachments penalty={penalty} />
                        {(penalty?.comments?.filter((c) => c.type === CommentType.changeStatus) || []).length > 0 && (
                            <PenaltyStatusUpdateComments penalty={penalty} />
                        )}
                        {(penalty?.comments?.filter((c) => c.type !== CommentType.changeStatus) || []).length > 0 && (
                            <PenaltyComments penalty={penalty} />
                        )}
                        <PenaltyNewComment penalty={penalty} />
                    </>
                )}
            </CustomDrawer>
        </PenaltyDrawerContextProvider>
    );
});

PenaltyDetailsDrawer.displayName = 'PenaltyDetailsDrawer';

export default PenaltyDetailsDrawer;
