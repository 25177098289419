export default Object.freeze({
    PAGE_SIZE: 50,
    API: Object.freeze({
        BASE_URL: process.env.REACT_APP_API_BASE_URL || '/api',
        KEY: process.env.REACT_APP_API_KEY ?? '',
    }),
    SEO: Object.freeze({
        BASE_URL: '',
        SITE_NAME: 'Espace fournisseurs',
        DEFAULT_TITLE: 'Espace fournisseurs',
        DEFAULT_DESCRIPTION: 'Espace fournisseurs',
    }),
    PRODUCT_NAME: 'Espace fournisseurs',
    GOOGLE_ANALYTICS: process.env.REACT_APP_GOOGLE_ANALYTICS ?? '',
    ENABLE_DEBUG_LOGS: false, // process.env.NODE_ENV === 'production',
    DEBUG_LOGS_APP: '', // <app>_<service>
    VERSION: process.env.REACT_APP_VERSION ?? '',
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID ?? '',
    NOTIFICATIONS_REFETCH_INTERVAL_IN_MS: 5000,
    IS_MAINTENANCE_ENABLED: false,
});
