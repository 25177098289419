import { serialize } from 'object-to-formdata';
import { UploadChangeParam } from 'antd/lib/upload';

import api from './apiClient';
import { SearchPaginationQuery } from '.';
import { Organization, ListResponse } from './types';

// Controller Interface
export interface OrganizationCreatePayload {
    name: Organization['name'];
}

export interface OrganizationUpdatePayload extends Omit<Organization, 'id'> {
    id?: Organization['id'];
    attachments?: UploadChangeParam['fileList'];
}

export type OrganizationIdPayload = Organization['id'];

export type OrganizationListPayload = SearchPaginationQuery & {
    parent?: Organization['id'] | null;
    children?: Array<Organization['id']> | null;
};
export type OrganizationslistResponse = ListResponse<Organization>;

// Routes
export const list = async (payload?: OrganizationListPayload) => {
    return await api
        .get<OrganizationslistResponse>(`/organizations`, { params: payload })
        .then((response) => response?.data);
};

export const create = async (payload: OrganizationCreatePayload) => {
    return await api.post(`/organizations`, payload).then((response) => response?.data);
};

export const update = async ({ id, attachments, ...payload }: OrganizationUpdatePayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    const formData = serialize(payload, { indices: true });

    for (const attachment of attachments ?? []) {
        const file = attachment.originFileObj;
        if (file) {
            formData.append('attachments', file);
        }
    }
    return await api.put<Organization>(`/organizations/${id}`, payload).then((response) => response?.data);
};

export const details = async (id?: OrganizationIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.get<Organization>(`/organizations/${id}`).then((response) => response?.data);
};

export const remove = async (id?: OrganizationIdPayload) => {
    if (!id) {
        throw new Error('missing id');
    }
    return await api.delete<undefined>(`/organizations/${id}`).then((response) => response?.data);
};
