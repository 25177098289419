import { FC } from 'react';
import { Form, Upload, UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { errorMessage } from '../../helpers/message';

interface CompanyTermsUploadFieldProps {
    className?: string;
}

const CompanyTermsUploadField: FC<CompanyTermsUploadFieldProps> = ({ className }) => {
    const onBeforeUpload: UploadProps['beforeUpload'] = (_, fileList) => {
        const totalSize = fileList.reduce<number>((total, file) => (total += file.size), 0);

        if (fileList.some((file) => file.type !== 'application/pdf')) {
            errorMessage({
                content: 'Seuls les fichier PDF sont autorisés',
            });

            return Upload.LIST_IGNORE;
        }

        if (totalSize / 1024 > 20000) {
            errorMessage({
                content: "L'ensemble de vos fichiers dépassent la limite de taille maximum de 20Mo",
            });

            return Upload.LIST_IGNORE;
        }

        return false;
    };
    const normFile = (e: any) => (Array.isArray(e) ? e : e?.fileList);

    return (
        <Form.Item
            name={['user', 'organization', 'attachments']}
            label="Conditions de pénalités (facultatif)"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={className}
        >
            <Upload.Dragger beforeUpload={onBeforeUpload} multiple>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Cliquez ou faites glisser ici le(s) document(s) sur les conditions de pénalités pour l&rsquo;envoyer
                </p>
                <p className="ant-upload-hint">(Format PDF / Max 20 Mo)</p>
            </Upload.Dragger>
        </Form.Item>
    );
};

export default CompanyTermsUploadField;
