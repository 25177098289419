import {
    Button,
    Col,
    Divider,
    Form,
    FormProps,
    Input,
    message,
    Row,
    Tabs,
    Typography,
    Upload,
    UploadFile,
    UploadProps,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { antdDrawer, useModal, create } from '@ebay/nice-modal-react';
import dayjs from 'dayjs';

import CustomDrawer from '../CustomDrawer';
import { errorMessage, successMessage } from '../../helpers/message';
import { Appointment, AppointmentReportStatus, AppointmentStatus, RemoteFile, User } from '../../queries/api/types';
import {
    useAppointmentDetails,
    useAppointmentRemoveAttachment,
    useAppointmentUpdate,
} from '../../queries/appointments';
import { downloadFile, getFullName, requiredRule } from '../../helpers';
import { formatDate, formatPrice, requiredFieldsText } from '../../i18n';
import '../../assets/styles/AppointmentReportFormDrawer.less';
import AppointmentStatusTag from '../appointment/AppointmentStatusTag';

interface AppointmentFollowUpFormDrawerProps {
    appointmentId: Appointment['id'];
}

const { TabPane } = Tabs;
const { TextArea } = Input;

const userNamesFormatter = (user: Pick<User, 'firstName' | 'lastName' | 'job'>) =>
    [getFullName(user), user.job].filter(Boolean).join(' - ');

const initialValuesFormatter = (data?: Appointment) => ({
    report: {
        providerContent: data?.reports?.[0].providerContent,
        attachments: data?.reports?.[0].attachments ?? [],
        providerAttachments: data?.reports?.[0].providerAttachments?.map((attachment) => ({
            uid: attachment.id,
            name: attachment.name ?? attachment.originalName ?? 'Fichier',
            status: 'done',
            url: attachment.url,
            response: {
                ...attachment,
            },
        })),
    },
});

const AppointmentFollowUpFormDrawer = create<AppointmentFollowUpFormDrawerProps>(({ appointmentId }) => {
    const appointmentSaveUpdate = useAppointmentUpdate();
    const appointmentValidateUpdate = useAppointmentUpdate();
    const { mutateAsync: removeAppointmentAttachment } = useAppointmentRemoveAttachment();
    const { data: appointment } = useAppointmentDetails(appointmentId, {
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            form.setFieldsValue(initialValuesFormatter(data));
        },
    });
    const modal = useModal();
    const [form] = Form.useForm();
    const date = dayjs(appointment?.fromDate).format('DD MMMM YYYY');
    const time = dayjs(appointment?.fromDate).format('HH[h]mm');

    const handleClickDownload = () => {
        downloadFile(appointment?.reports?.[0].reportFile?.url);
    };

    const handleClickSave = async () => {
        try {
            const {
                report: { providerContent, providerAttachments },
            } = form.getFieldsValue();
            await appointmentSaveUpdate.mutateAsync({
                id: appointmentId,
                report: { ...appointment?.reports?.[0], providerContent, providerAttachments },
            });
            successMessage({ content: 'Compte rendu sauvegardé avec succès' });
            modal.hide();
        } catch (error) {
            errorMessage({
                content: `Une erreur est survenue pendant la sauvegarde du compte rendu`,
            });
        }
    };

    const onFormValidSubmit: FormProps['onFinish'] = async (values) => {
        try {
            const {
                report: { providerContent, providerAttachments },
            } = values;
            await appointmentValidateUpdate.mutateAsync({
                id: appointmentId,
                report: {
                    ...appointment?.reports?.[0],
                    providerContent,
                    providerAttachments,
                    status: AppointmentReportStatus.waitingForValidation,
                },
            });
            successMessage({ content: 'Compte rendu soumis avec succès' });
            modal.hide();
        } catch (error) {
            errorMessage({
                content: `Une erreur est survenue pendant la soumission du compte rendu`,
            });
        }
    };

    const normFile = (e: any) => (Array.isArray(e) ? e : e?.fileList);
    const onBeforeUpload: UploadProps['beforeUpload'] = (file) => {
        if (file.size / 1024 > 100000) {
            errorMessage({
                content: 'Le fichier dépasse la limite de taille maximum de 100Mo',
            });
            return Upload.LIST_IGNORE;
        }

        return false;
    };
    const onRemoveFile: UploadProps<RemoteFile>['onRemove'] = async (file) => {
        return await new Promise((resolve) => {
            if (file.response?.id) {
                message.loading({
                    key: 'removeAttachment',
                    content: 'Suppression en cours',
                    duration: 0,
                });
                removeAppointmentAttachment(
                    { id: appointmentId, type: 'providerAttachments', fileId: file.response.id },
                    {
                        onError: () => {
                            errorMessage({
                                key: 'removeAttachment',
                                content: 'Une erreur est survenue pendant la suppression de la pièce jointe',
                            });
                        },
                        onSuccess: () => {
                            successMessage({
                                key: 'removeAttachment',
                                content: 'Pièce jointe supprimée avec succès',
                                duration: 2,
                            });
                            resolve();
                        },
                    }
                );
            } else {
                resolve();
            }
        });
    };

    return (
        <CustomDrawer width={1150} title="Suivi de rendez-vous" {...antdDrawer(modal)}>
            <AppointmentStatusTag status={appointment?.status} reportStatus={appointment?.reports?.[0]?.status} />
            <Row className="mt-16 mb-16 appointment-report-form-drawer-header" align="middle" justify="space-between">
                <Col>
                    <p className="font-20 text-blue font-medium mb-0">
                        Rendez vous du <span className="font-black">{date}</span> à{' '}
                        <span className="font-black">{time}</span>
                    </p>
                    <span className="font-16 text-grey">
                        Pris par {appointment?.createdBy?.lastName} {appointment?.createdBy?.firstName}
                    </span>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        ghost
                        onClick={handleClickDownload}
                        disabled={!appointment?.reports?.[0].reportFile?.url}
                    >
                        Télécharger le protocole
                    </Button>
                </Col>
            </Row>
            <div>
                <div className="mb-8">
                    <p className="mb-0 font-medium">
                        Représentants présents pour la {appointment?.hub?.name ?? 'centrale'}
                    </p>
                    <span className="font-16 text-grey">
                        {appointment?.reports?.[0].hubUsers
                            ?.map((user: User) => userNamesFormatter(user))
                            .concat(
                                appointment?.reports?.[0].extraHubRepresentatives.map((user) =>
                                    userNamesFormatter(user)
                                )
                            )
                            .filter(Boolean)
                            .join(' / ')}
                    </span>
                </div>
                <div>
                    <p className="mb-0 font-medium">Représentants présents pour le fournisseur</p>
                    <span className="font-16 text-grey">
                        {appointment?.reports?.[0].providerUsers
                            ?.map((user: User) => userNamesFormatter(user))
                            .concat(
                                appointment?.reports?.[0].extraProviderRepresentatives.map((user) =>
                                    userNamesFormatter(user)
                                )
                            )
                            .filter(Boolean)
                            .join(' / ')}
                    </span>
                </div>
            </div>
            {(appointment?.reports?.[0].status === AppointmentReportStatus.inProgress ||
                appointment?.status === AppointmentStatus.signed) && (
                <Tabs
                    className="mt-16 appointment-report-follow-up-form-drawer-content"
                    defaultActiveKey={appointment?.status === AppointmentStatus.signed ? '2' : '1'}
                >
                    <TabPane tab="Compte rendu fournisseur" key="1">
                        {appointment?.status === AppointmentStatus.signed && (
                            <div className="mb-16 font-medium font-14">
                                Votre compte rendu validé par la {appointment?.hub?.name ?? 'centrale'} :
                            </div>
                        )}
                        {appointment?.reports?.[0].status === AppointmentReportStatus.inProgress && (
                            <div className="mb-16 font-medium font-14">
                                Voici le compte rendu que vous avez soumis à validation :
                            </div>
                        )}
                        <div className="appointment-report-follow-up-form-drawer-content-text">
                            <p className="mb-0 font-14">{appointment?.reports?.[0].providerContent}</p>
                            {!!appointment?.reports?.[0].providerAttachments?.length && (
                                <>
                                    <Divider />
                                    <p className=" font-medium font-14">Pièce jointe</p>
                                    <Upload<RemoteFile>
                                        onRemove={onRemoveFile}
                                        defaultFileList={[
                                            ...appointment?.reports?.[0].providerAttachments.map(
                                                (attachment) =>
                                                    ({
                                                        uid: attachment.id,
                                                        name: attachment.name ?? attachment.originalName ?? 'Fichier',
                                                        status: 'done',
                                                        url: attachment.url,
                                                        response: {
                                                            ...attachment,
                                                        },
                                                    } as UploadFile)
                                            ),
                                        ]}
                                    />
                                </>
                            )}
                        </div>
                    </TabPane>
                    <TabPane tab="Protocole" key="2">
                        {appointment?.status === AppointmentStatus.signed && (
                            <div className="mb-16 font-medium font-14">Protocole signé ensemble :</div>
                        )}
                        <div className="appointment-report-follow-up-form-drawer-content-text">
                            <p className="font-medium font-14">Compte rendu</p>
                            <p className="mb-0 font-14">{appointment?.reports?.[0].content}</p>
                        </div>
                        <div className="appointment-report-follow-up-form-drawer-content-text mt-16">
                            <p className="font-medium font-14">Plan de progrès</p>
                            <p className="mb-0 font-14">{appointment?.reports?.[0].progressPlan}</p>
                        </div>
                        {appointment?.reports?.[0].penalty?.amount &&
                            appointment?.reports?.[0].penalty?.fromDate &&
                            appointment?.reports?.[0].penalty?.toDate && (
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <div className="appointment-report-follow-up-form-drawer-content-text mt-16">
                                            <p className="font-medium font-14">Montant des avis de pénalité</p>
                                            <p className="mb-0 font-14 whitespace-pre-wrap">
                                                {formatPrice(appointment?.reports?.[0].penalty?.amount)}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="appointment-report-follow-up-form-drawer-content-text mt-16">
                                            <p className="font-medium font-14">Période concernée</p>
                                            <p className="mb-0 font-14 whitespace-pre-wrap">
                                                Du {formatDate(appointment?.reports?.[0].penalty?.fromDate)} au{' '}
                                                {formatDate(appointment?.reports?.[0].penalty?.toDate)}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                    </TabPane>
                </Tabs>
            )}
            {(appointment?.status === AppointmentStatus.done || appointment?.status === AppointmentStatus.notSigned) &&
                (appointment?.reports?.[0].status === AppointmentReportStatus.pending ||
                    appointment?.reports?.[0].status === AppointmentReportStatus.refused) && (
                    <Form
                        form={form}
                        onFinish={onFormValidSubmit}
                        layout="vertical"
                        initialValues={initialValuesFormatter(appointment)}
                        scrollToFirstError
                    >
                        <div className="appointment-report-form-drawer-button">
                            {appointment?.reports?.[0].status === AppointmentReportStatus.refused ? (
                                <p className="font-bold mb-0 text-red">
                                    {`Votre compte rendu a été refusé car il n'est pas conforme à l'échange qui a été tenu.
                                Merci de le mettre à jour ci-dessous* :`}
                                </p>
                            ) : (
                                <p className="font-bold mb-0">
                                    Pour clôturer ce rendez-vous, merci de saisir un compte rendu ou d’ajouter un
                                    document ci-dessous * :
                                </p>
                            )}
                            <span className="appointment-report-form-drawer-button-subtitle text-grey font-14">
                                {`Note: Ces informations seront partagées avec votre administrateur ainsi qu'aux autres
                                gestionnaires de ce compte fournisseur, s'il y en a.`}
                            </span>
                            <Form.Item className="mt-24" name={['report', 'providerContent']} rules={[requiredRule]}>
                                <TextArea rows={6} placeholder="Saisir votre compte rendu" />
                            </Form.Item>
                            <Form.Item
                                name={['report', 'providerAttachments']}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload.Dragger beforeUpload={onBeforeUpload} onRemove={onRemoveFile}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">
                                        {`Cliquez ou faites glisser ici le protocole signé pour l'envoyer`}
                                    </p>
                                    <p className="ant-upload-hint">(Max 10 Mo)</p>
                                </Upload.Dragger>
                            </Form.Item>
                            <Row className="w-full" key="button" justify="space-between">
                                <Col>
                                    <Button
                                        type="primary"
                                        ghost
                                        onClick={handleClickSave}
                                        loading={appointmentSaveUpdate.isLoading}
                                        disabled={appointmentValidateUpdate.isLoading}
                                    >
                                        Sauvegarder
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={appointmentValidateUpdate.isLoading}
                                        disabled={appointmentSaveUpdate.isLoading}
                                    >
                                        Valider et envoyer
                                    </Button>
                                </Col>
                            </Row>
                            <Typography.Paragraph className="text-center mt-24 mb-0">
                                <Typography.Text className="font-12">{requiredFieldsText}</Typography.Text>
                            </Typography.Paragraph>
                        </div>
                    </Form>
                )}
        </CustomDrawer>
    );
});

export default AppointmentFollowUpFormDrawer;
