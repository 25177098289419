import { useCallback } from 'react';
import { Typography, Divider, Button, message, ModalProps } from 'antd';
import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';

import CustomModal from '../components/CustomModal';
import { useAuth } from '../context/AuthContext';
import { defaultErrorMessage } from '../i18n';

const { Text, Paragraph } = Typography;

interface AboutModalProps extends ModalProps {
    isLogin?: boolean;
}

const AboutModal = NiceModal.create<AboutModalProps>(({ isLogin, ...props }) => {
    const modal = useModal();
    const { updateUser } = useAuth();
    const { isLoading, mutate: acceptTerms } = updateUser;
    const onOk = useCallback(() => {
        acceptTerms(
            { acceptedTerms: true },
            {
                onError: () => {
                    message.error(defaultErrorMessage);
                },
                onSuccess: () => {
                    modal.resolve();
                    modal.hide();
                },
            }
        );
    }, [acceptTerms, modal]);

    return (
        <CustomModal
            title="Note importante"
            footer={
                isLogin
                    ? [
                          <Button key="okBtn" onClick={onOk} type="primary" loading={isLoading}>
                              J&rsquo;accepte pour continuer
                          </Button>,
                      ]
                    : null
            }
            width={500}
            altTitle={isLogin}
            closable={!isLogin}
            {...props}
            {...antdModal(modal)}
        >
            <Paragraph>Les documents de références pour baser nos échanges sont les suivants :</Paragraph>
            <ul>
                <li>le Contrat Cadre de l&rsquo;année en cours</li>
                <li>la Convention Qualité Logistique</li>
            </ul>
            <Divider
                type="horizontal"
                orientation="left"
                plain={false}
                style={{ minWidth: '56px', width: '56px', borderTop: '1px solid #0066CC' }}
            />
            <Text style={{ fontWeight: 500 }}>
                L&rsquo;Analyse Contradictoire est un élément clé et se doit d&rsquo;être réalisée. Si un
                dysfonctionnement est constaté dans nos process mutuels, il est important de rédiger ensemble un plan de
                progrès afin de gommer ces anomalies.
            </Text>
        </CustomModal>
    );
});

export default AboutModal;
