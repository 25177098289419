import { CheckCircleFilled, CloseCircleFilled, DownOutlined } from '@ant-design/icons';
import { Badge, Collapse, Tag, Typography } from 'antd';
import { useEffect, useMemo, useRef, useState, VFC } from 'react';

import { formatOrderDelivery, getFeesTotalInitialAmount } from '../../helpers';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { formatDate, formatPrice } from '../../i18n';
import { Fee, FeeStatus, Order, Penalty, PenaltyContestationStatus } from '../../queries/api/types';
import { usePenaltyDetails } from '../../queries/penalties';
import PenaltyFeesTable from './PenaltyFeesTable';

interface FeesByOrderReference {
    [orderId: string]: {
        order?: Order;
        fees: Fee[];
    };
}

interface PenaltyOrderCollapseProps {
    penaltyId?: Penalty['id'];
    positiveFees?: Penalty['fees'];
}

const PenaltyOrderCollapse: VFC<PenaltyOrderCollapseProps> = ({ penaltyId, positiveFees }) => {
    const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
    const collapseTitleRef = useRef<HTMLDivElement | null>(null);
    const isCollapseTitleVisible = useIntersectionObserver(collapseTitleRef, { threshold: 1, freezeOnceVisible: true });
    const { data: penalty } = usePenaltyDetails(penaltyId, {
        enabled: !!penaltyId,
        onSuccess: (data) => {
            if (!activePanelKey) {
                const hasFeesWaitingForProvider =
                    data.contestationStatus === PenaltyContestationStatus.waitingForProvider &&
                    data.fees?.some((fee) => fee.status === FeeStatus.waitingForProvider);
                setActivePanelKey(
                    data.fees?.find((fee) =>
                        hasFeesWaitingForProvider ? fee.status === FeeStatus.waitingForProvider && fee.order : fee.order
                    )?.order || ''
                );
            }
        },
    });
    const shouldScrollIntoView = useMemo(
        () =>
            penalty &&
            penalty.contestationStatus === PenaltyContestationStatus.waitingForProvider &&
            penalty.fees?.some((fee) => fee.status === FeeStatus.waitingForProvider),
        [penalty]
    );
    const feesByOrder = useMemo(
        () =>
            positiveFees?.reduce<FeesByOrderReference>((acc, fee) => {
                const orderKey = fee.order || '';

                return {
                    ...acc,
                    [orderKey]: {
                        order: penalty?.orders?.find((order) => order.reference === fee.order),
                        fees: [...(acc[orderKey]?.fees || []), fee],
                    },
                };
            }, {}),
        [positiveFees, penalty?.orders]
    );
    const feesByOrderKeys = Object.keys(feesByOrder || {});
    const [activePanelKey, setActivePanelKey] = useState<string>(
        shouldScrollIntoView
            ? penalty?.fees?.find((fee) => fee.status === FeeStatus.waitingForProvider && fee.order)?.order ||
                  feesByOrderKeys[0] ||
                  ''
            : feesByOrderKeys[0] || ''
    );

    useEffect(() => {
        if (isCollapseTitleVisible && shouldScrollIntoView) {
            setHasScrolledIntoView(true);
        }
    }, [isCollapseTitleVisible, shouldScrollIntoView]);

    useEffect(() => {
        if (shouldScrollIntoView && !hasScrolledIntoView) {
            window.setTimeout(() => {
                collapseTitleRef.current?.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
            }, 50);
        }
    }, [shouldScrollIntoView, hasScrolledIntoView]);

    return feesByOrderKeys.length ? (
        <>
            <Typography.Title level={3} className="font-medium font-20 mb-16" ref={collapseTitleRef}>
                Commandes fournisseur concernées <Badge count={feesByOrderKeys.length} showZero />
            </Typography.Title>
            <Collapse
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                    <span className="text-primary font-24">
                        <DownOutlined rotate={isActive ? 0 : -90} />
                    </span>
                )}
                activeKey={activePanelKey}
                onChange={(key) => setActivePanelKey(key as string)}
                accordion
            >
                {feesByOrderKeys.map((orderKey) => {
                    const order = feesByOrder![orderKey].order;
                    const key = order?.reference || 'unknown';
                    const fees = feesByOrder![orderKey].fees;
                    const hasChangedAmount = fees.some((fee) => fee.amount !== fee.originalAmount);

                    return (
                        <Collapse.Panel
                            key={key}
                            header={
                                <div className="flex items-center justify-between mr-16">
                                    <div className="flex items-center gap-12">
                                        {fees.some(
                                            (fee) =>
                                                (fee.status &&
                                                    [
                                                        FeeStatus.contested,
                                                        FeeStatus.contestationAccepted,
                                                        FeeStatus.contestationRejected,
                                                        FeeStatus.waitingForProvider,
                                                    ].includes(fee.status)) ||
                                                fee.amount !== fee.originalAmount
                                        ) && <CloseCircleFilled className="text-red font-24" />}
                                        {fees.every(
                                            (fee) =>
                                                fee.status === FeeStatus.accepted && fee.amount === fee.originalAmount
                                        ) && <CheckCircleFilled className="text-green font-24" />}
                                        <div>
                                            <Typography.Text className="block font-bold font-16">
                                                Commande fournisseur : {formatOrderDelivery(order)}
                                            </Typography.Text>
                                            {order && (
                                                <Typography.Text className="font-14 font-regular">
                                                    Passée le {formatDate(order?.date)} — Livraison prévue le{' '}
                                                    {formatDate(order?.plannedDeliveryDate)}
                                                    {order?.deliveryDate && (
                                                        <span className="ml-4">
                                                            / Réelle le {formatDate(order?.deliveryDate)}
                                                        </span>
                                                    )}
                                                </Typography.Text>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex space-x-4 items-center">
                                        {hasChangedAmount && (
                                            <Tag
                                                style={{
                                                    borderColor: '#8E9DB3',
                                                    color: 'black',
                                                    backgroundColor: '#e0e4eb',
                                                }}
                                            >
                                                Montants corrigés
                                            </Tag>
                                        )}
                                        <Typography.Text className="font-bold font-16">
                                            {formatPrice(getFeesTotalInitialAmount(fees))}
                                        </Typography.Text>
                                    </div>
                                </div>
                            }
                        >
                            <PenaltyFeesTable penaltyId={penaltyId} orderKey={key} fees={fees} />
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        </>
    ) : null;
};

export default PenaltyOrderCollapse;
