import { useState, FC } from 'react';
import Input from 'antd/lib/input';
import { PasswordProps } from 'antd/lib/input/Password';
import { CheckOutlined } from '@ant-design/icons';

import '../assets/styles/PasswordInput.less';

import validatePasswordRules, { PasswordIssueReason } from '../helpers/passwords';
import BasicList from './BasicList';
import { classNames } from '../helpers';

interface PasswordInputProps extends PasswordProps {
    value?: string;
}

const PasswordInput: FC<PasswordInputProps> = ({ value, onChange, ...props }) => {
    const [val, setVal] = useState(value ?? '');
    const [errors, setErrors] = useState<ReturnType<typeof validatePasswordRules>>();
    const onChangeInput: PasswordProps['onChange'] = (e) => {
        const inputValue = e.target.value;
        setVal(inputValue);
        setErrors(validatePasswordRules(inputValue));

        onChange?.(e);
    };

    return (
        <>
            <Input.Password {...props} onChange={onChangeInput} />
            <BasicList className="password-input-instructions">
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find(
                                (issue) =>
                                    issue.reason === PasswordIssueReason.minimumLength ||
                                    issue.reason === PasswordIssueReason.maximumLength
                            ) &&
                            'is-valid'
                    )}
                >
                    <CheckOutlined />8 caractères minimum
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireNumber) &&
                            'is-valid'
                    )}
                >
                    <CheckOutlined />
                    Au moins un chiffre
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireCapital) &&
                            'is-valid'
                    )}
                >
                    <CheckOutlined />
                    Au moins une lettre en majuscule
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireLower) &&
                            'is-valid'
                    )}
                >
                    <CheckOutlined />
                    Au moins une lettre en minuscule
                </li>
                <li
                    className={classNames(
                        !!val &&
                            !errors?.issues.find((issue) => issue.reason === PasswordIssueReason.requireSpecial) &&
                            'is-valid'
                    )}
                >
                    <CheckOutlined />
                    Au moins un caractère spécial
                </li>
            </BasicList>
        </>
    );
};

export default PasswordInput;
