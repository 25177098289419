/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.667 2.667c0-.369.298-.667.666-.667h13.334c.368 0 .666.298.666.667v10a.667.667 0 0 1-.666.666H9.943L8.47 14.805a.667.667 0 0 1-.942 0l-1.472-1.472H1.333a.667.667 0 0 1-.666-.666v-10ZM2 3.333V12h4.333c.177 0 .347.07.472.195L8 13.39l1.195-1.195A.666.666 0 0 1 9.667 12H14V3.333H2Zm6 1.334c.368 0 .667.298.667.666v.334a.667.667 0 0 1-1.334 0v-.334c0-.368.299-.666.667-.666ZM8 7c.368 0 .667.298.667.667v3a.667.667 0 0 1-1.334 0v-3C7.333 7.298 7.632 7 8 7Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgTipsOne: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-tips-one', props.className)} />
));
export default SvgTipsOne;
