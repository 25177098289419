import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { User } from './api/types';
import {
    create,
    details,
    list,
    remove,
    update,
    UserCreatePayload,
    UserIdPayload,
    UserslistResponse,
    UserListPayload,
    UserUpdatePayload,
} from './api/users';

export const userKeys = {
    all: ['users'],
    lists: () => [...userKeys.all, 'list'],
    list: (params?: UserListPayload) => [...userKeys.lists(), params],
    details: () => [...userKeys.all, 'details'],
    detail: (id?: UserIdPayload) => [...userKeys.details(), id],
};

export const useUsersList = (params?: UserListPayload) => {
    return useQuery<UserslistResponse, AxiosError>(userKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
    });
};

export const useUserDetails = <TData = User>(
    id: UserIdPayload | undefined,
    options?: UseQueryOptions<User, AxiosError, TData>
) => {
    return useQuery<User, AxiosError, TData>(userKeys.detail(id), async () => await details(id), options);
};

export const useUserCreate = (options: UseMutationOptions<User, AxiosError, UserCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<User, AxiosError, UserCreatePayload>(async (params) => await create(params), {
        ...options,
        onSettled: (...args) => {
            options.onSettled?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(userKeys.lists());
        },
    });
};

export const useUserUpdate = (options: UseMutationOptions<User, AxiosError, UserUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<User, AxiosError, UserUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options.onSettled?.(data, error, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(userKeys.detail(variables.id));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(userKeys.lists());
        },
    });
};

export const useUserRemove = (options: UseMutationOptions<undefined, AxiosError, UserIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, UserIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(userKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(userKeys.lists());
        },
    });
};
