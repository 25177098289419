/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.333 6.208a2.667 2.667 0 1 1 3.334 2.583v.75a.667.667 0 0 1-1.334 0V8.209c0-.368.299-.666.667-.666a1.333 1.333 0 1 0-1.333-1.334.667.667 0 0 1-1.334 0ZM8.833 11.708a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.333 3c0-.92.747-1.667 1.667-1.667h10c.92 0 1.667.747 1.667 1.667v10c0 .92-.746 1.667-1.667 1.667H3c-.92 0-1.667-.746-1.667-1.667V3ZM3 2.667A.333.333 0 0 0 2.667 3v10c0 .184.149.333.333.333h10c.184 0 .333-.149.333-.333V3A.333.333 0 0 0 13 2.667H3Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgHelpcenter: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-helpcenter', props.className)} />
));
export default SvgHelpcenter;
