import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import '../assets/styles/MainMenu.less';

import { getRoute, RoutePathName } from '../routes';
import { CalendarThree, Inbox } from './icons';

const MainMenu: FC = () => {
    const location = useLocation();

    return (
        <Menu id="main-menu" mode="horizontal" selectedKeys={[location.pathname]} disabledOverflow>
            <Menu.Item key={getRoute(RoutePathName.penalties)}>
                <Link to={getRoute(RoutePathName.penalties)}>
                    <Inbox />
                    <span>Pénalités</span>
                </Link>
            </Menu.Item>
            <Menu.Item key={getRoute(RoutePathName.appointments)}>
                <Link to={getRoute(RoutePathName.appointments)}>
                    <CalendarThree />
                    <span>Rendez-vous</span>
                </Link>
            </Menu.Item>
        </Menu>
    );
};

export default MainMenu;
