import { ReactNode, VFC } from 'react';
import { Form, FormItemProps, SelectProps, Typography } from 'antd';

import { useAuth } from '../../context/AuthContext';
import CustomSelect from '../CustomSelect';
import { errorMessage } from '../../helpers/message';
import { defaultErrorMessage, translateRoleSlug } from '../../i18n';
import { Role, RoleSlug } from '../../queries/api/types';
import { useRoleList } from '../../queries/roles';
import { RoleListPayload } from '../../queries/api/roles';

interface RoleFormItemProps extends FormItemProps {
    readOnly?: boolean;
    selectProps?: SelectProps<Role['id']>;
    queryPayload?: RoleListPayload;
    optionLabelRender?: (record: Role) => ReactNode;
    roleSlugs?: RoleSlug[];
}

const RoleFormItem: VFC<RoleFormItemProps> = ({
    readOnly,
    selectProps,
    queryPayload,
    roleSlugs,
    optionLabelRender = (record) => record.name,
    ...props
}) => {
    const { user } = useAuth();
    const { data: roles, isLoading } = useRoleList(
        { ...queryPayload },
        {
            enabled: !readOnly,
            onError: () => {
                errorMessage({ content: defaultErrorMessage });
            },
        }
    );
    const filteredRoles = roleSlugs ? roles?.items?.filter((role) => roleSlugs.includes(role.slug)) : roles?.items;

    return (
        <Form.Item label="Rôle" {...props}>
            {readOnly ? (
                user?.role ? (
                    <Typography.Text>{translateRoleSlug(user.role.slug)}</Typography.Text>
                ) : (
                    <Typography.Text type="secondary" className="font-12" italic>
                        Aucun rôle
                    </Typography.Text>
                )
            ) : (
                <CustomSelect
                    {...selectProps}
                    placeholder={selectProps?.placeholder ?? 'Choisir un rôle'}
                    filterOption={false}
                    loading={isLoading}
                    options={filteredRoles?.map((role) => ({
                        label: optionLabelRender(role),
                        value: role.id,
                    }))}
                />
            )}
        </Form.Item>
    );
};

export default RoleFormItem;
