import { FC, ReactNode } from 'react';
import { Tooltip, TooltipProps } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface LabelWithTooltipIconProps {
    label: ReactNode;
    tooltip: TooltipProps['title'];
}

const LabelWithTooltipIcon: FC<LabelWithTooltipIconProps> = ({ tooltip, label }) => (
    <>
        {label}&nbsp;
        <Tooltip title={tooltip}>
            <InfoCircleOutlined style={{ color: '#808080' }} />
        </Tooltip>
    </>
);

export default LabelWithTooltipIcon;
