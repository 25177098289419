/**
 * AUTO-GENERATED with npm run generateIcons
 */
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import { classNames } from '../../helpers';

const svg = () => (
    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.638.74A.667.667 0 0 1 9 1.334V3.99l4.387 3.134c.176.125.28.327.28.543V14h1a.667.667 0 0 1 0 1.333H1.333a.667.667 0 0 1 0-1.333H3V4.667c0-.216.104-.418.28-.543L7.945.791a.667.667 0 0 1 .692-.05ZM4.333 14h3.334V4.341a.66.66 0 0 1 0-.017V2.63l-3.334 2.38V14ZM9 5.629V14h3.333V8.01L9 5.629Z"
            fill="currentColor"
        />
    </svg>
);

type Props = IconComponentProps & RefAttributes<HTMLSpanElement>;
const SvgBuildingOne: ForwardRefExoticComponent<Props> = forwardRef((props, ref) => (
    <Icon component={svg} {...props} ref={ref} className={classNames('svg-building-one', props.className)} />
));
export default SvgBuildingOne;
