import { Tag } from 'antd';
import { VFC } from 'react';

import { getPenaltyStatusColor } from '../../helpers';
import { translatePenaltyDetailsStatus } from '../../i18n';
import { Penalty } from '../../queries/api/types';

interface PenaltyStatusTagProps {
    penalty?: Penalty;
}

const PenaltyStatusTag: VFC<PenaltyStatusTagProps> = ({ penalty }) => {
    return <Tag color={getPenaltyStatusColor(penalty?.status)}>{translatePenaltyDetailsStatus(penalty)}</Tag>;
};

export default PenaltyStatusTag;
