import { FC, useMemo } from 'react';
import { Button, Modal, Spin, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';

import { FeeStatus, Penalty } from '../../queries/api/types';
import { usePenaltyUpdate } from '../../queries/penalties';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';
import PenaltyRejectChangeAmountFormModal from '../modals/PenaltyRejectChangeAmountFormModal';
import { usePenaltyDrawerContext } from '../../context/PenaltyDrawerContext';

interface PenaltyContestChangeAmountFormProps {
    penalty?: Penalty;
}

const PenaltyContestChangeAmountForm: FC<PenaltyContestChangeAmountFormProps> = ({ penalty }) => {
    const { setIsSelectingFees, isSelectingFees, selectedFeeIdsByOrderRef, setSelectedFeeIdsByOrderRef } =
        usePenaltyDrawerContext();
    const selectedFeeIds = useMemo(
        () => (selectedFeeIdsByOrderRef ? Object.values(selectedFeeIdsByOrderRef).flat() : undefined),
        [selectedFeeIdsByOrderRef]
    );
    const rejectModal = useModal(PenaltyRejectChangeAmountFormModal);
    const { mutateAsync: updatePenalty } = usePenaltyUpdate();
    const onRejectAll = () => {
        rejectModal
            .show({ penalty, rejectAll: true })
            .then(() => {
                setIsSelectingFees(false);
            })
            .catch(() => {
                setIsSelectingFees(false);
            });
    };
    const onRejectPartial = () => {
        Modal.confirm({
            width: 500,
            centered: true,
            title: 'Contester des lignes',
            icon: <ExclamationCircleOutlined className="text-red" />,
            content: 'Sélectionnez les lignes que vous souhaitez contester',
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            onOk: () => {
                setIsSelectingFees(true);
            },
        });
    };
    const onAccept = () => {
        Modal.confirm({
            width: 500,
            centered: true,
            title: 'Accepter les montants corrigés',
            icon: <ExclamationCircleOutlined className="text-orange" />,
            content: (
                <Typography.Paragraph className="mb-0">
                    Êtes-vous sûr de vouloir accepter l’ensemble des montants corrigés pour cet avis de pénalité ?
                </Typography.Paragraph>
            ),
            cancelButtonProps: {
                ghost: true,
                type: 'primary',
            },
            okText: 'Confirmer',
            onOk: async () => {
                return await updatePenalty(
                    {
                        id: penalty?.id,
                        fees: penalty?.fees?.map((fee) => ({
                            id: fee.id,
                            status: FeeStatus.accepted,
                        })),
                    },
                    {
                        onSuccess: () => {
                            successMessage({ content: 'Avis de pénalité accepté avec succès' });
                        },
                        onError: () => {
                            errorMessage({ content: defaultErrorMessage });
                        },
                    }
                );
            },
        });
    };
    const onSubmitSelectedFees = () => {
        rejectModal
            .show({ penalty, selectedFeeIds })
            .then(() => {
                setIsSelectingFees(false);
                setSelectedFeeIdsByOrderRef(undefined);
            })
            .catch(() => {
                setIsSelectingFees(false);
                setSelectedFeeIdsByOrderRef(undefined);
            });
    };
    const onCancelSelect = () => {
        setIsSelectingFees(false);
    };

    return (
        <Spin spinning={!penalty}>
            {isSelectingFees ? (
                <div className="flex justify-between items-center">
                    <Typography.Text strong>Refuser des lignes</Typography.Text>
                    <div className="flex space-x-4">
                        <Button type="primary" size="large" onClick={onCancelSelect} ghost>
                            Annuler
                        </Button>
                        <Button size="large" onClick={onSubmitSelectedFees} disabled={!selectedFeeIds?.length} danger>
                            Refuser les lignes sélectionnées
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <Typography.Paragraph strong>Acceptez-vous les montants corrigés ?</Typography.Paragraph>
                    <div className="flex justify-between">
                        <div>
                            <Button size="large" onClick={onRejectAll} danger ghost>
                                Refuser l&rsquo;ensemble
                            </Button>
                            <Button
                                className="ml-12"
                                size="large"
                                onClick={onRejectPartial}
                                disabled={
                                    (penalty?.fees?.filter((fee) =>
                                        [FeeStatus.contested, FeeStatus.waitingForProvider].includes(fee.status)
                                    )?.length ?? 0) <= 1
                                }
                                danger
                                ghost
                            >
                                Refuser des lignes
                            </Button>
                        </div>
                        <Button className="ant-btn-success" size="large" onClick={onAccept}>
                            Accepter et clôturer l’avis de pénalité
                        </Button>
                    </div>
                </div>
            )}
        </Spin>
    );
};

export default PenaltyContestChangeAmountForm;
