import { VFC } from 'react';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';

import { useAppointmentList } from '../../queries/appointments';
import ApiResult from '../ApiResult';
import { AppointmentStatus } from '../../queries/api/types';
import '../../assets/styles/Appointments.less';
import AppointmentCard from './AppointmentCard';

const AppointmentArchive: VFC = () => {
    const { data, isLoading, error, isError, isSuccess } = useAppointmentList({
        fromDate: dayjs().startOf('year').subtract(5, 'year').toISOString(),
        toDate: dayjs().endOf('year').toISOString(),
        status: [AppointmentStatus.canceled, AppointmentStatus.refused, AppointmentStatus.signed],
        sort: 'fromDate',
        sortOrder: 'desc',
        pageSize: 300,
    });

    return (
        <>
            {!isLoading && isError && <ApiResult status={error?.response?.status} />}
            {isLoading && (
                <div className="my-32">
                    <Skeleton active />
                </div>
            )}
            {!isLoading && isSuccess && !!data?.items?.length && (
                <div className="appointment-card">
                    <div className="appointment-status-title flex items-center text-blue font-bold mt-32 mb-32">
                        Historique ({data?.items?.length})
                    </div>
                    {data?.items?.map((appointment) => (
                        <AppointmentCard appointment={appointment} key={appointment.id} />
                    ))}
                </div>
            )}
        </>
    );
};

export default AppointmentArchive;
