import { Button, Typography } from 'antd';
import { VFC } from 'react';

import { downloadFile } from '../../helpers';
import { Penalty } from '../../queries/api/types';

interface PenaltyJustificationAttachmentsProps {
    penalty?: Penalty;
}

const PenaltyJustificationAttachments: VFC<PenaltyJustificationAttachmentsProps> = ({ penalty }) => {
    const onDownload = () => {
        if (penalty) {
            downloadFile(`/api/penalties/${penalty.id}/justifications.zip`);
        }
    };
    const hasJustifications = !!penalty?.justifications?.length;
    const hasPriceJustifications = !!penalty?.priceJustifications?.length;

    return hasJustifications || hasPriceJustifications ? (
        <>
            <Typography.Paragraph className="font-medium block mb-8">Pièces justificatives :</Typography.Paragraph>
            <div className="flex justify-between items-center mb-24 p-16 rounded border-solid border-2 border-base bg-ultra-light-blue">
                <div>
                    {hasJustifications && (
                        <>
                            <Typography.Text className="font-bold block font-16 leading-6 text-primary">
                                Preuve du manquement :
                            </Typography.Text>
                            {penalty?.justifications?.map((attachment) => (
                                <Typography.Text key={attachment.id} className="block font-14">
                                    {attachment.originalName}
                                </Typography.Text>
                            ))}
                        </>
                    )}
                    {hasPriceJustifications && (
                        <>
                            <Typography.Text className="font-bold block font-16 leading-6 text-primary">
                                Preuve du préjudice :
                            </Typography.Text>
                            {penalty?.priceJustifications?.map((attachment) => (
                                <Typography.Text key={attachment.id} className="block font-14">
                                    {attachment.originalName}
                                </Typography.Text>
                            ))}
                        </>
                    )}
                </div>
                <Button type="primary" onClick={onDownload}>
                    Télécharger les pièces justificatives
                </Button>
            </div>
        </>
    ) : null;
};

export default PenaltyJustificationAttachments;
