import { Tag } from 'antd';
import { VFC } from 'react';

import { getAppointmentStatusColor } from '../../helpers';
import { translateAppointmentStatus } from '../../i18n';
import { AppointmentReportStatus, AppointmentStatus } from '../../queries/api/types';

interface AppointmentStatusTagProps {
    status?: AppointmentStatus;
    reportStatus?: AppointmentReportStatus;
}

const AppointmentStatusTag: VFC<AppointmentStatusTagProps> = ({ status, reportStatus }) => {
    return <Tag color={getAppointmentStatusColor(status)}>{translateAppointmentStatus(status, reportStatus)}</Tag>;
};

export default AppointmentStatusTag;
