import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    AppointmentSlotCreatePayload,
    AppointmentSlotIdPayload,
    AppointmentSlotListPayload,
    AppointmentSlotListResponse,
    AppointmentSlotUpdatePayload,
    remove,
    update,
} from './api/appointmentsSlots';
import { AppointmentSlot } from './api/types';

export const appointmentsSlotsKeys = {
    all: ['appointmentsSlots'],
    lists: () => [...appointmentsSlotsKeys.all, 'list'],
    list: (params?: AppointmentSlotListPayload) => [...appointmentsSlotsKeys.lists(), params],
    details: () => [...appointmentsSlotsKeys.all, 'details'],
    detail: (id?: AppointmentSlotIdPayload) => [...appointmentsSlotsKeys.details(), id],
};

export const useAppointmentSlotList = <TData = AppointmentSlotListResponse>(
    params?: AppointmentSlotListPayload,
    options?: UseQueryOptions<AppointmentSlotListResponse, AxiosError, TData>
) => {
    return useQuery<AppointmentSlotListResponse, AxiosError, TData>(
        appointmentsSlotsKeys.list(params),
        async () => await list(params),
        { keepPreviousData: true, ...options }
    );
};

export const useAppointmentSlotDetails = <TData = AppointmentSlot>(
    id?: AppointmentSlotIdPayload,
    options?: UseQueryOptions<AppointmentSlot, AxiosError, TData>
) => {
    return useQuery<AppointmentSlot, AxiosError, TData>(
        appointmentsSlotsKeys.detail(id),
        async () => await details(id),
        options
    );
};

export const useAppointmentSlotCreate = (
    options?: UseMutationOptions<AppointmentSlot, AxiosError, AppointmentSlotCreatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<AppointmentSlot, AxiosError, AppointmentSlotCreatePayload>(
        async (params) => await create(params),
        {
            ...options,
            onSettled: (...args) => {
                options?.onSettled?.(...args);

                // invalidate list queries so they refetch with the newly added item
                queryClient.invalidateQueries(appointmentsSlotsKeys.lists());
            },
        }
    );
};

export const useAppointmentSlotUpdate = (
    options?: UseMutationOptions<AppointmentSlot, AxiosError, AppointmentSlotUpdatePayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<AppointmentSlot, AxiosError, AppointmentSlotUpdatePayload>(
        async (params) => await update(params),
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);

                // invalidate detail query to refetch with the newly added item
                queryClient.invalidateQueries(appointmentsSlotsKeys.detail(variables.id));
            },
        }
    );
};

export const useAppointmentSlotRemove = (
    options?: UseMutationOptions<undefined, AxiosError, AppointmentSlotIdPayload>
) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, AppointmentSlotIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(appointmentsSlotsKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(appointmentsSlotsKeys.lists());
        },
    });
};
