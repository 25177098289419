import { createContext, FC, useMemo, useContext, Dispatch, SetStateAction, useState } from 'react';

interface PenaltyDrawerContextTypes {
    selectedFeeIdsByOrderRef: Record<string, string[]> | undefined;
    setSelectedFeeIdsByOrderRef: Dispatch<SetStateAction<Record<string, string[]> | undefined>>;
    isSelectingFees: boolean;
    setIsSelectingFees: Dispatch<SetStateAction<boolean>>;
}

export const PenaltyDrawerContext = createContext<PenaltyDrawerContextTypes | null>(null);
PenaltyDrawerContext.displayName = 'PenaltyDrawerContext';

export const PenaltyDrawerContextProvider: FC = ({ children }) => {
    const [isSelectingFees, setIsSelectingFees] = useState(false);
    const [selectedFeeIdsByOrderRef, setSelectedFeeIdsByOrderRef] = useState<Record<string, string[]>>();

    const contextValues = useMemo(
        () => ({
            selectedFeeIdsByOrderRef,
            setSelectedFeeIdsByOrderRef,
            isSelectingFees,
            setIsSelectingFees,
        }),
        [selectedFeeIdsByOrderRef, setSelectedFeeIdsByOrderRef, isSelectingFees, setIsSelectingFees]
    );

    return <PenaltyDrawerContext.Provider value={contextValues}>{children}</PenaltyDrawerContext.Provider>;
};

export const usePenaltyDrawerContext = () => {
    const context = useContext(PenaltyDrawerContext);

    if (!context) {
        throw new Error('usePenaltyDrawerContext must be used within an PenaltyDrawerContext Provider');
    }

    return context;
};
