import { RefObject, useEffect, useState } from 'react';

interface Args extends IntersectionObserverInit {
    freezeOnceVisible?: boolean;
}

function useIntersectionObserver(
    elementRef: RefObject<Element>,
    { threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false }: Args | undefined = {}
): boolean {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const frozen = isIntersecting && freezeOnceVisible;

    useEffect(() => {
        if (frozen) {
            return;
        }
        const ref = elementRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            { threshold, root, rootMargin }
        );

        if (ref) {
            observer.observe(ref);
        }

        return () => {
            if (ref) {
                observer.unobserve(ref);
            }
        };
    }, [elementRef, frozen, root, rootMargin, threshold]);

    return isIntersecting;
}

export default useIntersectionObserver;
