import NiceModal, { antdModal, useModal } from '@ebay/nice-modal-react';
import { Button, Col, Form, FormProps, ModalProps, Row, Typography, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useRef } from 'react';
import { TextAreaRef } from 'antd/lib/input/TextArea';

import CustomModal from '../CustomModal';
import { usePenaltyUpdate } from '../../queries/penalties';
import { CommentType, Fee, FeeStatus, Penalty } from '../../queries/api/types';
import { errorMessage, successMessage } from '../../helpers/message';
import { defaultErrorMessage } from '../../i18n';

interface FormData {
    comment: string;
}

interface PenaltyRejectChangeAmountFormModalProps
    extends Omit<ModalProps, 'visible' | 'onCancel' | 'afterClose'>,
        Record<string, any> {
    rejectAll?: boolean;
    penalty?: Penalty;
    selectedFeeIds?: Array<Fee['id']>;
}

const PenaltyRejectChangeAmountFormModal = NiceModal.create<PenaltyRejectChangeAmountFormModalProps>(
    ({ penalty, selectedFeeIds, rejectAll, ...props }) => {
        const { mutateAsync: updatePenalty } = usePenaltyUpdate();
        const commentInputRef = useRef<TextAreaRef>(null);
        const modal = useModal();
        const [form] = Form.useForm<FormData>();
        const onFinish: FormProps<FormData>['onFinish'] = (values) => {
            const changedAmountFees = penalty?.fees?.filter((fee) => fee.status === FeeStatus.waitingForProvider);
            let feesToUpdate;

            if (rejectAll) {
                feesToUpdate = changedAmountFees?.map(({ id }) => ({
                    id,
                    status: FeeStatus.contested,
                }));
            } else {
                feesToUpdate = changedAmountFees?.map(({ id }) => ({
                    id,
                    status: selectedFeeIds?.includes(id) ? FeeStatus.contested : FeeStatus.accepted,
                }));
            }

            updatePenalty(
                {
                    id: penalty?.id,
                    fees: feesToUpdate,
                    comment: {
                        content: values.comment,
                        type: CommentType.contestation,
                    },
                },
                {
                    onSuccess: () => {
                        successMessage({ content: 'Avis de pénalité contesté avec succès' });
                    },
                    onError: (error) => {
                        errorMessage({ content: `${defaultErrorMessage} (${error?.response?.status ?? 0})` });
                    },
                }
            );
            modal.resolve(values);
            modal.hide();
        };

        return (
            <CustomModal
                {...props}
                {...antdModal(modal)}
                closable={false}
                maskClosable={false}
                footer={false}
                width={500}
            >
                <Form<FormData> form={form} onFinish={onFinish}>
                    <Row justify="start" className="mb-8" gutter={16} wrap={false}>
                        <Col>
                            <ExclamationCircleOutlined
                                style={{ color: '#F5222D', fontSize: '21px', verticalAlign: 'end', marginTop: 3 }}
                            />
                        </Col>
                        <Col>
                            <Typography.Title level={5}>
                                {rejectAll ? 'Refuser l’ensemble' : 'Refuser des lignes'}
                            </Typography.Title>
                            <Typography.Paragraph>
                                {rejectAll
                                    ? 'Êtes-vous sûr de vouloir refuser l’ensemble des montants corrigés de cet avis de pénalité ?'
                                    : 'Êtes-vous sûr de vouloir refuser les lignes sélectionnées?'}
                            </Typography.Paragraph>
                            <Form.Item label="Commentaire (obligatoire)" name="comment" labelCol={{ span: 24 }}>
                                <Input.TextArea
                                    ref={commentInputRef}
                                    placeholder="Saisir votre commentaire"
                                    autoSize={{ minRows: 1, maxRows: 5 }}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="end" gutter={16} className="mt-16">
                        <Col>
                            <Button type="primary" onClick={async () => await modal.hide()} ghost>
                                Annuler
                            </Button>
                        </Col>
                        <Col>
                            <Form.Item dependencies={['comment']} noStyle>
                                {({ getFieldValue }) => (
                                    <Button type="primary" htmlType="submit" disabled={!getFieldValue('comment')}>
                                        {rejectAll ? 'Confirmer' : 'Ok'}
                                    </Button>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </CustomModal>
        );
    }
);

export default PenaltyRejectChangeAmountFormModal;
