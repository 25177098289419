import { VFC } from 'react';
import dayjs from 'dayjs';
import { Skeleton } from 'antd';

import { useAppointmentList } from '../../queries/appointments';
import { AppointmentStatus } from '../../queries/api/types';
import ApiResult from '../ApiResult';
import '../../assets/styles/Appointments.less';
import { formatNumber } from '../../i18n';
import AppointmentCard from './AppointmentCard';

const AppointmentRequestPending: VFC = () => {
    const { data, isLoading, error, isError, isSuccess } = useAppointmentList({
        fromDate: dayjs().startOf('year').subtract(5, 'year').toISOString(),
        toDate: dayjs().endOf('year').toISOString(),
        status: [AppointmentStatus.pending],
        createdByAdmin: true,
        sort: 'fromDate',
        sortOrder: 'desc',
    });

    return (
        <>
            {!isLoading && isError && <ApiResult status={error?.response?.status} />}
            {isLoading && (
                <div className="my-32">
                    <Skeleton active />
                </div>
            )}
            {!isLoading && isSuccess && !!data?.items.length && (
                <div className="appointment-card">
                    <div className="appointment-status-title flex items-center text-blue font-bold mt-32 mb-32">
                        Demande en attente ({formatNumber(data.totalCount)})
                    </div>
                    {data.items?.map((appointment) => (
                        <AppointmentCard appointment={appointment} key={appointment.id} />
                    ))}
                </div>
            )}
        </>
    );
};

export default AppointmentRequestPending;
