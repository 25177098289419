import { Layout } from 'antd';
import { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LayoutProvider } from '../context/LayoutContext';
import { classNames } from '../helpers';
import { getRawRoute, RoutePathName } from '../routes';
import Header from './Header';

const MainLayout: FC = ({ children }) => {
    const isValidateAccountPage = !!useRouteMatch(getRawRoute(RoutePathName.validateAccount));
    const isChooseHubPage = !!useRouteMatch(getRawRoute(RoutePathName.chooseHub));

    return (
        <LayoutProvider>
            <Layout className="min-h-screen">
                <Header />
                <Layout.Content
                    className={classNames(
                        'py-32 px-24 flex-auto',
                        isValidateAccountPage ? 'container-small' : 'container',
                        isChooseHubPage && 'choose-hub-content'
                    )}
                >
                    {children}
                </Layout.Content>
            </Layout>
        </LayoutProvider>
    );
};

export default MainLayout;
