import axios from 'axios';

import constants from '../../config/constants';

const client = axios.create({
    baseURL: constants.API.BASE_URL,
    headers: {
        'x-api-key': localStorage.getItem('api-key') || '',
        'x-api-language': 'fr',
    },
    withCredentials: true, // automatically send saved cookie
});

client.interceptors.response.use(
    (response) => response,
    (error) => {
        // dispatch a custom event when we encounter a 401. The event is caught in src/components/ProtectedRoute.tsx

        if (error?.response?.status === 401 && !error?.request?.responseURL?.includes('/auth/logout')) {
            const e = document.createEvent('CustomEvent');
            e.initCustomEvent('unauthorized.error', true, false, 401);
            window.dispatchEvent(e);
        }

        throw error;
    }
);

export default client;

export const setApiKey = (apiKey: string) => {
    client.defaults.headers['x-api-key'] = apiKey;
    localStorage.setItem('api-key', apiKey);
};

export const removeApiKey = () => {
    localStorage.removeItem('api-key');
    delete client.defaults.headers['x-api-key'];
};
