import { Button, Typography, Upload, UploadProps } from 'antd';
import { VFC } from 'react';
import { UploadFile } from 'antd/es/upload/interface';

import { Penalty } from '../../queries/api/types';
import { downloadFile } from '../../helpers';

interface PenaltyAttachmentsProps {
    penalty?: Penalty;
}

const PenaltyAttachments: VFC<PenaltyAttachmentsProps> = ({ penalty }) => {
    const hasAttachments = !![...(penalty?.attachments ?? []), ...(penalty?.invoices ?? [])]?.length;
    const onRemoveAttachment: UploadProps['onRemove'] = (file) => {
        console.log(file);
    };
    const onDownload = () => {
        if (penalty) {
            downloadFile(`/api/penalties/${penalty.id}/attachments.zip`);
        }
    };

    return hasAttachments ? (
        <div className="mt-24">
            <div className="flex justify-between mb-16">
                <Typography.Text className="font-medium">Pièces disponibles :</Typography.Text>
                <Button type="primary" size="small" onClick={onDownload} ghost>
                    Télécharger les pièces
                </Button>
            </div>
            <Upload
                onRemove={onRemoveAttachment}
                defaultFileList={[
                    ...(penalty?.attachments ?? [])?.map(
                        (attachment) =>
                            ({
                                uid: attachment.id,
                                name: attachment.name ?? attachment.originalName ?? 'Fichier',
                                status: 'done',
                                url: attachment.url,
                            } as UploadFile)
                    ),
                    ...(penalty?.invoices ?? [])?.map(
                        (attachment) =>
                            ({
                                uid: attachment.id,
                                name: attachment.name ?? attachment.originalName ?? 'Facture/Avoir/Note de débit',
                                status: 'done',
                                url: attachment.url,
                            } as UploadFile)
                    ),
                ]}
                showUploadList={{
                    showRemoveIcon: false,
                }}
            />
        </div>
    ) : null;
};

export default PenaltyAttachments;
