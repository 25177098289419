import { VFC } from 'react';

import defaultLogo from '../assets/images/logo.svg';
import { useAuth } from '../context/AuthContext';

interface LogoProps {
    isDefault?: boolean;
}

const Logo: VFC<LogoProps> = ({ isDefault }) => {
    const { user } = useAuth();

    return (
        <img
            className="logo"
            src={isDefault ? defaultLogo : user?.organization?.logoUrl || defaultLogo}
            alt={`Logo E.leclerc ${isDefault ? '' : user?.organization?.name ?? ''}`}
        />
    );
};

export default Logo;
