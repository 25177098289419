import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import './assets/styles/App.less';

import { useLogs } from './context/LogsContext';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './components/MainLayout';
import CookieBanner from './components/CookieBanner';
import ErrorBoundary from './components/ErrorBoundary';
import { getRawRoute, RoutePathName } from './routes';
import ErrorPage from './pages/error';
import Login from './pages/login';
import Home from './pages/home';
import ForgottenPassword from './pages/forgottenPassword';
import ResetPassword from './pages/resetPassword';
import { ValidateAccount } from './pages/validateAccount';
import PenaltiesList from './pages/penalties';
import AppointmentsList from './pages/appointments';
import ChooseOrganization from './pages/chooseHub/ChooseHub';
import Maintenance from './pages/maintenance';
import constants from './config/constants';
import TutorialModal from './components/TutorialModal';

export const tutorialModalId = 'tutorialModal';

const App: FC = () => {
    const { sendLogs } = useLogs();

    if (constants.IS_MAINTENANCE_ENABLED) {
        return <Maintenance />;
    }

    return (
        <Switch>
            <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
            <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
            <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
            <ProtectedRoute>
                <CookieBanner />
                <TutorialModal id={tutorialModalId} />
                <MainLayout>
                    <ErrorBoundary sendLogs={sendLogs}>
                        <Switch>
                            <Route exact path={getRawRoute(RoutePathName.home)}>
                                <Home />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.penalties)}>
                                <PenaltiesList />
                            </Route>

                            <Route path={getRawRoute(RoutePathName.appointments)}>
                                <AppointmentsList />
                            </Route>

                            <Route exact path={getRawRoute(RoutePathName.validateAccount)}>
                                <ValidateAccount />
                            </Route>

                            <Route exact path={getRawRoute(RoutePathName.chooseHub)}>
                                <ChooseOrganization />
                            </Route>

                            <Route path="*">
                                <ErrorPage />
                            </Route>
                        </Switch>
                    </ErrorBoundary>
                </MainLayout>
            </ProtectedRoute>
        </Switch>
    );
};

export default App;
