import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import {
    create,
    details,
    list,
    RoleCreatePayload,
    RoleIdPayload,
    RoleListPayload,
    RoleListResponse,
    RoleUpdatePayload,
    remove,
    update,
} from './api/roles';
import { Role } from './api/types';

export const rolesKeys = {
    all: ['roles'],
    lists: () => [...rolesKeys.all, 'list'],
    list: (params?: RoleListPayload) => [...rolesKeys.lists(), params],
    details: () => [...rolesKeys.all, 'details'],
    detail: (id?: RoleIdPayload) => [...rolesKeys.details(), id],
};

export const useRoleList = <TData = RoleListResponse>(
    params?: RoleListPayload,
    options?: UseQueryOptions<RoleListResponse, AxiosError, TData>
) => {
    return useQuery<RoleListResponse, AxiosError, TData>(rolesKeys.list(params), async () => await list(params), {
        keepPreviousData: true,
        ...options,
    });
};

export const useRoleDetails = <TData = Role>(
    id?: RoleIdPayload,
    options?: UseQueryOptions<Role, AxiosError, TData>
) => {
    return useQuery<Role, AxiosError, TData>(rolesKeys.detail(id), async () => await details(id), options);
};

export const useRoleCreate = (options?: UseMutationOptions<Role, AxiosError, RoleCreatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Role, AxiosError, RoleCreatePayload>(async (params) => await create(params), {
        ...options,
        onSettled: (...args) => {
            options?.onSettled?.(...args);

            // invalidate list queries so they refetch with the newly added item
            queryClient.invalidateQueries(rolesKeys.lists());
        },
    });
};

export const useRoleUpdate = (options?: UseMutationOptions<Role, AxiosError, RoleUpdatePayload>) => {
    const queryClient = useQueryClient();

    return useMutation<Role, AxiosError, RoleUpdatePayload>(async (params) => await update(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query to refetch with the newly added item
            queryClient.invalidateQueries(rolesKeys.detail(variables.id));
        },
    });
};

export const useRoleRemove = (options?: UseMutationOptions<undefined, AxiosError, RoleIdPayload>) => {
    const queryClient = useQueryClient();

    return useMutation<undefined, AxiosError, RoleIdPayload>(async (params) => await remove(params), {
        ...options,
        onSettled: (data, error, variables, context) => {
            options?.onSettled?.(data, error, variables, context);

            // invalidate detail query since we deleted the item
            queryClient.invalidateQueries(rolesKeys.detail(variables));

            // invalidate list queries to refetch for refreshing the list views
            queryClient.invalidateQueries(rolesKeys.lists());
        },
    });
};
