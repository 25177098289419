import { Result, Typography } from 'antd';
import { FC } from 'react';

import Seo from '../../components/Seo';

const Maintenance: FC = () => {
    return (
        <>
            <Seo title="Maintenance en cours" />
            <div style={{ maxWidth: 800, margin: '0 auto' }}>
                <Result
                    status="warning"
                    title="Le site Espace Fournisseurs E.LECLERC est actuellement en maintenance."
                    extra={
                        <>
                            <Typography.Paragraph>
                                Un e-mail vous sera transmis dès que l’accès sera de nouveau possible. <br />
                                Nous vous remercions de votre compréhension.
                            </Typography.Paragraph>
                            <Typography.Paragraph>À bientôt</Typography.Paragraph>
                        </>
                    }
                />
            </div>
        </>
    );
};

export default Maintenance;
